import React, { useState, useEffect } from "react";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { Link } from "react-router-dom";
import { Container, Breadcrumb, BreadcrumbItem, Button, CardBody, Card, CardHeader, Row, Col, Label, Spinner } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import RemotePagination from '../../newComponents/utilities/RemotePagination'
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProcessStatusChangeModal from "../../newComponents/utilities/ProcessStatusChangeModal";
import SearchBarComponent from '../../newComponents/utilities/SearchBarComponent'
import Select from "react-select";
import MomentConfig from '../../common/MomentConfig';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import AsyncSelect from "react-select/async";
import ActionDownloadModal from "../../newComponents/utilities/ActionDownloadModal";

const blankState = (
  <Card className="blank-state">
    <CardBody>
      <h3>No Records Available</h3>
    </CardBody>
  </Card>
)

const FormExchange = (props) => {

  // LOCAL VARIABLES
  const [blankStateStatus, setBlankStateStatus] = useState(false) // eslint-disable-line
  const [fileExchangeList, setFileExchangeList] = useState([]);
  const [loading, setLoading] = useState(true);// eslint-disable-line
  const [modalState, setModalState] = useState(false);
  const [modalStateDownload, setModalStateDownload] = useState(false);
  const [status, setStatus] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [tableID, setTableID] = useState("");
  const [groupId, setGroupId] = useState("")
  const [uploadedOn, setUploadedOn] = useState("")// eslint-disable-line
  const [updatedOn, setUpdatedOn] = useState("")// eslint-disable-line
  const [uploadedBy, setUploadedBy] = useState("")// eslint-disable-line
  const [processedBy, setProcessedBy] = useState("")// eslint-disable-line
  const [reloadUserData, setReloadUserData] = useState("")
  const [FileName, setFileName] = useState("")
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sizePerPage, setSizePerPage] = useState(50);
  const [totalSize, setTotalSize] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const fileExportName = 'FromExchange'
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [groupName, setGroupName] = useState("")
  const [fileType, setFileType] = useState("")
  const [inputValue, setInputValue] = useState("")// eslint-disable-line
  const [userTag, setUserTag] = useState("")
  const [page, setPage] = useState(1); // eslint-disable-line
  const [typeOptions, setTypeOPtions] = useState([])

  const StatusOptions = [
    { value: '2', label: 'Processed' },
    { value: '1', label: 'Not Processed' }
  ]
  let new_token = "" // eslint-disable-line

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleChange = (value) => {
    setGroupName(value);

  };

  const tagInputChange = (value) => {
    setInputValue(value);
  }
  const tagChange = (value) => {
    setUserTag(value);
  }

  const fileChange = (value) => {
    setFileType(value);
  }

  const loadOptions = (inputValue) => {
    if (inputValue.length >= 2) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
        .then((response) => response.data.data)
      // .catch((error) => {
      //   throw error;
      // });
    }
  };

  const tagOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/file-repository-tags/${inputValue.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then((response) => response.data.data)
      // .catch((error) => {
      //   throw error;
      // });
    }
  };

  const fileTypeOptions = (inputValue) => {
    return AxiosInter.get(`${API_ENDPOINT}/admin-api/file-repository-types/`,
      {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => setTypeOPtions(response.data.data))
    // .catch((error) => {
    //   throw error;
    // });

  };

  const fetchUsers = () => {
    setLoading(true);
    const params = {
      fileStatus: statusFilter ? statusFilter.value : "",
      tag: userTag ? userTag.label : "",
      groupName: groupName ? groupName.label : "",
      fileType: fileType ? fileType.label : "",
      OFFSET: offset,
      is_pagination: "1",
      record_limit: sizePerPage ? sizePerPage : "",
      search_keyword: searchKeyword ? searchKeyword : "",
      sortOrder: sortOrder ? sortOrder : "desc",
      sortColumn: sortColumn ? sortColumn : "gfr.createdOn",

    };

    AxiosInter.post(`${API_ENDPOINT}/admin-api/file-repoDetails-adminpanel/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((response) => {
        // eslint-disable-next-line eqeqeq
        if (response.status == "200") {
          setFileExchangeList(response.data.data);
          setTotalSize(response.data.record_count);
          setLoading(false);
          localStorage.setItem("offset", offset);
          localStorage.removeItem("tagFilter");
          localStorage.removeItem("groupNameFilter");
          localStorage.removeItem("statusFilter");

        } else {
          setTotalSize(0);
          setFileExchangeList("");
          setLoading(false);
        }
      })
    // .catch((error) => {
    //   if (error.response.status === 401) {
    //     new_token= Cookie.get("access_token_admin")  
    //     AxiosInter.get(`${API_ENDPOINT}/admin-api/file-repoDetails-adminpanel/`, {
    //               headers: {
    //                 Authorization: new_token,
    //               },
    //             })
    //         }else{
    //           setFileExchangeList("")}
    // });
  };

  const exportTableData = () => {

    const params =
    {
      "groupName": groupName ? groupName.label : "",
      "file_name": fileName ? fileName : "",
      "fileType": fileType ? fileType.label : "",
      "tag": userTag ? userTag.label : "",
      "uploadedBy": uploadedBy ? uploadedBy : "",
      "createdOn": uploadedOn ? uploadedOn : "",
      "fileStatus": statusFilter ? statusFilter.value : "",
      "processedBy": processedBy ? processedBy : "",
      "updatedOn": updatedOn ? updatedOn : "",
      "OFFSET": offset,
      "is_pagination": "1",
      "record_limit": sizePerPage ? sizePerPage : "",
      "search_keyword": searchKeyword ? searchKeyword : "",
      sortOrder: "",
      sortColumn: "",

    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/file-repository-export/`, params, {
      headers: {

        Authorization: API_TOKEN,

      },
      responseType: "blob"
    })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data, `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success(
            "Success",
            "Export successful"
          );
        }
      })
    // .catch((error) => {

    //     toastr.error(
    //         "Failed",
    //         "Something went wrong"
    //       );
    // });
  };



  const handleTableChange = (type, newState) => {

    setPage(newState.page);
    setSizePerPage(newState.sizePerPage);
    setOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("");
      setSortOrder("");
    }
    if (newState.sortField === "uploadedUserName") {
      setSortColumn("au.name");
      setSortOrder(newState.sortOrder);
      setReloadUserData(reloadUserData + 1);
    } else if (newState.sortField === "processedUserName") {
      setSortColumn("adn.name");
      setSortOrder(newState.sortOrder);
      setReloadUserData(reloadUserData + 1);
    } else if (newState.sortField === "groupName") {
      setSortColumn("g.name");
      setSortOrder(newState.sortOrder);
      setReloadUserData(reloadUserData + 1);

    } else if (newState.sortField === "updatedOn") {
      setSortColumn("gfr.updatedOn");
      setSortOrder(newState.sortOrder);
      setReloadUserData(reloadUserData + 1);

    } else if (newState.sortField === "status") {
      setSortColumn("gfr.status");
      setSortOrder(newState.sortOrder);
      setReloadUserData(reloadUserData + 1);

    } else {
      setSortColumn(newState.sortField);
      setSortOrder(newState.sortOrder);
      setReloadUserData(reloadUserData + 1);
    }
  };


  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Mark as Processed";
      badgeclass = "badge badge-primary statustoggle status-button-width";
    } else {
      status = "Processed";
    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setStatus(cell);
          setTableID(row.id)
          setModalState(cell === 1 ? true : false);
        }}
      >
        {status}
      </span>
    );
  };


  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const clearValues = () => {

    setGroupName("")
    setStatusFilter("")
    setUserTag("")
    setSearchKeyword("")
    setFileType("");
    setReloadUserData(reloadUserData + 1)
  }

  const userTypeValidation = (cell) => {
    if (cell == 1) { // eslint-disable-line
      return ''
    } else {
      return ''
    }
  }

  const statusFormatterCSV = (cell) => {
    return cell ? "" : ""
  }


  const actionFormatter = (cell, row) => {

    return (
      <>
        <FontAwesomeIcon
          icon={faDownload}
          fixedWidth
          className="align-middle"
          title="Download"
          onClick={() => {
            setModalStateDownload(true)
            setGroupId(row.groupID)
            setFileName(row.fileName)
          }}
        />
      </>
    )
  }
  const dobFormatter = (cell) => {
    return cell ? moment.utc(cell).local().format('DD-MMM-YYYY hh:mm a') : "N/A"
  }

  const data = [/* array of objects */];
  data.sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn));

  const tableColumns = [
    {
      dataField: "groupName",
      text: "Group Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: userTypeValidation,
    },
    {
      dataField: "fileName",
      text: "File Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "fileType",
      text: "File Type",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "tags",
      text: "Tag Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: "uploadedUserName",
      text: "Uploaded By",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: "createdOn",
      text: "Uploaded On",
      formatter: dobFormatter,
      csvFormatter: basicValidation,
      sort: true,
    },
    {
      dataField: "status",
      text: "Process Status",
      sort: true,
      formatter: statusFormatter,
      csvFormatter: statusFormatterCSV,
    },
    {
      dataField: "processedUserName",
      text: "Processed By",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "updatedOn",
      text: "Processed On",
      sort: true,
      formatter: dobFormatter,
      csvFormatter: basicValidation,
    },
    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
    }

  ];// eslint-disable-line

  let loader = null;// eslint-disable-line


  useEffect(() => {
    fileTypeOptions()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fileExchangeList.length === 0) {
      setBlankStateStatus(true)
    } else {
      setBlankStateStatus(false)
    }
  }, [fileExchangeList]);

  useEffect(() => {
    if (groupName?.length > 0 || status?.length > 0 || userTag?.length > 0) {
      fetchUsers()
    }
  }, [groupName, userTag, status]);// eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length >= 2) {
      setIsGlobalSearch(true);
    }
  }, [searchKeyword]) // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length === 0) {
      setReloadUserData(reloadUserData + 1)
    }
  }, [searchKeyword]) // eslint-disable-line

  useEffect(() => {
    if (reloadUserData > 0) {
      fetchUsers()
    }
  }, [reloadUserData])// eslint-disable-line

  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0);
      setTotalSize(0);
      fetchUsers();
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]) // eslint-disable-line

  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>File Exchange</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>File Exchange</BreadcrumbItem>
          </Breadcrumb>
        </Header>
      </Container>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="3">
              <Label>Group name</Label>
              <AsyncSelect
                cacheOptions
                name="groupName"
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                loadOptions={loadOptions}
                placeholder="Select group"
                isSearchable
                isClearable
                value={groupName}
                onInputChange={handleInputChange}
                onChange={handleChange}
              />
            </Col>
            <Col sm="3">
              <Label>Tag Name</Label>
              <AsyncSelect
                cacheOptions
                name="tag"
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                loadOptions={tagOptions}
                placeholder="Select Tag"
                isSearchable
                isClearable
                value={userTag ? userTag : ""}
                onInputChange={tagInputChange}
                onChange={tagChange}
              />
            </Col>
            <Col sm="3">
              <Label>File Type</Label>
              <Select
                options={typeOptions}
                placeholder="Select File Type"
                isClearable
                value={fileType ? fileType : ""}
                onChange={fileChange}
              />
            </Col>
            <Col sm="3">
              <Label>Status</Label>
              <Select
                options={StatusOptions}
                placeholder="Select Status"
                isClearable
                value={statusFilter}
                onChange={(event) => {
                  setStatusFilter(event);
                }}
              />
            </Col>
            <Col>
              <Button
                type="submit"
                className="go-buttons"
                onClick={() => {
                  setSearchKeyword("");
                  fetchUsers();
                }}
              >
                Go
              </Button>
              <Button
                type="reset"
                className="reset-buttons"
                onClick={() => {
                  clearValues();
                }}
              >
                Reset
              </Button>
            </Col>
          </Row>

          <div className="separator" />
        </CardHeader>

        <div className="groups-search">
          <SearchBarComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={tableColumns}
            clearValues={clearValues}
          />
        </div>
        {!loading && fileExchangeList.length === 0 ? (
          blankState
        ) : !loading ? (
          <RemotePagination
            data={fileExchangeList}
            columns={tableColumns}
            sizePerPage={sizePerPage}
            FileExportName={fileExportName}
            exportTableData={exportTableData}
            totalSize={totalSize || ""}
            onTableChange={(type, newState) => {
              handleTableChange(type, newState);
            }}
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
          />
        ) : (
          <Spinner />
        )}
      </Card>

      <ProcessStatusChangeModal
        modalState={modalState}
        setModalState={setModalState}
        setStatus={setStatus}
        setTableID={setTableID}
        tableID={tableID}
        status={status}
        setReloadUserData={setReloadUserData}
        reloadUserData={reloadUserData}
      />

      <ActionDownloadModal
        modalState={modalStateDownload}
        setModalState={setModalStateDownload}
        setGroupId={setGroupId}
        groupId={groupId}
        setFileName={setFileName}
        FileName={FileName}
      />
    </div>
  );
};

export default FormExchange;
