import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import AsyncSelect from "react-select/async"
import Cookie from "js-cookie"
import AxiosInter from './../../AxiosInter.js'
import { toastr } from "react-redux-toastr"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import AddNewHIMUser from '../../newComponents/utilities/AddNewHIMUser.js'
import EditHIMUser from '../../newComponents/utilities/EditHIMUser.js'
import './Tables.css'

function HIMUsers() {
   const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
   const API_TOKEN = Cookie.get("access_token_admin")

   const [HIMUsersList, setHIMUsersList] = useState([])
   const [totalCount, setTotalCount] = useState(0)
   const [groupName, setGroupName] = useState("")
   const [facility, setFacility] = useState("")
   const [appUser, setappUser] = useState("")
   const [searchKey, setSearchKey] = useState("")
   const [reload, setReload] = useState(true)
   const [isAddNew, setIsAddNew] = useState(false)
   const [statusUpdate, setStatusUpdate] = useState(false)
   const [clickedRow, setclickedRow] = useState("")
   const [isEditModal, setIsEditModal] = useState(false)
   const [loader, setLoader] = useState(false)

   const handleAppUserChange = (appUser) => {
      setappUser(appUser)
   }

   const handleGroupChange = (value) => {
      setGroupName(value)
   }

   const handleFacilityChange = (value) => {
      setFacility(value)
   }

   const toggleStatusUpdate = () => {
      setStatusUpdate(!statusUpdate)
   }

   const toggleEditModal = () => {
      setIsEditModal(!isEditModal)
   }

   const clearValues = () => {
      setGroupName("")
      setFacility("")
      setappUser("")
      setReload(true)
   }

   const GetHIMUsersList = () => {
      setLoader(true)
      const params = {
         "groupName": (groupName?.label) ? groupName.label : '',
         "appuserName": appUser ? appUser.label : '',
         "facilityName": (facility?.label) ? facility.label : '',
         "status": "",
         "searchKeyword": searchKey ? searchKey : '',
         "is_pagination": "1",
         "record_limit": "",
         "off_set": "",
         "sortOrder": "desc",
         "sortColumn": ""
      }

      AxiosInter
         .post(`${API_ENDPOINT}/admin-api/list-referral-sec-assignee-facility-mapping/`, params, {
            headers: {
               Authorization: API_TOKEN,
            }
         })
         .then((response) => {
            setHIMUsersList(response.data.data)
            setTotalCount(response.data.total_record_count)
            setReload(false)
            setLoader(false)
         })
         .catch((error) => {
            toastr.error("Failed", "Something went wrong")
            setLoader(false)
         })
   }

   const handleSearchKeyChange = (e) => {
      setSearchKey(e.target.value)
      // time delay for api to call
      // other wise every letter user click an api will call
      setTimeout(() => {
         setReload(true)
      }, 1000);
   }

   const groupNameloadOptions = (inputValue) => {
      if (inputValue.length >= 4) {
         const searchParams = new URLSearchParams({ name: inputValue })
         return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
            {
               headers: {
                  Authorization: API_TOKEN,
               },
            }
         )
            .then((response) => response.data.data)
            .catch((error) => {
               throw error
            })
      }
   }

   const appUserloadOptions = (inputValue) => {
      if (inputValue.length >= 4) {
         const searchParams = new URLSearchParams({ name: inputValue });
         return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-app-user-by-names-list/?${searchParams.toString()}`,
            {
               headers: {
                  Authorization: API_TOKEN,
               },
            }
         )
            .then((response) => response.data.data)
            .catch((error) => {
               throw error;
            });
      }
   };

   const facilityNameloadOptions = (inputValue) => {
      if (inputValue.length >= 4) {
         const searchParams = new URLSearchParams({ name: inputValue });
         return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-names-list/?${searchParams.toString()}`,
            {
               headers: {
                  Authorization: API_TOKEN,
               },
            }
         )
            .then((response) => response.data.data)
            .catch((error) => {
               throw error;
            });
      }
   };

   const validation = (cell) => {
      return cell ? cell : "N/A"
   }

   const ActionFormatter = (cell, row) => {
      return (
         <>
            <FontAwesomeIcon
               icon={faEdit}
               color={"#3f86fa"}
               style={{ cursor: "pointer" }}
               onClick={() => {
                  setclickedRow(row)
                  setIsEditModal(true)
               }}
            />
         </>
      )
   }

   const handleChangeStatus = () => {
      setLoader(true)
      let params = {
         "id": clickedRow.id,
         "status": (clickedRow.status === "Active") ? 0 : 1,
      }
      AxiosInter
         .post(`${API_ENDPOINT}/admin-api/status-update-referral-sec-assignee-facility-mapping/`, params, {
            headers: {
               Authorization: API_TOKEN,
            }
         })
         .then((response) => {
            if (response.status === 200) {
               toastr.success("Success", "Status updated")
               setReload(true)
               setStatusUpdate(false)
               setclickedRow("")
               setLoader(false)
            }
         })
         .catch((error) => {
            toastr.error("Failed", "Something went wrong, status not updated")
            setStatusUpdate(false)
            setclickedRow("")
            setLoader(false)
         })

   }

   const statusFormatter = (cell, row) => {
      if (cell && cell === 'Active') {
         return <span class="badge badge-success statustoggle status-button-width"
            onClick={() => {
               setStatusUpdate(true)
               setclickedRow(row)
            }}>
            {cell}
         </span>
      }
      else {
         return <span class="badge badge-danger statustoggle status-button-width"
            onClick={() => {
               setStatusUpdate(true)
               setclickedRow(row)
            }}>
            {cell}
         </span>
      }
   }

   const columns = [
      {
         dataField: "group_name",
         text: "Group name",
         formatter: validation,
         headerStyle: { width: '140px' },
         style: { width: '140px' },
         // sort: true,
      },
      {
         dataField: "subscriptionPlanName",
         text: "Subscription",
         sort: true,
         formatter: validation,
         headerStyle: { width: '140px' },
         style: { width: '140px' },
       },
      {
         dataField: "appUser_name",
         text: "App user name",
         formatter: validation,
         headerStyle: { width: '140px' },
         style: { width: '140px' },
         // sort: true,
      },
      {
         dataField: "facility_name",
         text: "Facility name",
         formatter: validation,
         headerStyle: { width: '150px' },
         style: { width: '150px' },
         // sort: true,
      },
      {
         dataField: "status",
         text: "status",
         formatter: statusFormatter,
      },
      {
         dataField: "created_by",
         text: "created_by",
         formatter: validation,
      },
      {
         dataField: "created_on",
         text: "created_on",
         formatter: validation,
      },
      {
         dataField: "updated_by",
         text: "updated_by",
         formatter: validation,
      },
      {
         dataField: "updated_on",
         text: "updated_on",
         formatter: validation,
      },
      {
         dataField: "id",
         text: "Actions",
         sort: false,
         formatter: ActionFormatter,
      },
   ]

   const options = {
      page: 1,
      nextPageText: ">",
      prePageText: "<",
      sizePerPage: 50,
      sizePerPageList: [50, 100, 150, 200],
      totalSize: HIMUsersList?.length,
   }

   // if no filter values are present button will disabled
   const buttonDisable = () => {
      if ((groupName === null || groupName === "") && (facility === "") && (appUser === null || appUser === "")) {
         return true
      }
      else return false
   }

   const toggleModal = () => {
      setIsAddNew(!isAddNew)
   };

   // if no data in table then show blankState
   const blankState = (
      <Card className="blank-state">
         <CardBody>
            <h3>No records available</h3>
         </CardBody>
      </Card>
   )

   useEffect(() => {
      if (reload) {
         GetHIMUsersList()
      }
   }, [reload]) // eslint-disable-line

   return (
      <>
         {
            loader && <Spinner />
         }
         <ToolkitProvider
            keyField="id"
            data={HIMUsersList}
            columns={columns}
            search
         >
            {(props) => (
               <div>
                  <Card>
                     <CardHeader>
                        <div className="separator" />
                        <Row>
                           <Col xs={4}>
                              <Label>Group name:</Label>
                              <AsyncSelect
                                 cacheOptions
                                 isClearable
                                 isSearchable
                                 type="text"
                                 name="groupName"
                                 placeholder="Search with at least 4 characters."
                                 value={groupName}
                                 onChange={handleGroupChange}
                                 loadOptions={(inputValue) => groupNameloadOptions(inputValue)}
                              />
                           </Col>
                           <Col xs={4}>
                              <Label>Facility:</Label>
                              <AsyncSelect
                                 cacheOptions
                                 isClearable
                                 isSearchable
                                 type="text"
                                 name="facility"
                                 placeholder="Search with at least 4 characters."
                                 value={facility}
                                 onChange={handleFacilityChange}
                                 loadOptions={(inputValue) => facilityNameloadOptions(inputValue)}
                                 required
                              />
                           </Col>
                           <Col xs={4}>
                              <Label>App user:</Label>
                              <AsyncSelect
                                 cacheOptions
                                 isClearable
                                 isSearchable
                                 type="text"
                                 name="appusername"
                                 placeholder="Search with at least 4 characters."
                                 value={appUser}
                                 onChange={handleAppUserChange}
                                 loadOptions={(inputValue) => appUserloadOptions(inputValue)}
                              />
                           </Col>
                           <Col sm="12">
                              <Button
                                 className="patient-go-buttons"
                                 disabled={buttonDisable()}
                                 title={buttonDisable() ? 'Choose at least one item to search' : ''}
                                 onClick={GetHIMUsersList}
                              >
                                 Go
                              </Button>{" "}
                              <Button
                                 className="patient-reset-buttons"
                                 onClick={clearValues}
                                 disabled={buttonDisable()}
                                 title={buttonDisable() ? "Nothing To Clear" : ""}
                              >
                                 Reset
                              </Button>
                           </Col>
                        </Row>
                        <Row>
                           <Col xs={2}>
                              <div className="number-of-records">
                                 <p>
                                    Number of records: <strong>{totalCount}</strong>
                                 </p>
                              </div>
                           </Col>
                           <Col>
                              <div className="form-inline justify-content-end">
                                 <div className="form-group mr-2">
                                    <Input
                                       className="search-box-new"
                                       type="text"
                                       value={searchKey}
                                       onChange={handleSearchKeyChange}
                                       placeholder="Search"
                                    />
                                 </div>
                                 <button
                                    type="button"
                                    className="clear-search-box btn btn-secondary"
                                    disabled={(searchKey === "") ? true : false}
                                    onClick={() => {
                                       setSearchKey("")
                                       setReload(true)
                                    }}
                                 >
                                    Clear
                                 </button>
                              </div>
                           </Col>
                           <Button
                              className="referral-add-btn ml-2 pull-right"
                              onClick={() => setIsAddNew(true)}
                           >
                              <FontAwesomeIcon
                                 icon={faPlus}
                                 fixedWidth
                                 className="align-middle"
                                 title="See Fulfillers"
                              />
                              Add HIM User
                           </Button>
                        </Row>
                        <div className="separator" />
                     </CardHeader>
                     <CardBody>
                        {HIMUsersList.length > 0 ? (
                           <>
                              <div className="scrollTable">
                                 <div className='assignee-mapping'>
                                    <BootstrapTable
                                       bootstrap4
                                       {...props.baseProps}
                                       keyField="id"
                                       data={HIMUsersList}
                                       columns={columns}
                                       pagination={paginationFactory(options)}
                                    />
                                 </div>
                              </div>
                           </>
                        ) : (
                           blankState
                        )}
                     </CardBody>
                  </Card>
               </div>
            )}
         </ToolkitProvider>
         {isAddNew && (
            <AddNewHIMUser
               isOpen={isAddNew}
               setIsOpen={setIsAddNew}
               toggleModal={toggleModal}
               setApiLoaded={setReload}
               setReload={setReload}
            />
         )}
         {
            statusUpdate &&
            <>
               <Modal isOpen={statusUpdate}>
                  <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggleStatusUpdate}>Change Status</ModalHeader>
                  <ModalBody>
                     {
                        loader && <Spinner />
                     }
                     <p className='text-center'>
                        Are you sure you want to change status?
                     </p>
                  </ModalBody>
                  <ModalFooter className="custom-modal-footer">
                     <Button className="yes-button button-width" onClick={handleChangeStatus}>Yes</Button>{' '}
                     <Button outline className="no-button button-width" onClick={toggleStatusUpdate}>No</Button>
                  </ModalFooter>
               </Modal>
            </>
         }
         {isEditModal && (
            <EditHIMUser
               clickedRow={clickedRow}
               isEditModal={isEditModal}
               setIsEditModal={setIsEditModal}
               toggleEditModal={toggleEditModal}
               setReload={setReload}
            />
         )}
      </>
   )
}

export default HIMUsers