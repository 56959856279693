import React, { useState, useEffect, useContext } from "react";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Spinner,
} from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "../../hoc/Hux";
import ExportCSVGPTable from "./GroupAppUserTable.js";
import { faEdit, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalState } from "../../contextAPI/Context";
import NewGroupAppUserModal from "../../newComponents/utilities/NewGroupAppUserModal";
import EditAppUserModal from "../../newComponents/utilities/EditAppUserModal";
import StatusChangeModalAppUsers from "../../newComponents/utilities/StatusChangeModalAppUsers";
import SsoLoginChangeForUser from "../../newComponents/utilities/SsoLoginChangeForUser";
import CSVUploadModal from "../../newComponents/utilities/CSVUploadModal";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import MomentConfig from "../../common/MomentConfig";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const GroupAppUsers = () => {
  // CONTEXT VARIABLES
  const {
    appUserName,
    appUserRole,
    appUserUsername,
    appUserDesignation,
    appUserNPI,
    appUserEmail,
    appUserStatus,
    appUserGroup,
    spinner,
    setSpinner,
    appUserReload,
    setAppUserReload,
    setBlankStateMessage,
    groupNamesList,
    countryCodeList,
    FetchCountryListAPICall,
  } = useContext(GlobalState);

  // LOCAL VARIABLES
  const [appUsersList, setAppUserList] = useState([]);
  const [newAppUserModal, setNewAppUserModal] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [modalStateStatus, setModalStateStatus] = useState(false);
  const [AppUserID, setAppUserID] = useState("");
  const [status, setStatus] = useState("");
  const [groupName, setGroupName] = useState("");
  const [newnNotificationEmail, setNewNotificationEmail] = useState([]);
  const [editAppUserModal, setEditAppUserModal] = useState(false);
  const [editAppUserID, setEditAppUserID] = useState("");
  const [editAppUserName, setEditAppUserName] = useState("");
  const [editFirstName, setEditFirstName] = useState("");
  const [editMiddleName, setEditMiddleName] = useState("");
  const [editLastName, setEditLastName] = useState("");
  const [editAppUserType, setEditAppUserType] = useState("");
  const [editAppUserDesignation, setEditAppUseDesignation] = useState("");
  const [editAppUserNPI, setEditAppUserNPI] = useState("");
  const [editAppUserEmail, setEditAppUseEmail] = useState("");
  const [editAppUserUserName, setEditAppUseUserName] = useState("");
  const [editAppUserGroupID, setEditAppUseGroupID] = useState("");
  const [editAppUserPassword, setEditAppUsePassword] = useState("");
  const [editAppUserZIP, setEditAppUseZIP] = useState("");
  const [editAppUserSSN, setEditAppUserSSN] = useState("");
  const [editAppUserPhone, setEditAppUserPhone] = useState("");
  const [editAppUserIsServiceProvider, setEditAppUserIsServiceProvider] =
    useState("");
  const [editAppUserSecurityID, setEditAppUserSecurityID] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [offset, setOffset] = useState("0");
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [sizePerPage, setSizePerPage] = useState("50");
  const [searchKeys, setSearchKeys] = useState("");
  const [uploadModal, setUploadModal] = useState(false);
  const [groupDropDownOptions, setGroupDropDownOptions] = useState([]);
  const [appUserEditStatus, setAppUserEditStatus] = useState("");
  const [allowLogin, setAllowLogin] = useState({});
  const [allowSsoLogin, setAllowSsoLogin] = useState({});
  const fileExportName = "App-Users";
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;
  const [editEmailDigestToggle, setEditEmailDigestToggle] = useState(false);
  const [appUserIdList, setAppUserIdList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [signature, setSignature] = useState('');
  const [editRestricPatientAccessToggle, setEditRestrictPatientAccessToggle] = useState(false);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");

  const [externalModelEnabled, setExternalModelEnabled] = useState(false);
  const [externalModelSelected, setExternalModelSelected] = useState([]);
  const [externalUserId, setExternalUserId] = useState("");
  const [enabledBulkActionGroupApp, setEnabledBulkActionGroupApp] = useState(false);
  const [proxyLoginToggleGroupApp, setProxyLoginToggleGroupApp] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();
  let { id } = useParams();
  let groupDetailsDataCheck = false; //eslint-disable-line
  const selectedName = () => {
    setGroupName(window.atob(id));
  };
  const goBack = () => {
    navigate(-1);
  };

  const fetchAppUsersList = async () => {
    setSpinner(true);
    var temp = "";
    if (sortColumn === "designation") {
      temp = "m_role.designation";
    } else {
      temp = sortColumn;
    }
    const params = {
      app_user_name: appUserName ? appUserName.label : "",
      app_user_type: appUserRole ? appUserRole.value : 0,
      app_user_designation: appUserDesignation ? appUserDesignation : "",
      app_user_npi: appUserNPI ? appUserNPI : "",
      app_user_email: appUserEmail ? appUserEmail : "",
      app_user_status: appUserStatus ? appUserStatus.value : "",
      app_user_username: appUserUsername ? appUserUsername.label : "",
      app_user_group_id: id ? id : "",
      record_limit: sizePerPage ? sizePerPage.toString() : "50",
      off_set: offset ? offset.toString() : "0",
      search_keyword: searchKeys ? searchKeys : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: temp ? temp : "",
      is_pagination: "1",
    };

    await AxiosInter.post(
      `${API_ENDPOINT}/admin-api/get-app-details-by-group/`,
      params,
      {
        headers: {
          Authorization: API_TOKEN,
        },
      }
    )
      .then(function (response) {
        setBlankStateMessage(response.data.data.length > 0 ? false : true);
        if (response.data.data) {
          setTotalSize(response.data.record_count);
          setAppUserList(response.data.data);
          localStorage.setItem("offset", offset);
          setSpinner(false);
        } else {
          setAppUserList([]);
          setBlankStateMessage(true);
          setSpinner(false);
        }
      })
      .catch(function (error) {
        // toastr.warning('Error Occurred', 'Please try again');
        setBlankStateMessage(true);
        setSpinner(false);
      });
  };
  const exportTableData = () => {
    var temp = "";
    if (sortColumn === "designation") {
      temp = "m_role.designation";
    } else {
      temp = sortColumn;
    }
    const params = {
      file_name: fileName ? fileName : "",
      app_user_name: appUserName ? appUserName.label : "",
      app_user_type: appUserRole ? Number(appUserRole.value) : 0,
      // "app_user_designation": appUserDesignation ? appUserDesignation : "",
      app_user_npi: appUserNPI ? appUserNPI : "",
      app_user_email: appUserEmail ? appUserEmail : "",
      app_user_status: appUserStatus ? appUserStatus.value : "",
      app_user_username: appUserUsername ? appUserUsername.label : "",
      appUserEmailDigest: "",
      app_user_group_id: id ? window.atob(id) : "",
      record_limit: "",
      off_set: offset ? offset.toString() : "0",
      search_keyword: searchKeys ? searchKeys : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: temp ? temp : "",
      is_pagination: "1",
    };

    AxiosInter.post(
      `${API_ENDPOINT}/admin-api/export-appusers-table-data/`,
      params,
      {
        headers: {
          Authorization: API_TOKEN,
        },
        responseType: "blob",
      }
    )
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const createGroupOption = () => {
    var array = groupNamesList;
    const options = array.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setGroupDropDownOptions(options);
  };

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else if (cell === 0) {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    } else {
      status = "Locked";
      badgeclass = "badge badge-warning statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setAppUserID(row.id);
          setStatus(cell);
          setModalStateStatus(true);
        }}
      >
        {status}
      </span>
    );
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };

  const statusFormatterCSV = (cell) => {
    return cell ? "Active" : "Inactive";
  };

  const TimeFormatter = (cell) => {
    return cell
      ? `${moment(cell).format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)} (UTC)`
      : "--";
  };

  const actionFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            // storing external model informations to states
            setExternalModelEnabled(row.isExternalModelEnabled)
            setExternalModelSelected(JSON.parse(row.externalModel))
            setEditAppUserModal(true);
            editUserData(
              row.GroupID,
              row.id,
              row.TypeID,
              row.designation,
              row.status,
              row.email,
              row.name,
              row.npi,
              row.password,
              row.username,
              row.zipID,
              row.ssn,
              row.phone,
              row.isServiceProvider,
              row.SecurityID,
              row.firstName,
              row.middleName,
              row.lastName,
              row.digestEmailEnabled,
              row.signatureCredential,
              row.externalUserId,
              row.allowBulkActions,
              row.isProxyLoginEnabled
            );
            onClickNotigicationP(row.notificationEmail);
          }}
          color="primary"
          title="Edit App User"
        >
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="align-middle"
            title="Edit App User"
          />
        </Button>
        {allowLogin === 1 ?
          <Button
            className="money-button"
            outline
            onClick={() => {
              setAppUserID(row.id);
              setModalState(true);
            }}
            color="#3B7DDD"
            size={'1x'}
            title=""
          >
            <FontAwesomeIcon
              icon={faSignInAlt}
              fixedWidth
              className="align-middle"
              title="Allow Login via SSO Only"
            />
          </Button> : ""}
      </>
    );
  };
  const onClickNotigicationP = (email) => {
    var temp = [];
    if (email === null) {
      setNewNotificationEmail("");
    } else {
      var parsedArray = JSON.parse(email);
      // eslint-disable-next-line array-callback-return
      parsedArray.map((item) => {
        temp.push(item.trim());
      });
    }
    setNewNotificationEmail(temp);
  };

  const editUserData = (
    GroupIDs,
    id,
    TypeID,
    designation,
    status,
    email,
    name,
    npi,
    password,
    username,
    zipID,
    ssn,
    phone,
    isServiceProvider,
    securityID,
    firstName,
    middleName,
    lastName,
    digestEmailEnabled,
    signatureCredential,
    externalUserId,
    enabledBulkActionGroupApp,
    proxyLoginToggleGroupApp
  ) => {
    if (signatureCredential) {
      setSignature(signatureCredential)
    }
    else {
      setSignature('')
    }
    setEditAppUserID(id);
    setEditAppUserName(name);
    setEditAppUserType(TypeID);
    setEditAppUseDesignation(designation);
    setAppUserEditStatus(status.toString());
    setEditAppUserNPI(npi);
    setEditAppUseEmail(email);
    setEditAppUseUserName(username);
    if (securityID) {
      setEditAppUserSecurityID(securityID.toString());
    } else {
      setEditAppUserSecurityID(securityID);
    }
    setEditAppUseGroupID(GroupIDs);
    setEditAppUsePassword(password);
    setEditAppUseZIP(zipID);
    setEditAppUserSSN(ssn);
    setEditAppUserPhone(phone);
    setEditAppUserIsServiceProvider(isServiceProvider);
    setEditFirstName(firstName);
    setEditMiddleName(middleName);
    setEditLastName(lastName);
    setEditEmailDigestToggle(digestEmailEnabled);
    setExternalUserId(externalUserId);
    setEnabledBulkActionGroupApp(enabledBulkActionGroupApp)
    setProxyLoginToggleGroupApp(proxyLoginToggleGroupApp)
  };

  const tableColumns = [
    {
      dataField: "username",
      text: "User Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "GroupName",
      text: "Group Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "designation",
      text: "Role Type",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "externalUserId",
      text: "External Userid",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "designation",
      text: "Designation",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: "status",
      text: "Status",
      formatter: statusFormatter,
      csvFormatter: statusFormatterCSV,
    },
    {
      dataField: "lastLoginTime",
      text: "Last Login",
      formatter: TimeFormatter,
      csvFormatter: TimeFormatter,
    },
    {
      dataField: "createDate",
      text: "Create Date",
      formatter: TimeFormatter,
      csvFormatter: TimeFormatter,
    },
    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
    },
  ];

  const handleTableChange = (type, newState) => {
    // debugger
    setPage(newState.page);
    setSizePerPage(newState.sizePerPage);
    setOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("");
      setSortOrder("");
    } else {
      setSortColumn(newState.sortField);
      setSortOrder(newState.sortOrder);
      setAppUserReload(appUserReload + 1);
    }
    // const currentIndex = 0;

    // if (newState.sortField !== "" && newState.sortOrder !== "") {
    // let result;
    // if (newState.sortOrder === 'asc') {
    // result = appUsersList.sort((a, b) => {
    // if (a[newState.sortField] > b[newState.sortField]) {
    // return 1;
    // } else if (b[newState.sortField] > a[newState.sortField]) {
    // return -1;
    // }
    // return 0;
    // });
    // } else {
    // result = appUsersList.sort((a, b) => {
    // if (a[newState.sortField] > b[newState.sortField]) {
    // return -1;
    // } else if (b[newState.sortField] > a[newState.sortField]) {
    // return 1;
    // }
    // return 0;
    // });
    // }

    // setAppUserList(result.slice(currentIndex, currentIndex + sizePerPage));

    // }
  };
  let groupId = localStorage.getItem("groupId")
  const getSsoToggleValues = () => {
    // debugger
    AxiosInter
      .get(`${API_ENDPOINT}/admin-api/get-sso-toggle-values/${groupId}`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          // console.log("isSsoEnabled",response.data.data[0]['isSsoEnabled'])
          setAllowLogin(response.data.data[0]['isEnableSsoIntegration'])
          setAllowSsoLogin(response.data.data[0]['isEnableSsoLogin'])
          console.log('resp', allowLogin)
          // setSsoLoginToggle(response.data.data[0]['isSsoEnabled'])
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong!");
      });
  };
  // DEFAULT API CALL
  useEffect(() => {
    if (NewGroupAppUserModal) {
      selectedName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NewGroupAppUserModal]);

  useEffect(() => {
    if (appUserReload > 0) {
      fetchAppUsersList();
      getSsoToggleValues();
    }
  }, [appUserReload]); // eslint-disable-line

  useEffect(() => {
    getSsoToggleValues();
  }, [appUserReload])//eslint-disable-line
  useEffect(() => {
    if (countryCodeList.length <= 0) {
      FetchCountryListAPICall();
    }
  }, [countryCodeList]); // eslint-disable-line

  useEffect(() => {
    if (
      appUserName !== "" ||
      appUserRole !== "" ||
      appUserNPI !== "" ||
      appUserEmail !== "" ||
      appUserStatus !== "" ||
      appUserUsername !== "" ||
      appUserGroup !== ""
    ) {
      fetchAppUsersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appUserName,
    appUserRole,
    appUserDesignation,
    appUserNPI,
    appUserEmail,
    appUserStatus,
    appUserUsername,
    appUserGroup,
  ]); // eslint-disable-line

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (searchKeys.length > 3 || searchKeys == "")
      setAppUserReload(appUserReload + 1);
  }, [searchKeys]); // eslint-disable-line

  useEffect(() => {
    if (groupNamesList) {
      createGroupOption();
    }
  }, [groupNamesList]); // eslint-disable-line

  useEffect(() => {
    if (appUsersList) {
      localStorage.setItem("sizePerPage", sizePerPage);
    }
  }, [appUsersList]); // eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if (offset !== previousOffset) {
      fetchAppUsersList();
    }
  }, [offset, sizePerPage]); // eslint-disable-line
  useEffect(() => {
    if (location.state) {
      localStorage.setItem(
        "isFilterApplied",
        JSON.stringify(location.state.detail)
      );
    }
  }, [location]);
  let appuserid = [];
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    appUsersList.map((item) => {
      appuserid.push(item.id);
    });
    setAppUserIdList(appuserid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUsersList]);
  const handleBulkDigest = async () => {
    try {
      setIsLoading(true);
      if (appUserIdList.length === 0) {
        toastr.warning("No data to assign email digest");
        setIsLoading(false);
        return;
      }
      const response = await AxiosInter.post(`${API_ENDPOINT}/admin-api/bulk-enable-email-digest/`, {
        appUserIdList,
      });
      if (response.status === 200) {
        setIsLoading(false);
        toastr.success("Email digest enabled");
      }
    } catch (error) {
      setIsLoading(false);
      toastr.error("Error occurred");
    }
  };
  return (
    <Hux>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Groups
            <Button
              className="Add-btn pull-right"
              onClick={() => {
                setNewAppUserModal(true);
                getSsoToggleValues()
              }}
            >
              Add App User
            </Button>{" "}
            <Button
              className="Add-btn pull-right"
              style={{ marginRight: "0.225rem" }}
              onClick={() => {
                setUploadModal(true);
              }}
            >
              Import App Users
            </Button>
            <Button
              className="Add-btn pull-right"
              // style={{ marginRight: '0.225rem' }}
              onClick={() => {
                handleBulkDigest();
              }}
            >
              Bulk Assign Email Digest
            </Button>
          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>App Users</BreadcrumbItem>

            <BreadcrumbItem>
              <Link to="/groups">Groups</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>App Users By Group</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <Card className="adv-search-background-card-pt2">
          <Container fluid>
            <Row style={{ marginBottom: "0rem" }}>
              <div style={{ justifyContent: "left" }}>
                <FontAwesomeIcon
                  size={"lg"}
                  style={{ cursor: "pointer" }}
                  title={"Go Back"}
                  icon={faArrowLeft}
                  onClick={goBack}
                />
              </div>
              <Col>
                <h2 style={{ textAlign: "center" }}>
                  {localStorage.getItem("groupName")}
                </h2>
              </Col>
            </Row>
          </Container>
        </Card>

        {spinner ? <Spinner /> : ""}
        {isLoading && <Spinner />}
        <ExportCSVGPTable
          data={appUsersList}
          columns={tableColumns}
          onTableChange={(type, newState) => {
            handleTableChange(type, newState);
          }}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalSize ? totalSize : 0}
          setPage={setPage}
          setOffset={setOffset}
          setTotalSize={setTotalSize}
          setSearchKeys={setSearchKeys}
          searchKeys={searchKeys}
          appUserReload={appUserReload}
          setAppUserReload={setAppUserReload}
          offset={offset}
          exportTableData={exportTableData}
          groupID={groupId}
        />
      </Container>
      <NewGroupAppUserModal
        newAppUserModal={newAppUserModal}
        setNewAppUserModal={setNewAppUserModal}
        setNewGroupName={setGroupName}
        newGroupName={groupName}
        AppUserID={AppUserID}
        allowLogin={allowLogin}
        allowSsoLogin={allowSsoLogin}
        setEnabledBulkActionGroupApp={setEnabledBulkActionGroupApp}
        enabledBulkActionGroupApp={enabledBulkActionGroupApp}
        proxyLoginToggleGroupApp={proxyLoginToggleGroupApp}
        setProxyLoginToggleGroupApp={setProxyLoginToggleGroupApp}
      />
      <EditAppUserModal
        signature={signature}
        setSignature={setSignature}
        editRestricPatientAccessToggle={editRestricPatientAccessToggle}
        setEditRestrictPatientAccessToggle={setEditRestrictPatientAccessToggle}
        editAppUserModal={editAppUserModal}
        setEditAppUserModal={setEditAppUserModal}
        editAppUserName={editAppUserName}
        setEditAppUserName={setEditAppUserName}
        editAppUserType={editAppUserType}
        setEditAppUserType={setEditAppUserType}
        editAppUserNPI={editAppUserNPI}
        editAppUserDesignation={editAppUserDesignation}
        setEditAppUseDesignation={setEditAppUseDesignation}
        setEditAppUserNPI={setEditAppUserNPI}
        editAppUserEmail={editAppUserEmail}
        setEditAppUseEmail={setEditAppUseEmail}
        editAppUserUserName={editAppUserUserName}
        setEditAppUseUserName={setEditAppUseUserName}
        editAppUserGroupID={editAppUserGroupID}
        setEditAppUseGroupID={setEditAppUseGroupID}
        editAppUserPassword={editAppUserPassword}
        setEditAppUsePassword={setEditAppUsePassword}
        editAppUserZIP={editAppUserZIP}
        setEditAppUseZIP={setEditAppUseZIP}
        editAppUserSSN={editAppUserSSN}
        setEditAppUserSSN={setEditAppUserSSN}
        editAppUserPhone={editAppUserPhone}
        setEditAppUserPhone={setEditAppUserPhone}
        editAppUserIsServiceProvider={editAppUserIsServiceProvider}
        setEditAppUserIsServiceProvider={setEditAppUserIsServiceProvider}
        setEditAppUserID={setEditAppUserID}
        editAppUserID={editAppUserID}
        editAppUserSecurityID={editAppUserSecurityID}
        setEditAppUserSecurityID={setEditAppUserSecurityID}
        setAppUserEditStatus={setAppUserEditStatus}
        appUserEditStatus={appUserEditStatus}
        newnNotificationEmail={newnNotificationEmail}
        setNewNotificationEmail={setNewNotificationEmail}
        editFirstName={editFirstName}
        setEditFirstName={setEditFirstName}
        editLastName={editLastName}
        setEditLastName={setEditLastName}
        editMiddleName={editMiddleName}
        setEditMiddleName={setEditMiddleName}
        editEmailDigestToggle={editEmailDigestToggle}
        setEditEmailDigestToggle={setEditEmailDigestToggle}
        externalModelSelected={externalModelSelected}
        externalModelEnabled={externalModelEnabled}
        setExternalModelSelected={setExternalModelSelected}
        setExternalModelEnabled={setExternalModelEnabled}
        externalUserId={externalUserId}
        setExternalUserId={setExternalUserId}
        setEnabledBulkActionGroupApp={setEnabledBulkActionGroupApp}
        enabledBulkActionGroupApp={enabledBulkActionGroupApp}
        proxyLoginToggleGroupApp={proxyLoginToggleGroupApp}
        setProxyLoginToggleGroupApp={setProxyLoginToggleGroupApp}
        type={"groupAppUserEdit"}
      />
      <StatusChangeModalAppUsers
        modalState={modalStateStatus}
        setModalState={setModalStateStatus}
        status={status}
        AppUserID={AppUserID}
        appUserReload={appUserReload}
        setAppUserReload={setAppUserReload}
      />
      <SsoLoginChangeForUser
        modalState={modalState}
        setModalState={setModalState}
        status={status}
        AppUserID={AppUserID}
        appUserReload={appUserReload}
        setAppUserReload={setAppUserReload}
      />
      <CSVUploadModal
        uploadModal={uploadModal}
        setUploadModal={setUploadModal}
        groupDropDownOptions={groupDropDownOptions}
        setGroupDropDownOptions={setGroupDropDownOptions}
        appUserReload={appUserReload}
        setAppUserReload={setAppUserReload}
      />
    </Hux>
  );
};


export default GroupAppUsers;