import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Col,
  Row,
  Alert,
  Card,
  CardBody,
  Container, Spinner
} from "reactstrap"; // eslint-disable-line
import equipologo from "../../assets/img/logos/equipo-logo_low-res_with-tagline.png";
import axios from "axios";
import { Formik, Form } from 'formik';

const ProxyRequestActionPage = () => {
  const { param1, param2, id } = useParams();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  let url = `${API_ENDPOINT}/admin-api/request-process-proxy-login/${param1}/${param2}/${id}/`

  useEffect(() => {
      setLoading(true);
      if (id === "1") {
        axios.get(url)
        .then((response) => {
            setLoading(false);
            setContent(<div style={{ textAlign: "center", color: "green" }}>{response?.data?.message}</div>);

        }).catch((error) => {
            setLoading(false);
            setContent(<div style={{ textAlign: "center", color: "yellow" }}>{error?.response?.data?.message}</div>);
        })
           
      } else if (id === "99") {
        axios.get(url)
        .then((response) => {
          setLoading(false);
          setContent(<div style={{ textAlign: "center", color: "red" }}>{response?.data?.message}</div>);
        })
        .catch((error) => {
          setLoading(false);
          setContent(<div style={{ textAlign: "center", color: "yellow" }}>{error?.response?.data?.message}</div>);
        })
      } 
  }, [param1, param2, id]); // eslint-disable-line

  if (loading) {
    return (
      <Container className="vh-50 d-flex align-items-center justify-content-center">
        <div><Spinner/></div>
      </Container>
    );
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {() => (
        <Form>
          <Container className="vh-50">
            <Row className="m-auto">
              <div className="card-password">
                <Card className="background">
                  <CardBody>
                    <div className="body-width">
                      <Row>
                        <Col>
                          <div className="m-sm-4">
                            <Alert className="alert-success adjust">
                              <div className="text-center">
                                <img
                                  src={equipologo}
                                  alt="equipo logo"
                                  className="img-fluid"
                                  width="150"
                                  height="150"
                                />
                              </div>
                            </Alert>
                            <br />
                            <h4>{content}</h4>
                            <br />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>  );
};

export default ProxyRequestActionPage;
