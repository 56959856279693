import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Label,
  Row,
  Col,
  Card,
  CardHeader
} from "reactstrap";
import ToggleButton from "react-toggle-button";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Cookie from 'js-cookie';
import moment from "moment";
import ReactSelect from "react-select";

//import styles from "./ProviderDetails.module.css";

const ReferralCentralNotification = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { groupID, setGroupID, categoryTypePopulated } = props;
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [enableCentralMAilToggleButton, setEnableCentralMAilToggleButton] =
    useState(false);
  const [NLPbuttonClicked, setNLPbuttonClicked] = useState(false)
  const [alertPreferenceClinical, setalertPreferenceClinical] = useState(false)
  const [alertPreferenceTransport, setalertPreferenceTransport] = useState(false)
  const [showAlertPreference, setShowAlertPreference] = useState(false)
  const [showAlertPreferenceClinical, setShowAlertPreferenceClinical] = useState(false)
  const [showAlertPreferenceTransport, setShowAlertPreferenceTransport] = useState(false)
  const [centralMAil, setCentralMail] = useState("");
  // const regXForEmail = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"); //eslint-disable-line
  const regXForEmail = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"); //eslint-disable-line

  var emailArray = [];//eslint-disable-line
  var temp = [];

  // alert type
  const [alertTypeSelectedClinical, setAlertTypeSelectedClinical] = useState([])
  const [alertTypeSelectedTransport, setAlertTypeSelectedTransport] = useState([])
  const [alertOptionsClinical,setAlertOptionsClinical] = useState([])
  const [alertOptionsTransport,setAlertOptionsTransport] = useState([])

  // populated values alert preference
  const [alertTypeClinicalPopulated,setAlertTypeClinicalPopulated] = useState(null)
  const [alertTypeTransportPopulated,setAlertTypeTransportPopulated] = useState(null)

  const saveNotifications = () => {
    var date2 = new Date();
    const format1 = "YYYY-MM-DD HH:mm:ss";
    let dateTime1 = moment(date2).format(format1);
    // console.log(dateTime1)

    var temp = [];
    var final_email_array = [];
    if (typeof centralMAil == "object") {
      temp = centralMAil;
    } else {
      temp = centralMAil.split(",");
    }

    if (temp) {
      // eslint-disable-next-line array-callback-return
      temp.map((item) => {
        final_email_array.push(item.trim());
      });
    }

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/referral-central-notification/`,
        {
          group_id: groupID ? groupID.value : "",

          enable_central_notification: enableCentralMAilToggleButton
            ? true
            : false,
          central_mail: centralMAil ? final_email_array : "",
          updated_by: localStorage.getItem("adminID")
            ? localStorage.getItem("adminID")
            : "",
          updated_on: dateTime1,
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && centralMAil!== "") {
          //   toggle();
          //   setPatientDataReload(patientDataReload + 1)
          toastr.success("Success", "Central Notification changes saved");

          // setApiCallDone(true)
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };
  const changeOnEmail = (e) => {
    setCentralMail(e);
  };

  const onSaveClick = () => {
    const emailString = centralMAil?.toString().trim() || '';
    const emailArray = emailString.split(',');
    const validEmails = emailArray.filter((email) => regXForEmail.test(email.trim()));
    
    if (validEmails?.length === emailArray?.length) {
      temp.push(...validEmails);
      saveNotifications();
    } else {
      toastr.warning('Invalid Email', 'Please enter a valid email');
    }
  };
  
  const fetchReferralEmailById = (grpid) => {
    AxiosInter
      .get(`${API_ENDPOINT}/admin-api/referral-email-suggestion/${grpid}`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        // alert preference populating values
        // if alert preference clinical button is on
        if(response?.data?.data[0]?.enable_reminder_for_clinical){
          setAlertTypeClinicalPopulated(
            {
              alertClinicalButtonClicked: response?.data?.data[0]?.enable_reminder_for_clinical,
              typePopulated: response?.data?.data[0]?.clinical_enabled_reminder_items
            }
          )
        }
        // if alert preference transport button is on
        if(response?.data?.data[0]?.enable_reminder_for_transport){
          setAlertTypeTransportPopulated(
            {
              alertTransportButtonClicked: response?.data?.data[0]?.enable_reminder_for_transport,
              typePopulated: response?.data?.data[0]?.transport_enabled_reminder_items
            }
          )
        }

        setEnableCentralMAilToggleButton(
          response.data.data[0]["enable_central_notification"]
        );
        if (response.data.data.length > 0) {
          let notificationMail = response.data.data[0]["central_mail"];

          let notificationMailarr = JSON.parse(notificationMail);

          let etemp = [];
          // eslint-disable-next-line array-callback-return
          notificationMailarr.map((item) => {
            etemp.push(item);
          });
          // eslint-disable-next-line eqeqeq
          if (etemp.length != 0) {
            setCentralMail(etemp);
          }
        }
      })
      .catch((error) => {
        // toastr.error("Error", "Something went wrong in region listing !!");
      });
  };
  useEffect(() => {
    if (groupID !== "" && groupID !== null && groupID !== undefined) {
      fetchReferralEmailById(grpid);
    }
  }, [groupID]); // eslint-disable-line

  const functionForCentralNotificationButton = () => {
    setEnableCentralMAilToggleButton(!enableCentralMAilToggleButton);
  };

  useEffect(() => {
    setEnableCentralMAilToggleButton(false);

    setCentralMail("");
  }, []); // eslint-disable-line
  const status = enableCentralMAilToggleButton;
  const grpid = groupID ? groupID.value : "";
  // useEffect(()=>
  // {
  //   debugger
  //   var date2 = new Date();
  //   const format1 = "YYYY-MM-DD HH:mm:ss"
  //  let dateTime1 = moment(date2).format(format1);
  //   console.log(dateTime1)
  // },[])

  useEffect(()=>{
    // (groupID.value) + '' <--- converting into string
    AxiosInter
      .get(
        `${API_ENDPOINT}/admin-api/get-nlp-status/?group_id=${(groupID.value)+''}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if(response.data?.NLP_status.length !== 0){
          let status = response.data?.NLP_status[0][0]
          if(status === 1){
            setNLPbuttonClicked(true)
          }
          if(status === 0){
            setNLPbuttonClicked(false)
          }
        }
      })
      .catch((error) => {
        // toastr.error("Failed", "Something went wrong");
        setNLPbuttonClicked(false)
      });
   },[groupID])  //eslint-disable-line react-hooks/exhaustive-deps

  const onSaveClickNLP = () =>{
    // (groupID.value) + '' <--- converting into string
    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/update-nlp-status/`,
        {
          "group_id": (groupID.value) + '',
          "status": NLPbuttonClicked ? '1' : '0',
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        toastr.success("Success", "NLP status updated");
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  }

  // this is for alert preference
  useEffect(()=>{
    // clinical, transport not selected case hide alert preference screen
    if(categoryTypePopulated.length === 0){
      setShowAlertPreference(false)
    }
    if(categoryTypePopulated.length > 0){
      if(categoryTypePopulated[0]?.includes('1') || categoryTypePopulated[1]?.includes('1')){
        getAllAlertOptions(1)
        setShowAlertPreference(true)
        setShowAlertPreferenceClinical(true)
      }
      if(categoryTypePopulated[0]?.includes('2') || categoryTypePopulated[1]?.includes('2')){
        getAllAlertOptions(2)
        setShowAlertPreference(true)
        setShowAlertPreferenceTransport(true)
      }
    }

    // setting alert preference clinical button on/off based on populated value 
    setalertPreferenceClinical(alertTypeClinicalPopulated?.alertClinicalButtonClicked)
    // setting alert preference clinical button on/off based on populated value 
    setalertPreferenceTransport(alertTypeTransportPopulated?.alertTransportButtonClicked)

    // this is string we need to convert to array
    const typePopulatedStrClinical = alertTypeClinicalPopulated?.typePopulated ? JSON.parse(alertTypeClinicalPopulated?.typePopulated) : null
    // typePopulatedStrClinical = ["pending_stat_orders"] 
    typePopulatedStrClinical && typePopulatedStrClinical.map(eachItem=>{ // eslint-disable-line array-callback-return
      populateAlreadySelectedAlertType(1,eachItem)
    })

    
    const typePopulatedStrTransport = alertTypeTransportPopulated?.typePopulated ? JSON.parse(alertTypeTransportPopulated?.typePopulated) : null
    typePopulatedStrTransport && typePopulatedStrTransport.map(eachItem=>{ // eslint-disable-line array-callback-return
      populateAlreadySelectedAlertType(2,eachItem)
    })
    

  },[categoryTypePopulated,alertTypeClinicalPopulated, alertTypeTransportPopulated]) // eslint-disable-line react-hooks/exhaustive-deps

  const populateAlreadySelectedAlertType = async(id, reminder_name)=>{
    // if id =1 that for clinical
    // if id =2 that for transport
    await AxiosInter
      .get(
        `${API_ENDPOINT}/admin-api/fetch-referralReminder-async-options/?category_id=${id}&reminder_name=${reminder_name}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (id === 1) {
          setAlertTypeSelectedClinical(prevS => {
            // remove duplicate values
            const newData = response.data.data[0];
            const hasDuplicate = prevS.some(item => item.value === newData.value);
          
            if (!hasDuplicate) {
              return [...prevS, newData];
            }
          
            return prevS;
          });          
        }
        if (id === 2) {
          setAlertTypeSelectedTransport(prevS => {
            // remove duplicate values
            const newData = response.data.data[0];
            const hasDuplicate = prevS.some(item => item.value === newData.value);

            if (!hasDuplicate) {
              return [...prevS, newData];
            }
          
            return prevS;
          })
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  }

  const getAllAlertOptions = async(id)=>{
    // if id =1 that for clinical
    // if id =2 that for transport
    await AxiosInter
      .get(
        `${API_ENDPOINT}/admin-api/fetch-referralReminder-async-options/?category_id=${id}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
        )
      .then((response) => {
        if(id === 1){
          setAlertOptionsClinical(response.data.data)
        }
        if(id === 2){
          setAlertOptionsTransport(response.data.data)
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  }

  const onSaveClickAlertPreference = () =>{
    let alertTypeSelectedClinicalParams = null
    let alertTypeSelectedTransportParams = null
    let statusApiCall = false

    // if alert clinical button clicked
    // mandatory to select option
    if (alertPreferenceClinical && alertTypeSelectedClinical.length > 0) {
      alertTypeSelectedClinicalParams = alertTypeSelectedClinical?.flatMap(item => item.value)
      statusApiCall = true
    }
    if (alertPreferenceClinical && alertTypeSelectedClinical.length === 0) {
      alertTypeSelectedClinicalParams = null
      toastr.warning('Please select Clinical Alert type')
      statusApiCall = false
    }

    if (alertPreferenceTransport && alertTypeSelectedTransport.length > 0) {
      alertTypeSelectedTransportParams = alertTypeSelectedTransport?.flatMap(item => item.value)
      statusApiCall = true
    }
    if (alertPreferenceTransport && alertTypeSelectedTransport.length === 0) {
      alertTypeSelectedTransportParams = null
      toastr.warning('Please select Transport Alert type')
      statusApiCall = false
    }
    
    if (statusApiCall) {
      saveAlertPreference(alertTypeSelectedClinicalParams, alertTypeSelectedTransportParams)
    }

    // both buttons are off conditon and no options selected case
    // this case work if user want to off both alert preferences
    if (!alertPreferenceClinical && !alertPreferenceTransport && alertTypeSelectedClinical?.length === 0 && alertTypeSelectedTransport?.length === 0) {
      saveAlertPreference(alertTypeSelectedClinicalParams, alertTypeSelectedTransportParams)
    }
  }

  const saveAlertPreference = (alertTypeSelectedClinicalParams, alertTypeSelectedTransportParams) =>{
    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/enable-referral-reminder/`,
        {
          "group_id": (groupID.value) + '',
          "enable_reminder_for_clinical": alertPreferenceClinical,
          "clinical_enabled_reminder_items": alertTypeSelectedClinical ? alertTypeSelectedClinicalParams : null,
          "enable_reminder_for_transport": alertPreferenceTransport,
          "transport_enabled_reminder_items": alertTypeSelectedTransport ? alertTypeSelectedTransportParams : null
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        toastr.success("Success", "Successfully saved alert preference");
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  }

  return (
    <>
    <Card style={{ border: "1px solid rgba(1, 0, 0, 0.25)" }}>
      <div>
        {/* <card> */}
        <div>
        <CardHeader>
          <Row>
          <Col sm={12} className="text-center">
              <h5 style={{ fontWeight: 800 , margin: 0 }}>Notification Preference</h5>
            </Col>
          </Row>
          </CardHeader>
        </div>
        <hr
          style={{
            borderColor: "rgba(1, 0, 0, 0.25)",
            height: "3px",
            margin: "0",  
          }}
        />
        <br />
        <br />
        <br />

        <div>
          <Row>
            <Col sm={1}></Col>
            <Col sm={6}>
              <p> Enable Central Notification</p>
            </Col>
            <Col sm={2}>
              <ToggleButton
                inactiveLabel={"OFF"}
                activeLabel={"ON"}
                //  value={(smsToggleButton ===1)?true:false}
                onToggle={() => {
                  functionForCentralNotificationButton();
                }}
                value={enableCentralMAilToggleButton}
              />
            </Col>
          </Row>

          {status === true || status === 1 ? (
            <>
              <Row>
                <Col sm={1}></Col>
                <Col sm={2}>
                  <Label>Email</Label>
                </Col>
                {/* <Col md={9} sm={4}> */}
                <Col sm={8}>
                  {/* <div> */}

                  <Input
                    placeholder="Emails (demo1@xyz.com,demo2@xyz.com)"
                    // isMulti
                    value={centralMAil}
                    onChange={(e) => {
                      changeOnEmail(e.target.value);
                    }}
                    style={{ margin: "0" }}
                  />
                  <br></br>

                  {centralMAil === 0 ? (
                    <>
                      <div
                        style={{ float: "left", marginTop: "0px" }}
                        className="warning-label"
                      >
                        <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                        <Label className="warning-text">
                          Enter atleast 1 email
                        </Label>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {/* </div> */}
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </div>
        <br />
      </div>
      <Button
        // className="yes-button button-width"
        onClick={() => {
          //  saveNotifications();
          onSaveClick();
        }}
      >
        Save
      </Button>{" "}
      {/* </card> */}
    </Card>

    {/* NLP card */}
      <Card style={{ border: "1px solid rgba(1, 0, 0, 0.25)" }}>
        <div>
          <div>
            <CardHeader>
              <Row>
                <Col sm={12} className="text-center">
                  <h5 style={{ fontWeight: 800, margin: 0 }}>NLP</h5>
                </Col>
              </Row>
            </CardHeader>
          </div>
          <hr
            style={{
              borderColor: "rgba(1, 0, 0, 0.25)",
              height: "3px",
              margin: "0",
            }}
          />
          <br />
          <div>
            <Row>
              <Col sm={1}></Col>
              <Col sm={6}>
                <p> Enable NLP</p>
              </Col>
              <Col sm={2}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  onToggle={() => {
                    setNLPbuttonClicked(prevS=> !prevS)
                  }}
                  value={NLPbuttonClicked}
                />
              </Col>
            </Row>
          </div>
        </div>
        <Button
          onClick={() => {
            onSaveClickNLP();
          }}
        >
          Save
        </Button>{" "}
      </Card>
    {/* Alert preference card */}
      {
        showAlertPreference &&
        <Card style={{ border: "1px solid rgba(1, 0, 0, 0.25)" }}>
          <div>
            <div>
              <CardHeader>
                <Row>
                  <Col sm={12} className="text-center">
                    <h5 style={{ fontWeight: 800, margin: 0 }}>Alert Preference</h5>
                  </Col>
                </Row>
              </CardHeader>
            </div>
            <hr
              style={{
                borderColor: "rgba(1, 0, 0, 0.25)",
                height: "3px",
                margin: "0",
              }}
            />
            <br />
              { showAlertPreferenceClinical &&
                <>
                  <div>
                    <Row>
                      <Col sm={1}></Col>
                      <Col sm={6}>
                        <p> Enable Alerts for Clinical</p>
                      </Col>
                      <Col sm={2}>
                        <ToggleButton
                          inactiveLabel={"OFF"}
                          activeLabel={"ON"}
                          onToggle={() => {
                            setalertPreferenceClinical(prevS => !prevS)
                            setAlertTypeSelectedClinical([])
                          }}
                          value={alertPreferenceClinical}
                        />
                      </Col>
                    </Row>
                  </div>

                  {Boolean(alertPreferenceClinical) && <div>
                    <Row>
                      <Col sm={10} style={{ padding: '0px 0px 0px 44px' }}>
                        <p> Select Alert Type</p>
                      </Col>
                      <Col sm={1}></Col>
                      <Col sm={10} style={{ padding: '0px 0px 20px 44px' }}>
                        <ReactSelect
                          options={alertOptionsClinical}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          allowSelectAll={true}
                          onChange={(item) => {
                            setAlertTypeSelectedClinical(item)
                          }}
                          value={alertTypeSelectedClinical}
                          placeholder="Select Alert Type"
                          isSearchable
                          isClearable
                        />
                      </Col>
                    </Row>
                  </div>}
                </>
              }

            { showAlertPreferenceTransport &&
              <>
                  <div>
                    <Row>
                      <Col sm={1}></Col>
                      <Col sm={6}>
                        <p> Enable Alerts for Transport</p>
                      </Col>
                      <Col sm={2}>
                        <ToggleButton
                          inactiveLabel={"OFF"}
                          activeLabel={"ON"}
                          onToggle={() => {
                            setalertPreferenceTransport(prevS => !prevS)
                            setAlertTypeSelectedTransport([])
                          }}
                          value={alertPreferenceTransport}
                        />
                      </Col>
                    </Row>
                  </div>
                  {Boolean(alertPreferenceTransport) && <div>
                    <Row>
                      <Col sm={10} style={{ padding: '0px 0px 0px 44px' }}>
                        <p> Select Alert Type</p>
                      </Col>
                      <Col sm={1}></Col>
                      <Col sm={10} style={{ padding: '0px 0px 20px 44px' }}>
                        <ReactSelect
                          options={alertOptionsTransport}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          allowSelectAll={true}
                          onChange={(item) => {
                            setAlertTypeSelectedTransport(item)
                          }}
                          value={alertTypeSelectedTransport}
                          placeholder="Select Alert Type"
                          isSearchable
                          isClearable
                        />
                      </Col>
                    </Row>
                  </div>}
              </>
            }

          </div>
          <Button
            onClick={() => {
              onSaveClickAlertPreference();
            }}
          >
            Save
          </Button>{" "}
        </Card>
      }
    </>
  );
};
export default ReferralCentralNotification;
