import React, { useState, useEffect, useContext } from "react";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { Link } from "react-router-dom";
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Spinner,
  Col,
  Row,
  CardHeader,
  Card,
  Label,
} from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "../../hoc/Hux";
import ExportCSVTable from "./FacilityDataTable";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalState } from "../../contextAPI/Context";
import FacilityStatusChangeModal from "../../newComponents/utilities/FacilityStatusChangeModal";
import EditAllFacilityModal from "../../newComponents/utilities/EditAllFacilityModal";
import NewFacilityModal from "../../newComponents/utilities/NewFacilityModal";
import FacilityDepartmentModal from "../../newComponents/utilities/FacilityDepartmentModal";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import MomentConfig from "../../common/MomentConfig";
import moment from "moment";

const AllFacility = (props) => {
  // CONTEXT VARIABLES
  const {
    setFacilityReload,
    facilityReload,
    m_zipList,
    facilityListLoading, // eslint-disable-line
    timeZoneList,
    getTimeZoneList,
  } = useContext(GlobalState);

  // LOCAL VARIABLES

  const [facilityList, setFacilityList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [facilityID, setFacilityID] = useState("");
  const [status, setStatus] = useState("");
  const [rowData, setRowData] = useState("");
  const [editFacilityModal, setEditFacilityModal] = useState(false);
  const [newFacilityModal, setNewFacilityModal] = useState(false);
  const [facilityDepartmentModal, setFacilityDepartmentModal] = useState(false);
  const [editFacilityID, setEditFacilityID] = useState("");
  const [editFacilityName, setEditFacilityName] = useState("");
  const [editAddress1, setEditAddress1] = useState("");
  const [editAddress2, setEditAddress2] = useState("");
  const [editReferalFax, setEditReferalFax] = useState("");
  const [editNPI, setEditNPI] = useState("");
  const [editZipCode, setEditZipCode] = useState("");
  const [editZipID, setEditZipID] = useState("");
  const [editType, setEditType] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editTimeZone, setEditTimeZone] = useState("");
  const [editLatitude, setEditLatitude] = useState("");
  const [editLongitude, setEditLongitude] = useState("");
  const [editPrimaryPhone, setEditPrimaryPhone] = useState("");
  const [editContactPerson, setEditContactPerson] = useState("");
  const [editDepartment, setEditDepartment] = useState("");
  const [editFloor, setEditFloor] = useState("");
  const [editWing, setEditWing] = useState("");
  const [editRoom, setEditRoom] = useState("");
  const [editCenter, setEditCenter] = useState("");
  const [externalFacilityID, setexternalFacilityID] = useState("");
  const [editExternalFacilityID, setEditExternalFacilityID] = useState("");

  const fileExportName = "Facility";
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  // PAGINATION VARIABLES
  const [facilityOffset, setFacilityOffset] = useState(0);
  const [facilityPage, setFacilityPage] = useState(1);
  const [facilityTotalSize, setFacilityTotalSize] = useState(0);
  const [facilitySizePerPage, setFacilitySizePerPage] = useState(50);
  const [selectedFacilityName, setSelectedFacilityName] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [data, setData] = useState([]); // eslint-disable-line
  const [spinner, setSpinner] = useState(false);
  const [isSearch, setIsSearch] = useState(false); // eslint-disable-line
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [search, setSearch] = useState(false);
  const [timeZoneDropDownOptions, setTimeZoneDropDownOptions] = useState([]); // eslint-disable-line
  const [assignRoomModal, setAssignRoomModal] = useState(false);
  const [departmentRoomDetails, setDepartmentRoomDetails] = useState([]);
  const [accessType, setAccessType] = useState({});
  const [isPrimary, setIsPrimary] = useState({});
  const [deleteModal, setDeleteModal] = useState(false); // eslint-disable-line
  const [reload, setReload] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [inputValue, setInputValue] = useState(""); // eslint-disable-line
  const [id, setID] = useState(""); // eslint-disable-line
  const [editFacilityDepartmentModal, setEditFacilityDepartmentModal] =useState(false);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const statusOptions = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ];
  const typeDropDown = [
    { value: "1", label: "Hospital" },
    { value: "2", label: "Clinic" },
    { value: "3", label: "SNF" },
  ];
  // handle input change event
  const handleInputChange = (value) => {
    setInputValue(value);
  };

  // handle selection
  const handleChange = (value) => {
    setSelectedFacilityName(value);
  };


  const loadOptions = async (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-names-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then((response)=> response.data.data)
        .catch ((error) =>{
        throw error;
        });
    }
  };
  
  const fetchFacility = () => {
    setLoading(true);

    const params = {
      facility_name: selectedFacilityName ? selectedFacilityName.label : "",
      facility_type: selectedType ? selectedType.label : "",
      facility_timezone: selectedTimeZone ? selectedTimeZone.label : "",
      facility_status: selectedStatus ? selectedStatus.value : "",
      search_keyword: searchKeyword ? searchKeyword : "",
      is_pagination: "1",
      record_limit: facilitySizePerPage ? facilitySizePerPage.toString() : "25",
      offset: facilityOffset ? facilityOffset.toString() : "0",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: sortColumn ? sortColumn : "",
    };

    AxiosInter.post(`${API_ENDPOINT}/admin-api/get-facility-details/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((res) => {
        if (res.data.data) {
          setFacilityList([]);
          setFacilityTotalSize(res.data.record_count);
          setFacilityList(res.data.data);
          localStorage.setItem("facilityOffset", facilityOffset);
          localStorage.removeItem("subscriptionFilter");
          localStorage.removeItem("groupNameFilter");
          localStorage.removeItem("statusFilter");
          localStorage.removeItem("adminNameFilter");
          localStorage.removeItem("zipCodeFilter");
          localStorage.removeItem("timeZoneFilter");
          setLoading(false);
        } else {
          setFacilityList([]);
          setLoading(false);
        }
      })
      .catch(function (error) {
        // setFacilityList([]);
        // console.log(error,"error");
        // setLoading(false);
      });
  };
  const exportTableData = () => {
    const params = {
      file_name: fileName ? fileName : "",
      facility_name: selectedFacilityName ? selectedFacilityName.label : "",
      facility_type: selectedType ? selectedType.label : "",
      facility_timezone: selectedTimeZone ? selectedTimeZone.label : "",
      facility_status: selectedStatus ? selectedStatus.value : "",
      search_keyword: searchKeyword ? searchKeyword : "",
      is_pagination: "1",
      record_limit: "",
      offset: facilityOffset ? facilityOffset.toString() : "0",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: sortColumn ? sortColumn : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/export-facility-table-data/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const getAssignRoomDetails = () => {
    const facility_id = localStorage.getItem("facilityId");
    const params = {
      facility_id: facility_id ? Number(facility_id) : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-facility-assign-room/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        setDepartmentRoomDetails(response.data.data);
        setFacilityDepartmentModal(true);
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong");
        setSpinner(false);
      });
  };

  const getDepartmentDropdownList = () => {
    setSpinner(true);

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/get-departments-list/`,
        {
          facility_id: facilityID ? facilityID : "",
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setDepartmentOptions(response.data.data);
      })
      .catch(function (error) {
        setSpinner(false);
      });
  };

  const getRoomDropdownList = () => {
    setSpinner(true);

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/get-rooms-list/`,
        {
          facility_id: facilityID ? facilityID : "",
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setRoomOptions(response.data.data);
      })
      .catch(function (error) {
        setSpinner(false);
      });
  };

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setFacilityID(row.id);
          setStatus(cell);
          setModalState(true);
        }}
      >
        {status}
      </span>
    );
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };

  const statusFormatterCSV = (cell) => {
    return cell ? "Active" : "Inactive";
  };

  const actionFormatter = (cell, row) => {
    return (
      <>
        <div style={{ gap: "20px", display: "flex", justifyContent: "center" }}>
          <FontAwesomeIcon
            icon={faEye}
            size={"1x"}
            fixedWidth
            className="align-middle"
            title={row.department ? "View Department" : "No Department Assigned"}
            style={
              row.department && row.room
                ? { visibility: "visible", cursor: "pointer" , color:"#2785ca"}
                : {
                    
                    visibility: "hidden", cursor: "pointer" , color:"#626365",
                    title: "No departments or rooms",
                    
                  }
            }
            onClick={() => {
              localStorage.setItem("facilityId", row.id); // need to store facilityId value in localStorage to make it available for modal reloads on add and edit for room assign
              setFacilityID(row.id);
              setFacilityDepartmentModal(!facilityDepartmentModal);
              setRowData(row);
            }}
          />

          <FontAwesomeIcon
            icon={faEdit}
            size={"1x"}
            color={"#3f86fa"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setRowData(row);
              setEditFacilityModal(true);
              setEditFacilityID(row.id);
              setEditFacilityName(row.name);
              setEditAddress1(row.address1);
              setEditAddress2(row.address2);
              setEditReferalFax(row.referralFax);
              setEditNPI(row.npi);
              setEditZipID(row.zipID);
              setEditType(row.type);
              setEditDescription(row.description1);
              setEditTimeZone(row.workTime);
              setEditLatitude(row.latitude);
              setEditLongitude(row.longitude);
              setEditPrimaryPhone(row.primaryPhone);
              setEditContactPerson(row.contactPerson);
              setEditDepartment(row.department);
              setEditFloor(row.floor);
              setEditWing(row.wing);
              setEditRoom(row.room);
              setAccessType(row.facilityType);
              setIsPrimary(row.isPrimary);
              setEditCenter(row.centerID);
              setEditExternalFacilityID(row.externalFacilityID)

            }}
            title="Edit Facility"
          />
          {/* <FontAwesomeIcon
            icon={faTrash}
            size={'1x'}
            color={'#E12C2C'}
            style={{ 'cursor': 'pointer' }}
            onClick={() => {
              setFacilityID(row.id)
              setDeleteModal(true)

            }}

            title="Delete"

          /> */}
        </div>
      </>
    );
  };

  const clearValues = () => {
    setSelectedFacilityName("");
    setSelectedType("");
    setSelectedTimeZone("");
    setSelectedStatus("");
    setData("");
    setFacilityReload(facilityReload + 1);
  };

  const tableColumns = [
    {
      dataField: "name",
      text: "Facility",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "GroupName",
      text: "Group Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "subscriptionPlanName",
      text: "Subscription",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: "address1",
      text: "Address",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "workTime",
      text: "Time Zone",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: "externalFacilityID",
      text: "External Facility Id", 
      formatter: basicValidation,
    },
    {
      dataField: "status1",
      text: "Status",
      formatter: statusFormatter,
      align: "center",
      csvFormatter: statusFormatterCSV,
    },
    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
    },
  ];

  let loader = null;

  if (loading) {
    loader = <Spinner />;
  }

  const handleTableChangeForFacility = (type, newState) => {
    setFacilityPage(newState.page);
    setFacilitySizePerPage(newState.sizePerPage);
    setFacilityOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("");
      setSortOrder("");
    }
    else {
      setSortColumn(newState.sortField);
      // Toggle the sortOrder between "desc" and "asc"
      const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
      setSortOrder(newSortOrder);
      setFacilityReload(facilityReload + 1);
    }
  };
  const searchButtonValidation = () => {
    if (
      selectedFacilityName ||
      selectedType ||
      selectedTimeZone ||
      selectedStatus ||
      selectedTimeZone
    ) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };
  // DEFAULT API CALL
  useEffect(() => {
    if (facilityReload) {
      fetchFacility();
      setFacilityReload(0);
    }
  }, [facilityReload]); // eslint-disable-line

  // PAGINATION API CALL
  useEffect(() => {
    let previousOffset = localStorage.getItem("facilityOffset");
    if (facilityOffset !== previousOffset || facilitySizePerPage) {
      // fetchFacility();
      setFacilityReload(facilityReload + 1);

      // fetchTimeZone();
    }
  }, [facilityOffset, facilitySizePerPage]); // eslint-disable-line

  useEffect(() => {
    if (isGlobalSearch) {
      setFacilityOffset(0);
      setFacilityTotalSize(0);
      fetchFacility();
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length >= 4) {
      setIsGlobalSearch(true);
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (searchKeyword.length === 0) {
      setFacilityReload(facilityReload + 1);
    }
  }, [searchKeyword]); // eslint-disable-line

  useEffect(() => {
    searchButtonValidation();
  }, [selectedFacilityName, selectedType, selectedTimeZone, selectedStatus]); // eslint-disable-line

  useEffect(() => {
    if (facilityID && facilityDepartmentModal) {
      getAssignRoomDetails();
      getDepartmentDropdownList();
      getRoomDropdownList();
    }
  }, [facilityID, facilityDepartmentModal]); // eslint-disable-line

  useEffect(() => {
    if (timeZoneList.length <= 0) {
      getTimeZoneList();
    }
  }, [timeZoneList]); // eslint-disable-line

  // useEffect(() => {
  //   if(id && editFacilityDepartmentModal) {
  //     editAssignRoomDetails();
  //   }
  // }, [id,editFacilityDepartmentModal]) // eslint-disable-line
  // useEffect(() => {
  //   if(departmentRoomDetails) {
  //   setFacilityDepartmentModal(true);
  //   }
  // }, [departmentRoomDetails]) // eslint-disable-line

  const clearSearchValues = () => {
    setSearchKeyword("");
    setReload(reload + 1);
  };
  const minLength = 3;

const customNoOptionsMessage = ({ inputValue }) => {
  
  if (inputValue.length < minLength) {
    return "Please enter at least 4 letters to search";
  } else {
    return "No option";
  }
};
  return (
    <Hux>
      {loader}
      <Container fluid>
        <Header>
          <HeaderTitle>
            Facility
            <Button
              className="Add-btn pull-right"
              onClick={() => {
                setNewFacilityModal(true);
              }}
            >
              Add New Facility
            </Button>
          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Facility</BreadcrumbItem>
          </Breadcrumb>
        </Header>

        <Card>
          {!facilityListLoading ? (
            <CardHeader>
              <Row>
                <Col sm="4" md="2" lg="2.5">
                  <Label>Facility Name</Label>
                  <AsyncSelect
                    cacheOptions
                    name="Facility"
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    noOptionsMessage={customNoOptionsMessage}
                    loadOptions={loadOptions}
                    placeholder="Enter facility name"
                    isSearchable
                    isClearable
                    value={selectedFacilityName}
                    onInputChange={handleInputChange}
                    onChange={handleChange}
                  />
                </Col>

                <Col sm="2" md="2" lg="2">
                  <Label>Type</Label>
                  <Select
                    name="Type"
                    options={typeDropDown}
                    placeholder="Select facility type"
                    isClearable
                    value={selectedType}
                    onChange={(e) => {
                      setSelectedType(e);
                    }}
                  />
                </Col>

                <Col sm="4" md="2" lg="2.5">
                  <Label>Time Zone</Label>
                  <Select
                    name="TimeZone"
                    options={timeZoneList}
                    placeholder="Choose TimeZone"
                    isClearable
                    value={selectedTimeZone}
                    onChange={(e) => {
                      setSelectedTimeZone(e);
                    }}
                  />
                </Col>
                <Col sm="2" md="2" lg="2">
                  <Label>Status</Label>
                  <Select
                    name="Status"
                    options={statusOptions}
                    placeholder="Choose status"
                    isClearable
                    value={selectedStatus}
                    onChange={(e) => {
                      setSelectedStatus(e);
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    className="patient-go-buttons"
                    onClick={() => {
                      setSearchKeyword("");
                      setData("");
                      facilityOffset === 0
                        ? fetchFacility()
                        : setFacilityOffset(0);
                      setIsSearch(true);
                      setFacilityPage(1);
                    }}
                    disabled={spinner || !search ? true : false}
                    title={
                      spinner || !search
                        ? "Choose at least one item to search "
                        : ""
                    }
                  >
                    Go
                  </Button>
                  <Button
                    className="patient-reset-buttons"
                    onClick={() => {
                      clearValues();
                    }}
                    disabled={spinner || !search ? true : false}
                    title={spinner || !search ? "Nothing To Clear" : ""}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
              <div className="separator" />
            </CardHeader>
          ) : (
            <Spinner />
          )}

          {m_zipList ? (
            <ExportCSVTable
              data={facilityList}
              columns={tableColumns}
              handleTableChangeForFacility={(type, newState) => {
                handleTableChangeForFacility(type, newState);
              }}
              facilityPage={facilityPage}
              facilitySizePerPage={facilitySizePerPage}
              totalSize={facilityTotalSize ? facilityTotalSize : 0}
              setFacilityPage={setFacilityPage}
              setFacilityOffset={setFacilityOffset}
              setFacilityTotalSize={setFacilityTotalSize}
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              facilityReload={facilityReload}
              setFacilityReload={setFacilityReload}
              clearSearchValues={clearSearchValues}
              exportTableData={exportTableData}
              loading={loading}
            />
          ) : (
            <Spinner />
          )}
        </Card>
        <EditAllFacilityModal
          setFacilityReload={setFacilityReload}
          facilityReload={facilityReload}
          editFacilityID={editFacilityID}
          setEditFacilityID={setEditFacilityID}
          editFacilityModal={editFacilityModal}
          setEditFacilityModal={setEditFacilityModal}
          rowData={rowData}
          editFacilityName={editFacilityName}
          setEditFacilityName={setEditFacilityName}
          editAddress1={editAddress1}
          setEditAddress1={setEditAddress1}
          editAddress2={editAddress2}
          setEditAddress2={setEditAddress2}
          editReferalFax={editReferalFax}
          setEditReferalFax={setEditReferalFax}
          editNPI={editNPI}
          setEditNPI={setEditNPI}
          editZipCode={editZipCode}
          setEditZipCode={setEditZipCode}
          editZipID={editZipID}
          editType={editType}
          setEditType={setEditType}
          editDescription={editDescription}
          setEditDescription={setEditDescription}
          editTimeZone={editTimeZone}
          setEditTimeZone={setEditTimeZone}
          editLatitude={editLatitude}
          setEditLatitude={setEditLatitude}
          editLongitude={editLongitude}
          setEditLongitude={setEditLongitude}
          editPrimaryPhone={editPrimaryPhone}
          setEditPrimaryPhone={setEditPrimaryPhone}
          editContactPerson={editContactPerson}
          setEditContactPerson={setEditContactPerson}
          editDepartment={editDepartment}
          setEditDepartment={setEditDepartment}
          editFloor={editFloor}
          setEditFloor={setEditFloor}
          editWing={editWing}
          setEditWing={setEditWing}
          editRoom={editRoom}
          setEditRoom={setEditRoom}
          timeZoneList={timeZoneList}
          zipCodeList={m_zipList}
          accessType={accessType}
          setAccessType={setAccessType}
          isPrimary={isPrimary}
          setIsPrimary={setIsPrimary}
          editCenter={editCenter}
          setEditCenter={setEditCenter}
          editExternalFacilityID={editExternalFacilityID}
          setEditExternalFacilityID={setEditExternalFacilityID}
        />

        <NewFacilityModal
          newFacilityModal={newFacilityModal}
          setNewFacilityModal={setNewFacilityModal}
          timeZoneList={timeZoneList}
          externalFacilityID={externalFacilityID}
          setexternalFacilityID={setexternalFacilityID}
        />

        <FacilityStatusChangeModal
          modalState={modalState}
          setModalState={setModalState}
          setStatus={setStatus}
          status={status}
          facilityID={facilityID}
          facilityReload={facilityReload}
          setFacilityReload={setFacilityReload}
        />
        <FacilityDepartmentModal
          facilityDepartmentModal={facilityDepartmentModal}
          setFacilityDepartmentModal={setFacilityDepartmentModal}
          assignRoomModal={assignRoomModal}
          setAssignRoomModal={setAssignRoomModal}
          setRowData={setRowData}
          facilityID={facilityID}
          departmentRoomDetails={departmentRoomDetails}
          setDepartmentRoomDetails={setDepartmentRoomDetails}
          setFacilityID={setFacilityID}
          editRoom={editRoom}
          editDepartment={editDepartment}
          setDepartmentOptions={setDepartmentOptions}
          departmentOptions={departmentOptions}
          roomOptions={roomOptions}
          setEditFacilityDepartmentModal={setEditFacilityDepartmentModal}
          editFacilityDepartmentModal={editFacilityDepartmentModal}
          getAssignRoomDetails={getAssignRoomDetails}
        />
        {/* <DeleteConfirmationModal
        deleteModal = {deleteModal}
        setDeleteModal = {setDeleteModal}
        deleteFunction = {deleteFacilityDetails}


        /> */}
        {/* <FacilityDepartmentAssignRoomModal
        assignRoomModal={assignRoomModal}
        setAssignRoomModal={setAssignRoomModal}
        setEditFacilityDepartmentModal={setEditFacilityDepartmentModal}
        setID={setID}
        getDepartmentDropdownList={getDepartmentDropdownList}

        />
        <EditFacilityDepartmentAssignRoomModal
        // editAssignRoomDetails={editAssignRoomDetails}
        editFacilityDepartmentModal={editFacilityDepartmentModal}
        setEditFacilityDepartmentModal={setEditFacilityDepartmentModal}
        setID={setID}
        editAssignRoomDetails={editAssignRoomDetails}
        getRoomDropdownList={getRoomDropdownList}
        // departmentDropdownList={departmentDropdownList}
        // roomDropdownList={roomDropdownList}
        /> */}
      </Container>
    </Hux>
  );
};

export default AllFacility;
