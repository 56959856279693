import React, { useContext, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import equipologo from "../assets/img/logos/equipo_logo_060117_white.png";
import equiposmalllogo from "../assets/img/logos/NVC_Equipo_LOGO.png";
import { GlobalState } from "../contextAPI/Context";
import "./Layout.css";
import ReactTooltip from "react-tooltip";
import {
  faTachometerAlt,
  faUsers,
  faUserCircle,
  faCode,
  faChalkboardTeacher,
  faHandshake,
  faFileAlt,
  faHospital,
  faHospitalUser,
  faKey,
  faCog,
  faStickyNote,
  faNotesMedical,
  faFileMedicalAlt,
  faList,
  faFax,
  faServer,
  faPhoneAlt,
  faMoneyBillWave,
  faWallet,
  faTags,
  faBook,
  faStethoscope,
  faSyringe,
  faUserClock,
  faMapMarker,
  faClipboardList,
  faUserPlus,
  faRegistered,
  faFolderOpen,
  faStreetView,faArchive, faExchangeAlt, faMagnifyingGlassChart, faCircleH, faShieldHalved, faReceipt
} from "@fortawesome/free-solid-svg-icons";

import { faSellsy, faServicestack, faUssunnah,faBattleNet,faWpforms } from "@fortawesome/free-brands-svg-icons";

const superAdminCheck = localStorage.getItem("superuser_check")
// console.log('superAdmin',superAdminCheck )
const microServiceSidebar = localStorage.getItem("microServiceSidebar")
const Is_SANITAS = process.env.REACT_APP_SANITAS_ENVIRONMENT;
const Is_EQUIPO = process.env.REACT_APP_EQIPO;

// let microServiceSidebar =0
console.log('microServiceSidebar',microServiceSidebar)
const externalApi = [
 
  
  {
    name: "Services",
    path: "/authservices",
    icon: faServicestack,
  },
  {
    name: "Groups",
    path: "/microservicegroups",
    icon: faBattleNet,
  },
  {
    name: "Users",
    path: "/microserviceusers",
    icon: faStreetView,
  },].filter(Boolean);
const category = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: faTachometerAlt,
  },
  {
    name: "Patients",
    path: "/patients",
    icon: faHospitalUser,
  },
  {
    name: "Subscription Configuration",
    path: "/subscriptionConfiguration",
    icon: faShieldHalved
  },
  {
    name: "Referral",
    path: "/referral-import",
    icon: faFileAlt,
  },
  {
    name: "Groups",
    path: "/groups",
    icon: faHospital,
  },
  {
    name: "Facility",
    path: "/facility",
    icon: faHandshake,
  },
  // {
  //   name: "RPM",
  //   path: "/rpm",
  //   icon: faSimplybuilt,
  // },
  {
    name: "App Users",
    path: "/appusers",
    icon: faUsers,
  },
  {
    name: "Providers",
    path: "/ProviderList",
    icon: faUserPlus,
  },
  /* {
    name: "Order Against Rule",
    path: "/order-against-rule",
    icon: faAmericanSignLanguageInterpreting,
  }, */
  {
    name: "Provider Speciality Ranking",
    path: "/provider-speciality-ranking",
    icon: faUssunnah,
  },
  {
    name: "HL7 Clinical Import Log",
    path: "/hl7",
    icon: faCircleH
  },
  {
    name: "Analytics",
    path: "/analytics",
    icon: faMagnifyingGlassChart
  },
  {
    name: "File Exchange", /*File exchange button  started*/
    path: "/FormExchange",
    icon: faExchangeAlt,
  },
  {
    name: "EMI Approval",
    path: "/emiapproval",
    icon: faWallet
  },
  (Is_SANITAS ==="true") ? null: {
    name: "PandaDoc",
    path: "/doc-signature",
    icon: faStickyNote,
  },
  {
    name: "Permission Manager",
    icon: faCog,
    path: "/permission-manager",
  },
  {
    name: "Autoprocess Exception Rule",
    icon: faClipboardList,
    path: "/auto-process-exception-rule",
  },
  {
    name: "Autoprocess Rule",
    icon: faArchive,
    path: "/auto-process-rule",
  },
  {
    name: "Queues",
    path: "/queues",
    icon: faSellsy,
  },
  {
    name: "Service Forms",
    path: "/serviceforms",
    icon: faWpforms,
  },
  {
    name: "Fax Log",
    path: "/fax-log",
    icon: faFax,
  },
  (superAdminCheck === "true") ? {
    name: "Admin Users",
    path: "/users",
    icon: faUserCircle,
  } : null,
  {
    name: "ICD",
    path: "/icd",
    icon: faNotesMedical,
  },
  {
    name: "CPT",
    path: "/cpt",
    icon: faFileMedicalAlt,
  },
  {
    name: "Referral Order Autoprocess",
    path: "/ReferralByTag",
    icon: faRegistered,
  },
  {
    name: "Programs",
    path: "/programs",
    icon: faFolderOpen,
  },
  {
    name: "SQL Query",
    path: "/sqlquery",
    icon: faCode,
  },
  // {
  //   name: "Health Check",
  //   path: "/healthcheck",
  //   icon: faReceipt,
  // },
  {
    name: "NPI",
    path: "/npi",
    icon: faChalkboardTeacher,
  },
  {
    name: "Subscription Plans",
    path: "/subscription-plan-details",
    icon: faList,
  },
 (Is_SANITAS ==="true") ? null: {
    name: "Appointment Tags",
    path: "/appointment-tag",
    icon: faTags,
  } ,
  {
    name: "DRG",
    icon: faStethoscope,
    path: "/drg",
  },
  // {
  //   name: "AWS S3",
  //   path: "/aws-s3",
  //   icon: faDatabase,
  // },
  {
    name: "ETL",
    icon: faServer,
    path: "/etl",
  },
  {
    name: "Packages",
    icon: faWallet,
    path: "/packages",
  },

  {
    name: "Insurance",
    path: "/insurance",
    icon: faMoneyBillWave,
  },

  {
    name: "Immunization",
    path: "/immunizations",
    icon: faSyringe,
  },
  {
    name: "InApp Call Log",
    path: "/in-app-call-log",
    icon: faPhoneAlt,
  },
  {
    name: "Encryption/Decryption",
    path: "/encryption",
    icon: faKey,
  },
  {
    name: "Access Log",
    path: "/userlog/",
    icon: faUserClock,
  },
  // {
  //   name : "Facility Locations",
  //   path :"/facilitymap/",
  //   icon :  faMapMarker,
  // },
  {
    name: "Locations",
    path: "/LocationMarker/",
    icon: faMapMarker,
  },
  (Is_EQUIPO ==="false") ?
  {
    name: "Setup Sheet",
    path: "/SetupSheet/",
    icon: faBook,
  } : null,
  // {
  //   name: "Cache",
  //   path: "/cache",
  //   icon: faMagic,
  // },
 ].filter(Boolean);
const SidebarItem = ({ name, icon, to }) => {
  return (
    <>
      <ReactTooltip className="card-tooltip" id="side-bar" />
      <li className="sidebar-item" data-tip={name} data-for="side-bar">
        <NavLink
          exact
          to={to}
          className="sidebar-link"
          activeStyle={{ color: "black" }}
          activeClassName="side-bar-active-link"
        >
          {icon ? (
            <React.Fragment>
              <FontAwesomeIcon
                icon={icon}
                fixedWidth
                className="align-middle mr-2"
              />{" "}
              <span className="align-middle side-bar-text"> {name}</span>
            </React.Fragment>
          ) : (
            <div className="side-bar-text">{name}</div>
          )}{" "}
        </NavLink>
      </li>
    </>
  );
};

const Sidebar = (props) => {
  // GLOBAL CONTEXT VARIABLES
  const { hideMenu, setHideMenu } = useContext(GlobalState);
  var string = ""; // eslint-disable-line
  // SIDEBAR AUTO HIDE LOGIC
  let location = useLocation();
  const handleSidebarToggle = () => {

    const titlePaths = new Map([
      ["/dashboard", "Equipo Admin | Dashboard"],
      ["/patients", "Equipo Admin | Patients"],
      ["/users", "Equipo Admin | Admin Users"],
      ["/sqlquery", "Equipo Admin | SQL"],
      ["/groups", "Equipo Admin | Groups"],
      ["/icd", "Equipo Admin | ICD"],
      ["/cpt", "Equipo Admin | CPT"],
      ["/doc-signature", "Equipo Admin | PandaDoc"],
      ["/etl", "Equipo Admin | ETL"],
      ["/subscription-plan-details", "Equipo Admin | Subscription Plans"],
      ["/appointment-tags", "Equipo Admin | Appointment Tags"],
      ["/referral-import", "Equipo Admin | Referral"],
      ["/facility", "Equipo Admin | Facility"],
      ["/rpm", "Equipo Admin | RPM"],
      ["/appusers", "Equipo Admin | App Users"],
      ["/ProviderList", "Equipo Admin | Providers"],
      ["/queues", "Equipo Admin | Queues"],
      ["/permission-manager", "Equipo Admin | Permission Manager"],
      ["/fax-log", "Equipo Admin | Fax Log"],
      ["/npi", "Equipo Admin | NPI"],
      ["/insurance", "Equipo Admin | Insurance"],
      ["/ReferralByTag", "Equipo Admin | Referral Order Autoprocess"],
      ["/programs", "Equipo Admin | Programs"],
      ["/in-app-call-log", "Equipo Admin | In App Call"],
      ["/encryption", "Equipo Admin | Encryption"],
      ["/appointment-tag", "Equipo Admin | Appointment Tag"],
      ["/packages", "Equipo Admin | Packages"],
      ["/drg", "Equipo Admin | DRG"],
      ["/userlog/", "Equipo Admin | Access Log"],
      ["/LocationMarker/", "Equipo Admin | Locations"],
      ["/provider-speciality-ranking", "Equipo Admin | Provider Speciality Ranking"],
      ["/immunizations", "Equipo Admin | Immunizations"],
      ["/auto-process-exception-rule", "Equipo Admin | Auto Process Exception Rule"],
      ["/auto-process-rule", "Equipo Admin | Auto Process Rule"],
      ["/microservicegroups", "Groups"],
      ["/serviceforms", "Equipo Admin | Service Forms"],
      ["/FormExchange", "Equipo Admin | File Exchange"],
      ["/SetupSheet", "Equipo Admin | Setup Sheet"],
      ["/emiapproval", "Equipo Admin | EMI Approval"],
      ["/analytics", "Equipo Admin | Analytics"],
      ["/hl7", "Equipo Admin | HL7"],
      ["/subscriptionConfiguration", "Equipo Admin | Subscription Configuration"],
      ["/healthcheck", "Equipo Admin | Health Check"],

    ]);
  
    const title = titlePaths.get(location.pathname);
    setHideMenu(true);
    document.querySelector("title").textContent = title;
  };

  useEffect(() => {
    handleSidebarToggle();
  }, [location]); // eslint-disable-line
  useEffect(() => {
    setHideMenu(true);
  }, []); // eslint-disable-line

  // useEffect(()=>{
  //   category.sort(function(a, b){
  //     var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase()
  //     if (nameA < nameB) //sort string ascending
  //         return -1
  //     if (nameA > nameB)
  //         return 1
  //     return 0 //default return value (no sorting)
  // })

  // },[category]) // eslint-disable-line

  return (
    <>
      <nav className={classNames("sidebar", hideMenu && "toggled")}>
        <div className="sidebar-content">
          <span className={classNames("sidebar-brand")}>
            {hideMenu && window.innerWidth > 1024 ? (
              <img src={equiposmalllogo} className="img-fluid" alt="logo" />
            ) : (
              <img
                src={equipologo}
                alt="Admin App Logo"
                className="img-fluid"
                width="200"
              />
            )}
          </span>
          <br />
          <ul className="sidebar-nav">
  { // eslint-disable-next-line eqeqeq
  microServiceSidebar == 0 || microServiceSidebar ===null || microServiceSidebar === undefined
    ? category.map((category, index) => (
        <SidebarItem
          key={index.toString()}
          name={category.name}
          to={category.path}
          icon={category.icon}
        />
      ))
    : externalApi.map((api, index) => (
        <SidebarItem
          key={index.toString()}
          name={api.name}
          to={api.path}
          icon={api.icon}
        />
      ))
  }
</ul>

          {/* POWERED BY EQUIPO */}
          {/*  <div className="equipo-logo">
            {hideMenu ? "" : <p className="powered-by">powered by</p>}
            <a href="https://equipo.io/" target="_blank" rel="noopener noreferrer">
              <img
                src={equipoLogo}
                className="img-fluid mb-2"
                alt="equipo logo"
              />
            </a>
          </div> */}
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
