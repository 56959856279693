import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  Spinner,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit"; // eslint-disable-line
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import MomentConfig from "../../common/MomentConfig";

const ExportCSVTable = (props) => {
  const [blankStateStatus, setBlankStateStatus] = useState(false); // eslint-disable-line
  const {
    totalSize,
    handleTableChangeForFacility,
    searchKeyword,
    setSearchKeyword,
    clearSearchValues,
    exportTableData,
    facilityReload, // eslint-disable-line
    setFacilityReload, // eslint-disable-line
    data,
    facilityPage,
    setFacilityPage, // eslint-disable-line
    facilitySizePerPage,
    loading,
  } = props;
  const [numberOfRecords, setNumberOfRecords] = useState(0);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      exportTableData();
    };
    return (
      <div>
        <button className="btn btn-export mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No records available</h3>
      </CardBody>
    </Card>
  );

  useEffect(() => {
    setNumberOfRecords(totalSize);
  }, [totalSize]);

  useEffect(() => {
    if (props.data.length === 0) {
      setBlankStateStatus(true);
    } else {
      setBlankStateStatus(false);
    }
  }, [props.data.length]);

  return (
    <div className="facility_search_component">
      <Card>
        <ToolkitProvider
          keyField="id3"
          data={props.data}
          columns={props.columns}
          search
          exportCSV={{
            fileName: `Equipo-Facility-Export-${moment().format(
              MomentConfig.MOMENT_FORMAT_DATEANDTIME
            )}.csv`,
          }}
        >
          {(props) => (
            <div>
              <CardHeader>
                <Row>
                  <Col sm="2">
                    <div className="number-of-records">
                      <p>
                        Number of records: <strong>{numberOfRecords}</strong>
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div className="c-search-field form-inline justify-content-end">
                      <div className="form-group mr-2">
                        {/* <SearchBar {...props.searchProps} className="" /> */}
                        <Input
                          autoFocus="autofocus"
                          type="text"
                          placeholder="Search"
                          value={searchKeyword}
                          onChange={(e) => {
                            setSearchKeyword(e.target.value);
                          }}
                        ></Input>
                      </div>
                      {/* <ClearSearchButton {...props.searchProps} className="clear-search-button" /> */}
                      <Button
                        className="clear-search-button"
                        onClick={() => {
                          clearSearchValues();
                        }}
                        disabled={searchKeyword ? false : true}
                        title={searchKeyword ? "" : "Nothing To Clear"}
                      >
                        Clear
                      </Button>
                      <div className="export-button">
                        <MyExportCSV {...props.csvProps} />
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="separator" />
                <div className="float-right pull-right"></div>
              </CardHeader>
              <CardBody className="patient-list dropdown-menu-up">
                {!loading && data?.length === 0 ? (
                  blankState
                ) : !loading ? (
                  <BootstrapTable
                    {...props.baseProps}
                    remote
                    bootstrap4
                    hover
                    /* id={props.key} */
                    bordered={false}
                    keyField="id3"
                    // onDataSizeChange={handleDataChange}
                    pagination={paginationFactory({
                      page: facilityPage,
                      sizePerPage: facilitySizePerPage,
                      sizePerPageList: [50, 100, 150, 200],
                      totalSize,
                    })}
                    onTableChange={handleTableChangeForFacility}
                  />
                ) : (
                  <Spinner />
                )}
              </CardBody>
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </div>
  );
};

export default ExportCSVTable;
