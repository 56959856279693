import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, Spinner, Form, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select-virtualized';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { GlobalState } from '../../contextAPI/Context';
import ToggleButton from "react-toggle-button";
import AsyncSelect from 'react-select/async';

const AddNewGroupModal = (props) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");
  const { groupNamesReload, setGroupNamesReload, getTimeZoneList, RegionNames, getRegionNames, } = useContext(GlobalState);  // eslint-disable-line

  const {
    setGroupAddModal,
    groupAddModal,
    subscriptionNames,
    groupNameList,
    timeZoneList,
    zipCodeList,
    reactMultiSelectForNewGroup,
    setSelectedFacilities, // eslint-disable-line
    facilities,  // eslint-disable-line
    isAISummarizationEnable,
    setIsAISummarizationEnable,
    setReload,
    reload,
    setSelectedFacilitiesForAdd,
    facilitiesForSaving,
    isWhiteLabelingEnabled,
    setIsWhiteLabelingEnabled
  } = props;

  const [groupName, setGroupName] = useState("")
  const [groupPhone, setGroupPhone] = useState("")
  const [groupAddress1, setGroupAddress1] = useState("")
  const [groupAddress2, setGroupAddress2] = useState("")
  const [groupNpi, setGroupNpi] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [referralFax, setReferralFax] = useState("")
  const [Region, setRegion] = useState("")
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [selectedZip, setSelectedZip] = useState({});
  const [selectedAdmin, setSelectedAdmin] = useState({});
  const [selectedTimeZone, setSelectedTimeZone] = useState({});
  const [selectedSupervisorName, setSelectedSupervisorName] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [pointOfContactName, setPointOfContactName] = useState("")
  const [pointOfContactPhone, setPointOfContactPhone] = useState("")
  const [pointOfContactEmail, setPointOfContactEmail] = useState("")
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedInvoiceDate, setSelectedInvoiceDate] = useState("");
  const [pointOfContactEmailValid, setPointOfContactEmailValid] = useState(false)
  const isPayingClient = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];
  const selectInvoiceDates = [
    { value:'30-60 days', label:"30-60 days" },
    { value:'60-90 days', label:"60-90 days" },
    { value:'> 90 days', label:"> 90 days" }
  ]

  const [date, setDate] = useState(moment().toDate());
  const [phoneNumberInvalidStatus, setPhoneNumberInvalidStatus] = useState(false);
  const [updateButtonBlankState, setUpdateButtonBlankState] = useState(true);
  const regXForNubers = /^[0-9]*$/;
  const regexForEmail = new RegExp("^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@([a-zA-Z0-9-]+\\.)+[a-zA-Z0-9]{2,}$");

  const toggle = () => {
    if (groupAddModal) {
      setGroupAddModal(false);
    }
    else {
      setGroupAddModal(true);
      setPointOfContactEmailValid(false)
    }
  }

  const saveGroupDetails = () => {
    if (groupPhone.length > 15) {
      return toastr.error("Failed", "Phone number should be less than 15 digits")
    }
    if (groupNpi.length > 15) {
      return toastr.error("Failed", "NPI should be less than 15 digits")
    }
    if (referralFax.length > 15) {
      return toastr.error("Failed", "Referral fax should be less than 15 digits")
    }
    if (pointOfContactEmail && pointOfContactEmail.length > 1) {
      if (!regexForEmail.test(pointOfContactEmail)) {
        setPointOfContactEmailValid(true);
        return false;
      } else {
        setPointOfContactEmailValid(false);
      }
    }
    setSpinner(true)
    let admin_id = localStorage.getItem('adminID');

    let gp_num = Region ? Region.value : ""
    let gp_string = '' + gp_num                       // eslint-disable-line
    const params = {
      "admin_id": admin_id ? admin_id : "",
      "group_name": groupName ? groupName : "",
      "group_phone": groupPhone ? groupPhone : "",
      "group_referral_fax": referralFax ? referralFax : "",
      "group_address1": groupAddress1 ? groupAddress1 : "",
      "group_address2": groupAddress2 ? groupAddress2 : "",
      "group_npi": groupNpi ? groupNpi : "",
      "group_zip_code": selectedZip ? selectedZip.label : "",
      "group_subscription_plan_id": selectedSubscription ? selectedSubscription.value : "",
      "group_admin_id": selectedAdmin ? selectedAdmin.value : "",
      "group_supervisor_id": selectedSupervisorName ? selectedSupervisorName.value : "",
      "group_timezone_id": selectedTimeZone ? selectedTimeZone.value : "",
      "group_expiry_date": date ? moment(date).format('YYYY-MM-DD') : "",
      "group_facility_id_list": facilitiesForSaving ? facilitiesForSaving : [],
      "point_of_contact_name": pointOfContactName ? pointOfContactName : "",
      "point_of_contact_email": pointOfContactEmail ? pointOfContactEmail : "",
      "point_of_contact_phone": pointOfContactPhone ? pointOfContactPhone : "",
      "paying_client": selectedStatus ? selectedStatus.value : "",
      "invoice_pending": selectedInvoiceDate ? selectedInvoiceDate.value:"",
      "regionID": Region ? Region.value : "",
      "isWhiteLabelingEnabled": isWhiteLabelingEnabled ? isWhiteLabelingEnabled : 0,
      "isAISummarizationEnable": isAISummarizationEnable
      //"regionID": selectedRegion ? selectedRegion.value : ""
    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/create-new-group/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        if (response.status === 201) {
          setSpinner(false)
          toastr.success("Group created successfully")
          setReload(reload + 1);
          setGroupNamesReload(groupNamesReload + 1);
          toggle();
        }

      })
      .catch(error => {
        setSpinner(false)
        toastr.error(error.response.data.message)

      });


  };
  // eslint-disable-next-line no-unused-vars
  const onChangeForNPINumber = (e) => {
    if (regXForNubers.test(e) === false) {
      toastr.warning("Invalid NPI Number", "alphabets are not allowed")
    } else {
      setGroupNpi(e)
    }
  }
  const GetZipDetails = (e) => {
    setSpinner(true);
    const params = {
      "group_zip_code": e ? e.label : ""

    }
    AxiosInter.post(`${API_ENDPOINT}/admin-api/get-zip-details/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        setSpinner(false);
        if (response.status === 200) {
          if (response.data.data.state === "") {
            setState("")
          }
          if (response.data.data.city === "") {
            setCity("")
          }
          else {
            setState(response.data.data.state)
            setCity(response.data.data.city)
          }
        }
      })
      .catch(error => {
        setSpinner(false);
        toastr.error("Error", 'Something went wrong !')

      });
  }

  const validate = () => {
    if (groupName && groupAddress1 && selectedSubscription && selectedTimeZone) {
      setUpdateButtonBlankState(false)
    }
    else {
      setUpdateButtonBlankState(true)
    }
  }

  const getDate = (date) => {
    setDate(date);
  }

  const loadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      // const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-app-user-name/${inputValue.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          }
        })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }

  };

  const minLength = 4;
  const customNoOptionsMessage = ({ inputValue }) => {
    if (inputValue.length < minLength) {
      return "Please enter at least 4 letters to search";
    } else {
      return "No option";
    }
  };
  useEffect(() => {
    validate()

  }, [groupName, groupPhone, groupAddress1, selectedSubscription, selectedTimeZone]) // eslint-disable-line

  useEffect(() => {
    if (!groupAddModal) {
      setGroupName("");
      setGroupNpi("");
      setGroupPhone("");
      setGroupAddress1("");
      setGroupAddress2("");
      setReferralFax("");
      setSelectedSubscription("");
      setSelectedSupervisorName("");
      setSelectedTimeZone("");
      setSelectedZip("");
      setSelectedAdmin("");
      setCity("");
      setState("");
      setRegion("");
      setIsWhiteLabelingEnabled(false);
      setIsAISummarizationEnable(false);
      ///setSelectedRegion()
      // setSelectedFacilities([])
      setSelectedFacilitiesForAdd([])
      // setSelectedExpiryDate("")
      setPhoneNumberInvalidStatus(false)
      setPointOfContactName("")
      setPointOfContactPhone("")
      setPointOfContactEmail("")
      setSelectedStatus("")
      setPointOfContactEmailValid(false)
      setSelectedInvoiceDate("")
    }
  }, [groupAddModal]) // eslint-disable-line

  useEffect(() => {
    if (date === null) {
      setDate(moment().toDate())
    }
  }, [date]) // eslint-disable-line

  /* useEffect(()=>{
    if(timeZoneList.length <=0 ) {
      getTimeZoneList()
    }
  },[timeZoneList]) // eslint-disable-line */

  const handleChange = value => {
    setRegion(value);
    // console.log(value)
  }

  const regionloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-m-region-list/${inputValue}`, {
        headers: {
          Authorization: API_TOKEN,
        }
      })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });

    }
  };
  const changeOnPhoneNumber = (e) => {
    if (regXForNubers.test(e) === false) {
        toastr.warning("Invalid Phone Number", "alphabets are not allowed")
    } else {
      setGroupPhone(e)
    }
  }

  const regXForNumbers = /^[0-9]*$/;
  const changeOnNPIField = (e) => {
    if (regXForNumbers.test(e) === false) {
      toastr.warning("Warning", "Invalid NPI,accepts only numbers");
    } else {
      setGroupNpi(e);
    }
  };
  const changeOnPointOFContactNumber = (e) => {
    if (regXForNubers.test(e) === false) {
      toastr.warning(
        "Invalid point of contact Phone Number",
        "alphabets are not allowed"
      );
    } else {
      setPointOfContactPhone(e);
    }
  };

  return (
    <div>
      <Modal isOpen={groupAddModal} size="lg">
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Add New Group
        </ModalHeader>
        <ModalBody className="overflow-modal">
          {spinner ? <Spinner /> : ""}
          <Form>
            <FormGroup>
              <Row>
                <Col sm={6}>
                  <Label for="group_name">
                    Group Name<span className="required">*</span>
                  </Label>
                  <Input
                    id="group_name"
                    placeholder="Group name"
                    value={groupName}
                    onChange={(e) => {
                      setGroupName(e.target.value);
                    }}
                    minLength="1"
                    maxLength="120"
                  />
                </Col>
                <Col sm={6}>
                  <Label for="phone_number">Phone Number</Label>
                  <Input
                    id="phone_number"
                    type="text"
                    value={groupPhone}
                    placeholder="Enter the Phone Number"
                    onChange={(e) => {
                      if (e.target.value.length >= 13) {
                        toastr.warning(
                          "Invalid Number",
                          "number length exceeded"
                        );
                      } else {
                        changeOnPhoneNumber(e.target.value);
                      }
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={6}>
                  <Label for="npi">NPI</Label>
                  <Input
                    id="npi"
                    type="text"
                    value={groupNpi}
                    placeholder="Enter the NPI number "
                    onChange={(e) => {
                      if (e.target.value.length >= 11) {
                        toastr.warning(
                          "Invalid Number",
                          "Number length exceeded"
                        );
                      } else {
                        changeOnNPIField(e.target.value);
                      }
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <Label for="referral_fax">Referral Fax</Label>
                  <Input
                    id="referral_fax"
                    type="number"
                    value={referralFax}
                    placeholder="Enter the Referral fax"
                    onChange={(e) => {
                      if (e.target.value.length >= 20) {
                        toastr.warning(
                          "Invalid Number",
                          "number length exceeded"
                        );
                      } else {
                        setReferralFax(e.target.value);
                      }
                    }}
                    maxLength="250"
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={6}>
                  <Label for="address1">
                    Address 1<span className="required">*</span>{" "}
                  </Label>
                  <Input
                    id="address1"
                    type="textarea"
                    rows={4}
                    cols={2}
                    placeholder="Enter the group address"
                    value={groupAddress1}
                    onChange={(e) => {
                      setGroupAddress1(e.target.value);
                    }}
                    minLength="1"
                    maxLength="120"
                  />
                </Col>
                <Col sm={6}>
                  <Label for="address2">Address 2</Label>
                  <Input
                    id="address2"
                    type="textarea"
                    rows={4}
                    cols={2}
                    value={groupAddress2}
                    placeholder="Enter the group address"
                    onChange={(e) => {
                      setGroupAddress2(e.target.value);
                    }}
                    maxLength="50"
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={3}>
                  <Label for="zip">ZIP</Label>
                  <Select
                    name="zip"
                    options={zipCodeList}
                    value={selectedZip}
                    placeholder="Choose Zip Code"
                    isSearchable
                    isClearable
                    onChange={(e) => {
                      setSelectedZip(e);
                      GetZipDetails(e);
                    }}
                  />
                </Col>
                <Col sm={3}>
                  <Label for="state">State</Label>
                  <Input
                    id="state"
                    type="text"
                    value={state}
                    disabled
                    placeholder="State"
                    onChange={(e) => {
                      setState(e);
                    }}
                  />
                </Col>

                <Col sm={3}>
                  <Label for="city">City</Label>
                  <Input
                    id="city"
                    value={city}
                    disabled
                    placeholder="City"
                    onChange={(e) => {
                      setCity(e);
                    }}
                    maxLength="250"
                  />
                </Col>

                <Col sm={3}>
                  <Label for="Region">Region</Label>
                  <AsyncSelect
                    isClearable
                    isSearchable
                    name="Region"
                    placeholder="Region"
                    noOptionsMessage={customNoOptionsMessage}
                    value={Region}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    validate={{
                      maxLength: { value: 120 },
                    }}
                    onChange={handleChange}
                    loadOptions={(inputValue) => regionloadOptions(inputValue)}
                  />
                </Col>

                <Col sm={6}>
                  <br></br>
                  <Label for="subscription_plan">
                    Subscription Plan<span className="required">*</span>{" "}
                  </Label>
                  <Select
                    value={selectedSubscription}
                    name="subscription_plan"
                    options={subscriptionNames}
                    placeholder="Subscription Plan"
                    isClearable={true}
                    onChange={(e) => {
                      setSelectedSubscription(e);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <br></br>
                  <Label for="time_zone">
                    Time Zone<span className="required">*</span>{" "}
                  </Label>
                  <Select
                    name="time_zone"
                    options={timeZoneList}
                    placeholder="Time Zone"
                    isSearchable
                    isClearable
                    value={selectedTimeZone}
                    onChange={(e) => {
                      setSelectedTimeZone(e);
                    }}
                  />
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col sm={6}>
                  <Label for="supervisor_group">Supervisor Group</Label>
                  <Select
                    name="supervisor_group"
                    options={groupNameList}
                    placeholder="Supervisor Group"
                    isSearchable
                    isClearable
                    value={selectedSupervisorName}
                    onChange={(e) => {
                      setSelectedSupervisorName(e);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <Label for="group_admin">Group Admin</Label>
                  <AsyncSelect
                    cacheOptions
                    isClearable
                    value={selectedAdmin}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadOptions}
                    noOptionsMessage={customNoOptionsMessage}
                    onChange={(e) => {
                      setSelectedAdmin(e);
                    }}
                    placeholder="Type Admin name for options"
                  />
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col sm={6}>{reactMultiSelectForNewGroup()}</Col>
                <Col sm={6}>
                  <Label for="expiryDate">
                    Expiry Date<span className="required">*</span>
                  </Label>
                  <div className="sign-up-dob-input">
                    <DatePicker
                      dateFormat="dd MMM yyyy"
                      selected={date}
                      minDate={moment().toDate()}
                      onChange={(date) => {
                        getDate(date);
                      }}
                      showYearDropdown
                      showMonthDropdown
                    />
                  </div>
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col sm={6}>
                  <Label for="point_of_contact_name">
                    Point Of Contact Name
                  </Label>
                  <Input
                    id="pont_of_contact_name"
                    placeholder="Enter point of contact name"
                    value={pointOfContactName}
                    onChange={(e) => {
                      setPointOfContactName(e.target.value);
                    }}
                    minLength="1"
                    maxLength="120"
                  />
                </Col>
                <Col sm={6}>
                  <Label for="point_of_contact_phone">
                    Point Of Contact Phone Number
                  </Label>
                  <Input
                    id="point_of_contact_phone"
                    type="text"
                    value={pointOfContactPhone}
                    placeholder="Enter point of contact phone number"
                    onChange={(e) => {
                      if (e.target.value.length >= 13) {
                        toastr.warning(
                          "Invalid Number",
                          "number length exceeded"
                        );
                      } else {
                        changeOnPointOFContactNumber(e.target.value);
                      }
                    }}
                  />
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col sm={6}>
                  <Label>Point Of Contact Email</Label>
                  <Input
                    id="email"
                    type="email"
                    value={pointOfContactEmail}
                    placeholder="Enter point of contact email"
                    onChange={(e) => {
                      setPointOfContactEmail(e.target.value);
                    }}
                    invalid={pointOfContactEmailValid ? true : false}
                    style={{ margin: "0" }}
                  />
                </Col>
                <Col sm="6">
                  <Label>Paying Client</Label>
                  <Select
                    name="paying_client"
                    options={isPayingClient}
                    placeholder="Select Paying Client"
                    isClearable
                    value={selectedStatus}
                    onChange={(e) => {
                      setSelectedStatus(e);
                    }}
                  />
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col sm="6">
                  <Row style={{marginBottom: "10px"}}>
                    <Col sm={9}>
                      <Label>AI Summarization Enabled</Label>
                    </Col>
                    <Col sm={2}>
                      <ToggleButton
                        inactiveLabel={"No"}
                        activeLabel={"Yes"}
                        value={isAISummarizationEnable}
                        onToggle={() => {
                          setIsAISummarizationEnable(!isAISummarizationEnable);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={9}>
                      <Label>Is White Labeling Enabled</Label>
                    </Col>
                    <Col sm={2}>
                      <ToggleButton
                        inactiveLabel={"No"}
                        activeLabel={"Yes"}
                        value={isWhiteLabelingEnabled}
                        onToggle={() => {
                          setIsWhiteLabelingEnabled(!isWhiteLabelingEnabled);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm="6">
                  <Label>Invoice Pending</Label>
                  <Select
                    name="invoice_pending"
                    options={selectInvoiceDates}
                    placeholder="Select invoice pending dates"
                    isClearable
                    value={selectedInvoiceDate}
                    onChange={(e) => {
                      setSelectedInvoiceDate(e);
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="custom-modal-footer">
          <Button
            onClick={() => {
              saveGroupDetails();
            }}
            disabled={updateButtonBlankState}
            title={
              updateButtonBlankState
                ? "Please fill in all mandatory fields"
                : phoneNumberInvalidStatus && updateButtonBlankState
                ? "Please provide valid details"
                : ""
            }
          >
            Add
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddNewGroupModal;