import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  Button, Spinner
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import MomentConfig from "../../common/MomentConfig";
import ProviderSpecialityRankingFilters from "../../newComponents/utilities/ProviderSpecialityRankingFilters";

/* const { SearchBar, ClearSearchButton } = Search; */

const ProviderSpecialityRankingDataTable = (props) => {
  // LOCAL VARIABLES
  const {
    totalSize,
    onTableChange,
    reload,
    setReload,
    search,
    setSearch,
    offset,
    setOffset,
    exportTableData, spinner, data
  } = props;
  const [numberOfRecords, setNumberOfRecords] = useState(0);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      //props.onExport();
      exportTableData();
    };
    return (
      <div>
        <button className="btn btn-export mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No data available in table</h3>
      </CardBody>
    </Card>
  );

  const options = {
    page: props.page,
    nextPageText: "Next",
    prePageText: "Previous",
    sizePerPage: props.sizePerPage,
    sizePerPageList: [25, 50, 150, 200],
    totalSize,
  };

  useEffect(() => {
    setNumberOfRecords(totalSize);
  }, [totalSize]);
  return (
    <div className="physicians_table patient_table table-list-height">
      <Card>
        <ToolkitProvider
          keyField="id"
          data={props.data}
          columns={props.columns}
          search
          exportCSV={{
            fileName: `Equipo-Provider-speciality-Ranking-${moment().format(
              MomentConfig.MOMENT_FORMAT_DATEANDTIME
            )}.csv`,
          }}
        >
          {(props) => (
            <div>
              <CardHeader>
                <Row>
                  <Col>
                    <ProviderSpecialityRankingFilters
                      reload={reload}
                      setReload={setReload}
                      offset={offset}
                      setOffset={setOffset}
                      /* groupDropDownROS={groupDropDownROS}
                      groupDropDownROF={groupDropDownROF}
                      orderTypeDropDown={orderTypeDropDown} */
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="2">
                    <div className="number-of-records">
                      <p>
                        Number of records: <strong>{numberOfRecords}</strong>
                      </p>
                    </div>
                  </Col>
                  <Col>
                    {/* <SearchBar {...props.searchProps} className="search-box" />
                    <ClearSearchButton {...props.searchProps} className="clear-search-box" /> */}
                    <div className="search-order-serial-number"></div>
                    <div className="form-inline justify-content-end">
                      <div className="form-group mr-2">
                        <Input
                          id="num"
                          value={search}
                          placeholder="Search"
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                      <Button
                        className="clear-search-box"
                        onClick={() => {
                          setSearch("");
                          setReload(reload + 1);
                        }}
                      >
                        Clear
                      </Button>
                      <div className="export-button ml-3">
                        <MyExportCSV {...props.csvProps} />
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="separator" />
                <div className="float-right pull-right"></div>
              </CardHeader>
              <CardBody className="patient-list">
                {!spinner && data?.length===0?(blankState): !spinner?(
                  <div className="providerTable">
                    <BootstrapTable
                      {...props.baseProps}
                      remote
                      bootstrap4
                      hover
                      pagination={paginationFactory(options)}
                      onTableChange={onTableChange}
                    />
                  </div>
                  ):(<Spinner/>)}
              </CardBody>
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </div>
  );
};

export default ProviderSpecialityRankingDataTable;
