import React, { useState, useEffect, useContext } from "react";
import Cookie from 'js-cookie';
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Spinner,
  TabContent,
  Button,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "../../hoc/Hux";
import ReferralImportLogDataTable from "./ReferralImportLogDataTable";
import ReferralSenderDataTable from "./ReferralSenderDataTable";
import ReferralDraftDataTable from "./ReferralDraftDataTable";
import ReferralFulfillerDataTable from "./ReferralFulfillerDataTable";
import OrderAgainstRuleDataTable from "./OrderAgainstRuleDataTable";
import { GlobalState } from "../../contextAPI/Context";
import classnames from "classnames";
import "../tables/Tables.css";
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye,faListAlt } from "@fortawesome/free-solid-svg-icons";
import ReferralSenderTableModal from "../../newComponents/utilities/ReferralSenderTableModal";
import EditReferralSenderModal from "../../newComponents/utilities/EditReferralSenderModal";
import EditReferralFulfillerModal from "../../newComponents/utilities/EditReferralFulfillerModal";
import ReferralFulfillerTableModal from "../../newComponents/utilities/ReferralFulfillerTableModal";
import ReferralSenderStatusChangeModal from "../../newComponents/utilities/ReferralSenderStatusChangeModal";
import ReferralFulfillerStatusChangeModal from "../../newComponents/utilities/ReferralFulfillerStatusChangeModal";
import ReferralAssigneStatusChangeModal from "../../newComponents/utilities/ReferralAssigneStatusChangeModal";
import ReferralAssigneeTagDataTable from "./ReferralAssigneeTagDataTable";
import ReferralFulfillerOrdertypes from "../../newComponents/utilities/referralfulfillerordertypes.js";
import AxiosInter from './../../AxiosInter.js';
import MomentConfig from "../../common/MomentConfig";
import moment from "moment";
import ReferralPreferance from "./ReferralPreferance";
import AllProviderSupportedInsurances from "./AllProviderSupportedInsurances";
import AllFacilitySupportedInsurances from "./AllFacilitySupportedInsurance";
import SenderAssigneeAllocation from "./SenderAssigneeAllocation";
import ReferralCategoryConfiguration from "./ReferralCategoryConfiguration";
import AllRefOrderTypes from "./AllRefOrderTypes.js"
import AssigneeInsuranceMapping from "./AssigneeInsuranceMapping.js";
import HIMUsers from "./HIMUsers.js";

const Tables = (props) => {
  // CONTEXT VARIABLES
  const {
    // IMPORT
    senderId,
    fulFillerId,
    year,
    month,
    status,
    timeZone,
    orderColumn,
    importlogReload,
    setImportlogReload,

    // COMMON
    setBlankStateMessage,

    setSpinner,

    // DRAFT
    senderIdDraft,
    fulFillerIdDraft,
    yearDraft,
    monthDraft,
    statusDraft,
    timeZoneDraft,
    orderColumnDraft,
    referralDraftReload,
    draftReload,
    setDraftReload,

    // SENDER
    

    senderDropDownList,
    setSenderDropDownList,
    senderDropDown,
    // FULFILLER
    referralFulfillerReload,
    setReferralFulfillerReload,
    fullFillerDropDownList,
    setFullFillerDropDownList,
  } = useContext(GlobalState);

  //TAB NAMES
  const tabs = [
    { id: 1, name: "Import Data" },
    { id: 2, name: "Draft Order" },
    { id: 3, name: "Referral Senders" },
    { id: 4, name: "Referral Fulfiller" },
    { id: 5, name: "Order Against Rule" },
    { id: 6, name: "Assignation by Tag" },
    { id: 7, name: "Referral Preference" },
    { id: 8, name: "Supported Insurances" },
    { id: 9, name: "Facility Supported Insurances" },
    { id: 10, name: "Assignation by Facility" },
    { id: 11, name: "Referral Order Type" },
    { id: 12, name: "Category Configuration" },
    { id: 13, name: "Assignee - Insurance Mapping" },
    { id: 14, name: "HIM Users" },
   ];

  // LOCAL VARIABLES
  const [referralSenderReload, setReferralSenderReload] = useState(0)
  const [activeTab, setActiveTab] = useState("1");
  const [searchDraft, setSearchDraft] = useState(""); // eslint-disable-line
  const [searchOrderAgainstRule,SetSearchOrderAgainstRule] = useState("");
  const [statusFilter, setStatusFilter]=useState("");
  const [searchImport, setSearchImport] = useState(""); // eslint-disable-line
  const [referrlImportLog, setReferrlImportLog] = useState([]);
  const [referrlDraftData, setReferrlDraftData] = useState([]);
  const [referrlSender, setReferrlSender] = useState([]);
  const [referrlfulfiller, setReferrlFulfiller] = useState([]);
  const [loading, setLoading] = useState(false);
  const [referralOrderSenderID, setReferralOrderSenderID] = useState("");
  const [referralOrderFulfillerID, setReferralOrderFulfillerID] = useState("");
  const [referralOrderSenderGpName, setReferralOrderSenderIDGpName] =
    useState("");
  const [referralOrderFulfillerGpName, setReferralOrderFulfillerGpName] =
    useState("");
  const [ReferralSenderTabelModal, setReferralSenderTabelModal] =
    useState(false);
  const [ReferralFulfillerTabelModal, setReferralFulfillerTabelModal] =
    useState(false);
  const [ReferralSenderEditModal, setReferralSenderEditModal] = useState(false);
  const [ReferralFulfillerEditModal, setReferralFulFillerEditModal] =
    useState(false);
  const [ReferralSenderStatusModal, setReferralSenderStatusModal] =
    useState(false);
  const [rowData, setRowData] = useState("");
  const [referralReferralSenderID, setReferralReferralSenderID] = useState("");
  const [referralSenderStatus, setReferralSenderStatus] = useState("");
  const [referralReferralFulfillerID, setReferralReferralFulfillerID] =
    useState("");
  const [referralFulfillerStatus, setReferralFulfillerStatus] = useState("");
  const [ReferralFulfillerStatusModal, setReferralFulfillerStatusModal] =
    useState(false);
  const [referralAssigneTagID, setReferralAssigneTagID] = useState("");
  const [referralAssigneTagStatus, setReferralAssigneTagStatus] = useState("");
  const [ReferralAssigneeStatusModal, setReferralAssigneeStatusModal] =
    useState(false);
  const [dropDownDataROS, setDropDownDataROS] = useState("");
  const [dropDownDataROF, setDropDownDataROF] = useState("");
  const [groupDropDownROS, setGroupDropDownROS] = useState("");
  const [groupDropDownROF, setGroupDropDownROF] = useState("");

  //EDIT REFERRAL SENDERS VARIABLES
  const [editNPI, setEditNPI] = useState("");
  const [editAutoProcess, setEditAutoProcess] = useState("");
  const [editSubscriptionType, setEditSubscriptionType] = useState("");
  const [editEnableEmail, setEditEnableEmail] = useState("");
  const [editIsClinical, setEditIsClinical] = useState("");
  const [editIsTransport, setEditIsTransport] = useState("");
  const [editCategoryType, setEditCategoryType] = useState([]);
  const [categoryTypeOptions, setCategoryTypeOptions] = useState([]);

  //EDIT REFERRAL FULFILLER VARIABLES
  const [editName, setName] = useState("");
  const [editReferralID, setReferralID] = useState("");
  const [editAppointmentSMSNote, setEditAppointmentSMSNote] = useState("");
  const [editSubscription, setEditSubscription] = useState("");
  const [editDataExchangeEnabled, setEditDataExchangeEnabled] = useState("");
  const [editClinical, setEditClinical] = useState("");
  const [editTransport, setEditTransport] = useState("");
  const [editRecapGenerationEnabled, setEditRecapGenerationEnabled] =
    useState("");

  // PAGINATION VARIABLES FOR REFERRAL FULFILLER
  const [fulfillerOffset, setFulfillerOffset] = useState(0);
  const [fulfillerPage, setFulfillerPage] = useState(1);
  const [fulfillerTotalSize, setFulfillerTotalSize] = useState(0);
  const [fulfillerSizePerPage, setFulfillerSizePerPage] = useState(50);
  const [searchKeysForFulfiller, setSearchKeysForFulfiller] = useState("");
  const [sortColumnForFulfiller, setSortColumnForFulfiller] = useState("");
  const [sortOrderForFulfiller, setSortOrderForFulfiller] = useState("");

  // PAGINATION VARIABLES FOR REFERRAL SENDER
  const [senderOffset, setSenderOffset] = useState(0);
  const [senderPage, setSenderPage] = useState(1);
  const [senderTotalSize, setSenderTotalSize] = useState(0);
  const [senderSizePerPage, setSenderSizePerPage] = useState(50);
  const [searchKeysForSender, setSearchKeysForSender] = useState("");
  const [sortColumnForSender, setSortColumnForSender] = useState("");
  const [sortOrderForSender, setSortOrderForSender] = useState("");

  // VARIABLES FOR ORDER AGAINST RULE
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(25);
  const [reload, setReload] = useState(0); // eslint-disable-line
  const [orderSerialNumber, setOrderSerialNumber] = useState(""); // eslint-disable-line

  // LOCAL VARIABLES FOR REFERRAL ASSIGNEE TAG
  const [referralAssigneeTagList, setReferralAssigneeTagList] = useState([]);
  const [referralAssigneeTagGroupName, setReferralAssigneeTagGroupName] =
    useState("");
  const [referralAssigneeTagName, setReferralAssigneeTagName] = useState("");
  const [referralAssigneeTagAppUser_Name, setReferralAssigneeTagAppUser_Name] =
    useState("");

  // PAGINATION VARIABLES FOR REFERRAL ASSIGNEE TAG
  const [offsetForReferralAssigneeTag, setOffsetForReferralAssigneeTag] =
    useState(0);
  const [pageForReferralAssigneeTag, setPageForReferralAssigneeTag] =
    useState(1);
  const [totalSizeForReferralAssigneeTag, setTotalSizeForReferralAssigneeTag] =
    useState(0);
  const [
    sizePerPageForReferralAssigneeTag,
    setSizePerPageForReferralAssigneeTag,
  ] = useState(50);
  const [
    searchKeysForReferralAssigneeTag,
    setSearchKeysForReferralAssigneeTag,
  ] = useState("");
  const [
    sortColumnForReferralAssigneeTag,
    setSortColumnForReferralAssigneeTag,
  ] = useState("");
  const [sortOrderForReferralAssigneeTag, setSortOrderForReferralAssigneeTag] =
    useState("");
  const [reloadForReferralAssigneeTag, setReloadForReferralAssigneeTag] =
    useState(0);

  const [ReferralFulfillerOrderTypeModal, setReferralFulfillerOrderTypeModal] =
    useState(false);
  const [referralFulfillerOrderOptions, SetReferralFulfillerOrderOPtions] =
    useState([]);

  const fileExportNameForSender = "Referral-Sender";
  const fileExportNameForFulfiller = "Referral-Fulfiller";
  const fileExportNameForOrderAgainstRule = "Order-Against-Rule";
  const fileNameForSender = `Equipo-${fileExportNameForSender}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;
  const fileNameForFulfiller = `Equipo-${fileExportNameForFulfiller}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;
  const fileNameForOrderAgainstRule = `Equipo-${fileExportNameForOrderAgainstRule}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME
    )}.csv`;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  
  const d = new Date();

  const fetchReferralImportLogData = () => {
    setLoading(true);
    const params = {
      sender_id: senderId ? Number(senderId) : 0,
      fulfiller_Id: fulFillerId ? Number(fulFillerId) : 0,
      year: year ? Number(year) : Number(d.getFullYear()),
      month: month ? Number(month) : Number(d.getMonth() + 1),
      status: status ? status.toString() : "999",
      timeZone: timeZone ? timeZone : "US/Hawaii",
      orderColumn: orderColumn ? orderColumn : "importedOn",
      sortOrder: "desc",
      recLimit: 0,
      counter: 2,
      offset: 0,
      search_keyword: searchImport ? searchImport.toString() : "",
    };
    AxiosInter.post(`${API_ENDPOINT}/admin-api/get-referral-import-log/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((res) => {
        setReferrlImportLog(res.data);
        localStorage.removeItem("subscriptionFilter");
        localStorage.removeItem("groupNameFilter");
        localStorage.removeItem("statusFilter");
        localStorage.removeItem("adminNameFilter");
        localStorage.removeItem("zipCodeFilter");
        localStorage.removeItem("timeZoneFilter");
        setLoading(false);
      })
      .catch(function (error) {
        // console.log(error,"error");
        toastr.warning("Error Occurred", "Please try again");
        setLoading(false);
      });
  };

  const fetchReferralDraftData = () => {
    setLoading(true);
    const params = {
      sender_id: senderIdDraft ? Number(senderIdDraft) : 0,
      fulfiller_Id: fulFillerIdDraft ? Number(fulFillerIdDraft) : 0,
      year: yearDraft ? Number(yearDraft) : Number(d.getFullYear()),
      month: monthDraft ? Number(monthDraft) : Number(d.getMonth() + 1),
      status: statusDraft ? statusDraft.toString() : 8,
      timeZone: timeZoneDraft ? timeZoneDraft : "US/Hawaii",
      orderColumn: orderColumnDraft ? orderColumnDraft : "ro.creation_date",
      sortOrder: "desc",
      recLimit: 0,
      counter: 2,
      offset: 0,
      search_keyword: searchDraft ? searchDraft.toString() : "",
    };

    AxiosInter.post(`${API_ENDPOINT}/admin-api/get-referral-draft/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((res) => {
        setReferrlDraftData(res.data);
        setLoading(false);
      })
      .catch(function (error) {
        // console.log(error,"error");
        toastr.warning("Error Occurred", "Please try again");
        setLoading(false);
      });
  };

  const fetchDataForOrderAgainstRule = () => {
    setLoading(true);
    const params = {
      orderSerialNumber: orderSerialNumber ? orderSerialNumber : "",
      record_limit: sizePerPage ? sizePerPage.toString() : "25",
      offset: offset ? offset.toString() : "0",
      search_keyword: searchOrderAgainstRule ? searchOrderAgainstRule.toString() : "",
    };
  
    AxiosInter.post(`${API_ENDPOINT}/admin-api/order-agains-rule/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((response) => {
        if (response.data.data && response.data.data.length > 0) {
          setBlankStateMessage(false);
          setTotalSize(response.data.record_count);
          setData(response.data.data);
          localStorage.setItem("offset", offset);
        } else {
          setBlankStateMessage(true);
          setData([]);
        }
      })
      .catch((error) => {
        setBlankStateMessage(true);
        // toastr.warning('Error Occurred', 'Please try again');
      })
      .finally(() => {
        setLoading(false); 
      });
  };
  
  const exportDataForOrderAgainstRule = () => {
    setLoading(true);
    const params = {
      orderSerialNumber: orderSerialNumber || "",
      record_limit: "", // Ensure you pass any necessary record limit here
      offset: offset ? offset.toString() : "0",
      search_keyword: searchOrderAgainstRule ? searchOrderAgainstRule.toString() : "",
      file_name: fileNameForOrderAgainstRule,
    };
  
    AxiosInter.post(`${API_ENDPOINT}/admin-api/export-orderAgainst-which-rule/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
      responseType: "blob",
    })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileNameForOrderAgainstRule}`,
            response.headers["x-suggested-filename"] || `${fileNameForOrderAgainstRule}.xlsx`
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        setBlankStateMessage(true);
        // toastr.warning('Error Occurred', 'Please try again');
      })
      .finally(() => {
        setLoading(false); 
      });
  };
  
  const fetchReferralSenderData = () => {
    let params;
    let tempSenderColumn = "";
    setLoading(true);
    if (sortColumnForSender === "GroupName") {
      tempSenderColumn = "g.name";
    } else if (sortColumnForSender === "GroupNPI") {
      tempSenderColumn = "g.npi";
    } else {
      tempSenderColumn = "";
    }

    if (searchKeysForSender.length > 2) {
      params = {
        record_limit: 100,
        off_set: "0",
        search_keyword: searchKeysForSender
          ? searchKeysForSender.toString()
          : "",
        sortOrder: sortOrderForSender ? sortOrderForSender : "",
        sortColumn: tempSenderColumn ? tempSenderColumn : "",
      };
    } else {
      params = {
        record_limit: senderSizePerPage,
        off_set: senderOffset.toString(),
        search_keyword: searchKeysForSender
          ? searchKeysForSender.toString()
          : "",
        sortOrder: sortOrderForSender ? sortOrderForSender : "",
        sortColumn: tempSenderColumn ? tempSenderColumn : "",
      };
    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/get-referral-senders/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((res) => {
        if (res.data.data) {
          setSenderTotalSize(res.data.record_count);
          setReferrlSender(res.data.data);
          localStorage.setItem("senderOffset", senderOffset);
          setLoading(false);
        } else {
          setReferrlSender([]);
          setLoading(false);
        }
      })
      .catch(function (error) {
        // console.log(error,"error");
        toastr.warning("Error Occurred", "Please try again");
        setLoading(false);
      });
  };

  const TimeFormatter = (cell) => {
    return cell
      ? `${moment(cell).format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)} (UTC)`
      : "--";
  };

  const fetchReferralAssigneeTag = async () => {
    let params;
    setSpinner(true);
    let temp = "";
    if (sortColumnForReferralAssigneeTag === "group_name") {
      temp = "g.name ";
    } else if (sortColumnForReferralAssigneeTag === "appUser_name") {
      temp = "au.name";
    } else {
      temp = sortColumnForReferralAssigneeTag;
    }
    if(searchKeysForReferralAssigneeTag.length>2){
      params = {
        tag_name: referralAssigneeTagName
          ? referralAssigneeTagName.label.toString()
          : "",
        group_name: referralAssigneeTagGroupName
          ? referralAssigneeTagGroupName.label.toString()
          : "",
        appUser_name: referralAssigneeTagAppUser_Name
          ? referralAssigneeTagAppUser_Name.label.toString()
          : "",
        status: statusFilter ? statusFilter.value: "",
        record_limit:"50",
        off_set:"0",
        search_keyword: searchKeysForReferralAssigneeTag
          ? searchKeysForReferralAssigneeTag
          : "",
        sortOrder: sortOrderForReferralAssigneeTag
          ? sortOrderForReferralAssigneeTag
          : "",
        sortColumn: temp ? temp : "",
  
      };
  

    }else {

    params = {
      tag_name: referralAssigneeTagName
        ? referralAssigneeTagName.label.toString()
        : "",
      group_name: referralAssigneeTagGroupName
        ? referralAssigneeTagGroupName.label.toString()
        : "",
      appUser_name: referralAssigneeTagAppUser_Name
        ? referralAssigneeTagAppUser_Name.label.toString()
        : "",
      status: statusFilter ? statusFilter.value: "",
      record_limit: sizePerPageForReferralAssigneeTag
        ? sizePerPageForReferralAssigneeTag.toString()
        : "50",
      off_set: offsetForReferralAssigneeTag
        ? offsetForReferralAssigneeTag.toString()
        : "0",
      search_keyword: searchKeysForReferralAssigneeTag
        ? searchKeysForReferralAssigneeTag
        : "",
      sortOrder: sortOrderForReferralAssigneeTag
        ? sortOrderForReferralAssigneeTag
        : "",
      sortColumn: temp ? temp : "",

    };
  }

    await AxiosInter.post(
      `${API_ENDPOINT}/admin-api/list-all-assignee-tag/`,
      params,
      {
        headers: {
          Authorization: API_TOKEN,
        },
      }
    )
      .then(function (response) {
        setBlankStateMessage(response.data.data.length > 0 ? false : true);
        if (response.data.data) {
          setTotalSizeForReferralAssigneeTag(response.data.record_count);
          setReferralAssigneeTagList(response.data.data);
          localStorage.setItem("offset", offsetForReferralAssigneeTag);
          setSpinner(false);
        } else {
          setReferralAssigneeTagList([]);
          setBlankStateMessage(true);
          setSpinner(false);
        }
      })
      .catch(function (error) {
        // toastr.warning('Error Occurred', 'Please try again');
        setBlankStateMessage(true);
        setSpinner(false);
        setReferralAssigneeTagList([]);

      });
  };
  const exportReferralSenderTableData = () => {
    var temp = "";
    if (sortColumnForSender === "GroupName") {
      temp = "g.name";
    } else if (sortColumnForSender === "GroupNPI") {
      temp = "g.npi";
    } else {
      temp = sortColumnForSender;
    }
    const params = {
      file_name: fileNameForSender ? fileNameForSender : "",
      record_limit: "500",
      offset: offset,
      search_keyword: searchKeysForSender ? searchKeysForSender : "",
      sortOrder: sortOrderForSender ? sortOrderForSender : "",
      sortColumn: temp ? temp : "",
      status: statusFilter ? statusFilter.value : "",
    };
    AxiosInter.post(
      `${API_ENDPOINT}/admin-api/export-referral-sender-data/`,
      params,
      {
        headers: {
          Authorization: API_TOKEN,
        },
        responseType: "blob",
      }
    )
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileNameForSender}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      // .catch((error) => {
      //   toastr.error("Failed", "Something went wrong");
      // });
  };

  const fetchReferralFulfillerData = () => {
    let params;
    setLoading(true);

    let tempFulfillerColumn = "";

    if (sortColumnForFulfiller === "GroupName") {
      tempFulfillerColumn = "g.name";
    } else {
      tempFulfillerColumn = "";
    }

    if (searchKeysForFulfiller.length > 2) {
      params = {
        record_limit: 100,
        off_set: "0",
        search_keyword: searchKeysForFulfiller
          ? searchKeysForFulfiller.toString()
          : "",
        sortOrder: sortOrderForFulfiller ? sortOrderForFulfiller : "",
        sortColumn: tempFulfillerColumn ? tempFulfillerColumn : "",
      };
    } else {
      params = {
        record_limit: fulfillerSizePerPage,
        off_set: fulfillerOffset.toString(),
        search_keyword: searchKeysForFulfiller
          ? searchKeysForFulfiller.toString()
          : "",
        sortOrder: sortOrderForFulfiller ? sortOrderForFulfiller : "",
        sortColumn: tempFulfillerColumn ? tempFulfillerColumn : "",
      };
    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/get-referral-fulfiller/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((res) => {
        if (res.data.data) {
          setFulfillerTotalSize(res.data.record_count);
          setReferrlFulfiller(res.data.data);
          localStorage.setItem("fulfillerOffset", fulfillerOffset);
          setLoading(false);
        } else {
          setReferrlFulfiller([]);
          setLoading(false);
        }
      })
      .catch(function (error) {
        // console.log(error,"error");
        toastr.warning("Error Occurred", "Please try again");
        setLoading(false);
      });
  };

  const exportReferralFulfillerTableData = () => {
    var temp = "";
    if (sortColumnForFulfiller === "GroupName") {
      temp = "g.name";
    } else {
      temp = sortColumnForFulfiller;
    }
    const params = {
      file_name: fileNameForFulfiller ? fileNameForFulfiller : "",
      record_limit: "500",
      offset: offset,
      search_keyword: searchKeysForFulfiller ? searchKeysForFulfiller : "",
      sortOrder: sortOrderForFulfiller ? sortOrderForFulfiller : "",
      sortColumn: temp ? temp : "",
    };
    AxiosInter.post(
      `${API_ENDPOINT}/admin-api/export-referral-fulfiller-data/`,
      params,
      {
        headers: {
          Authorization: API_TOKEN,
        },
        responseType: "blob",
      }
    )
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileNameForFulfiller}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };
  const exportReferralAssigneeTagDatatable = () => {
    const fileName = `Equipo-Referral-Assignee-tag-Export-${moment().format(
      MomentConfig.MOMENT_FORMAT_DATEANDTIME
    )}.csv`;
    let temp = "";
    if (sortColumnForReferralAssigneeTag === "group_name") {
      temp = "g.name ";
    } else if (sortColumnForReferralAssigneeTag === "appUser_name") {
      temp = "au.name";
    } else {
      temp = sortColumnForReferralAssigneeTag;
    }
    const params = {
      tag_name: referralAssigneeTagName ? referralAssigneeTagName.label.toString() : "",
      group_name: referralAssigneeTagGroupName ? referralAssigneeTagGroupName.label.toString() : "",
      appUser_name: referralAssigneeTagAppUser_Name ? referralAssigneeTagAppUser_Name.label.toString() : "",
      status: statusFilter ? statusFilter.value:"",
      record_limit:"",
      off_set:"0",
      search_keyword: searchKeysForReferralAssigneeTag ? searchKeysForReferralAssigneeTag : "",
      sortOrder: sortOrderForReferralAssigneeTag ? sortOrderForReferralAssigneeTag : "",
      sortColumn: temp ? temp : "",
    };
  
    AxiosInter.post(`${API_ENDPOINT}/admin-api/export-referral-assignee-tag/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
      responseType: "blob",
    })
      .then((response) => {
        if (response.status === 200) {
          const suggestedFilename = response.headers['x-suggested-filename'];
          var fileDownload=require("js-file-download");
          fileDownload(response.data, fileName, suggestedFilename);
          toastr.success("Success", "Export successful");
        } else {
          toastr.error("Failed", "Something went wrong");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
        console.error(error);
      });
  };
  

  const fetchReferralSenderDataForDropDown = () => {
    setLoading(true);
    AxiosInter.get(`${API_ENDPOINT}/admin-api/referral-sender-data/`, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((res) => {
        setSenderDropDownList(res.data.data);

        setLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const fetchReferralFulfillerDataForDropDown = () => {
    setLoading(true);
    AxiosInter.get(`${API_ENDPOINT}/admin-api/referral-fulfiller-data/`, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((res) => {
        setFullFillerDropDownList(res.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    // eslint-disable-next-line eqeqeq
    if (cell == "Processed") {
      // eslint-disable-line
      status = "Processed";
      badgeclass = "badge badge-success statustoggle status-button-width";
      // eslint-disable-next-line eqeqeq
    } else if (cell == "Error") {
      // eslint-disable-line
      status = "Error";
      badgeclass = "badge badge-danger statustoggle status-button-width";
      // eslint-disable-next-line eqeqeq
    } else if (cell == "Duplicate") {
      // eslint-disable-line
      status = "Duplicate";
      badgeclass = "badge badge-warning statustoggle status-button-width";
      // eslint-disable-next-line eqeqeq
    } else if (cell == "Queued") {
      // eslint-disable-line
      status = "Queued";
      badgeclass = "badge badge-dark statustoggle status-button-width";
      // eslint-disable-next-line eqeqeq
    } else if (cell == "Pending") {
      // eslint-disable-line
      status = "Pending";
      badgeclass = "badge badge-tertiary statustoggle status-button-width";
    } else {
      status = "No Status";
      badgeclass = "badge-third";
    }

    return (
      <span
        className={badgeclass}
        /* onClick={() => {
        setAdminID(row.id);
        setStatus(cell);
        setModalState(true);
      }} */
      >
        {status}
      </span>
    );
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };

  const statusFormatterForReferralSender = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setReferralReferralSenderID(row.id);
          setReferralSenderStatus(cell);
          setReferralSenderStatusModal(true);
        }}
      >
        {status}
      </span>
    );
  };
  const subTypeFormatterForReferralSender = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === "LITE") {
      status = "Community";
     
    } else if (cell === "FULL") 
    {
      status = "Enterprise";
     
    }
    else 
    {
      status = "Community Plus";
     
    }

    return (
      <span
        className={badgeclass}
        // onClick={() => {
        //   setReferralReferralSenderID(row.id);
        //   setReferralSenderStatus(cell);
        //   setReferralSenderStatusModal(true);
        // }}
      >
        {status}
      </span>
    );
  };
  const statusFormatterForReferralFulfiller = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setReferralReferralFulfillerID(row.id);
          setReferralFulfillerStatus(cell);
          setReferralFulfillerStatusModal(true);
        }}
      >
        {status}
      </span>
    );
  };
  const subTypeFormatterForReferralFullfillerr = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === "LITE") {
      status = "Community";
     
    } else if (cell === "FULL") 
    {
      status = "Enterprise";
     
    }
    else 
    {
      status = "Community Plus";
     
    }

    return (
      <span
        className={badgeclass}
        // onClick={() => {
        //   setReferralReferralSenderID(row.id);
        //   setReferralSenderStatus(cell);
        //   setReferralSenderStatusModal(true);
        // }}
      >
        {status}
      </span>
    );
  };
  const statusFormatterForReferralAssigneeTag = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setReferralAssigneTagID(row.id);
          setReferralAssigneTagStatus(cell);
          setReferralAssigneeStatusModal(true);
        }}
      >
        {status}
      </span>
    );
  };

  const handleTableChangeForFulfiller = (type, newState) => {
    setFulfillerPage(newState.page);
    setFulfillerSizePerPage(newState.sizePerPage);
    setFulfillerOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumnForFulfiller("");
      setSortOrderForFulfiller("");
    } else {
      setSortColumnForFulfiller(newState.sortField);
      setSortOrderForFulfiller(newState.sortOrder);
      setReferralFulfillerReload(referralFulfillerReload + 1);
    }
  };

  const handleTableChangeForSender = (type, newState) => {
    setSenderPage(newState.page);
    setSenderSizePerPage(newState.sizePerPage);
    setSenderOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumnForSender("");
      setSortOrderForSender("");
    } else {
      setSortColumnForSender(newState.sortField);
      setSortOrderForSender(newState.sortOrder);
      setReferralSenderReload(referralSenderReload + 1);
    }
  };

  const tableColumnsForReferralAssigneeTag = [
    {
      dataField: "tag_name",
      text: "Tag Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "group_name",
      text: "Group",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "subscriptionPlanName",
      text: "Subscription",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "appUser_name",
      text: "App User",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: "status",
      text: "Status",
      sort: false,
      formatter: statusFormatterForReferralAssigneeTag,
    },

    {
      dataField: "created_on",
      text: "Created Date",
      formatter: TimeFormatter,
      csvFormatter: TimeFormatter,
    },
    /* {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
    } */
  ];

  const tableColumnsForImportData = [
    {
      dataField: "senderName",
      text: "Sender",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "senderSubscription",
      text: "Sender Subscription",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "fileName",
      text: "File Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: "patientName",
      text: "Patient Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: "fulfillerName",
      text: "Fulfiller",
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "fulfillerSubscription",
      text: "Fulfiller Subscription",
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "importedOn",
      text: "Imported On",
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: statusFormatter,
    },
  ];

  const tableColumnsForDraftData = [
    {
      dataField: "senderGroupName",
      text: "Sender",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "senderSubscriptionName",
      text: "Sender Subscription",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "fulfillerGroupName",
      text: "Fulfiller ",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "fulfillerSubscriptionName",
      text: "Fulfiller Subscription",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "patientName",
      text: "Patient Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "orderSerialNumber",
      text: "Order Serial Number",
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "orderType",
      text: "Order Type",
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "priority",
      text: "Priority",
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "providerName",
      text: "Referring Provider",
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "creatorName",
      text: "Created By",
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
  ];

  const tableColumnsForOrderAgainstRule = [
    {
      dataField: "orderSerialNumber",
      text: "order Serial Number",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "referralAutoProcessRule",
      text: "Rule Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "referralAutoProcessExceptionRules",
      text: "Exception Rule",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "referralAutoProcessExceptionRules1",
      text: "Flow Exception Rule",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
  ];

  const handleTableChangeForOrderAgainstRule = (type, newState) => {
    setPage(newState.page);
    setSizePerPage(newState.sizePerPage);
    setOffset((newState.page - 1) * newState.sizePerPage);
  };

  /* const GenerateValuesForSenderDropDown = () => {

    var SortedSenderDropDownList =senderDropDownList.sort(function(a, b) {

      if (a.senderName < b.senderName) return -1;
      if (a.senderName > b.senderName) return 1;
      return 0;
    });
    
    SortedSenderDropDownList.map((item) => { // eslint-disable-line

      senderDropDown.push({
        value: item.senderID,
        label: item.senderName
      })
    })  

  }
  const GenerateValuesForFulfillerDropDown = () => {

    var SortedFullFillerDropDownList = fullFillerDropDownList.sort(function(a, b) {

      if (a.fulfillerName < b.fulfillerName) return -1;
      if (a.fulfillerName > b.fulfillerName) return 1;
      return 0;
    });

    SortedFullFillerDropDownList.map((item) => { // eslint-disable-line

      fullFillerDropDown.push({
        value: item.fulfillerID,
        label: item.fulfillerName
      })
    })
  } */

  const yesNoValidatation = (cell) => {
    // eslint-disable-next-line eqeqeq
    if (cell == "1") {
      // eslint-disable-line
      return "Yes";
      // eslint-disable-next-line eqeqeq
    } else if (cell == "0") {
      //  eslint-disable-line
      return "No";
    } else {
      return "--";
    }
  };

  const fetchCategoryTypeOptions = () => {
    AxiosInter.get(`${API_ENDPOINT}/admin-api/get-category-type-options/`, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((response) => {
        if (response.status === 200) {

          setCategoryTypeOptions(response.data.data)

        }

      })
      // .catch((error) => {
      //   console.log(error)
      // });
  }
  useEffect(() => {

    if (ReferralSenderEditModal === true) {
      fetchCategoryTypeOptions();

    }
  }, [ReferralSenderEditModal]) // eslint-disable-line

  const validateCategory = (cell) => {
    if (!cell) return "--";
    
    const categories = JSON.parse(cell);
    let result = [];
  
    if (categories) {
      categories.forEach((c) => {
        categoryTypeOptions.forEach((item) => {
          if (c == item.value) {// eslint-disable-line
            result.push(item.label);
            return;
          }
        });
        // result.push('Others');
      });
    }
    
    return result.join(', ');
  };
  

  const statusValidation = (cell) => {
    return cell ? "Active" : "Inactive";
  };

  const actionFormatter = (cell, row) => {
  
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setReferralSenderTabelModal(true);
            setReferralOrderSenderID(row.id);
            setReferralOrderSenderIDGpName(row.GroupName);
          }}
          color="primary"
          title="View Fulfillers"
        >
          <FontAwesomeIcon
            icon={faEye}
            fixedWidth
            className="align-middle"
            title="View Fulfillers"
          />
        </Button>
        <Button
          className="money-button"
          outline
          onClick={() => {
            
            setRowData(row);
            setEditNPI(row.GroupNPI);
            setEditAutoProcess(row.enable_auto_process);
            setEditSubscriptionType(row.subscription_type);
            setEditEnableEmail(row.enable_mail_notification);
            setEditIsClinical(row.is_clinical);
            setEditIsTransport(row.is_transport);
            setEditCategoryType(row.category_type != null ? JSON.parse(row.category_type):"");
            setReferralSenderEditModal(true);
          }}
          color="primary"
          title="Edit Referral Sender"
        >
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="align-middle"
            title="Edit Referral Sender"
          />
        </Button>
      </>
    );
  };

  // useEffect(()=>{
  //   debugger
  //   if (referralFulfillerOrderOptions.length>1){
  //     referralFulfillerOrderOptions.map((item)=>{
  //       console.log(item)
  //     })
  //   }

  // },[referralFulfillerOrderOptions])
  const actionFormatterFulfiller = (cell, roww) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
         
            setReferralFulfillerTabelModal(true);
            setReferralOrderFulfillerID(roww.id);
            setReferralOrderFulfillerGpName(roww.GroupName);
          }}
          color="primary"
          title="View Senders"
        >
          <FontAwesomeIcon
            icon={faEye}
            fixedWidth
            className="align-middle"
            title="View Senders"
          />
        </Button>

        <Button
          className="money-button"
          outline
          onClick={() => {
            
            setReferralFulfillerOrderTypeModal(true);
            setReferralOrderFulfillerID(roww.id);
            let fid = roww.id;

            if (fid) {
              AxiosInter
                .get(
                  `${API_ENDPOINT}/admin-api/fetch-fulfiller-order-type/${fid}`,
                  {
                    headers: {
                      Authorization: API_TOKEN,
                    },
                  }
                )
                .then((response) => {
                  if (response.data.data.length > 0) {
                 
                    SetReferralFulfillerOrderOPtions(response.data.data);
                    // console.log(
                    //   "🚀 ~ file: AllReferral.js ~ line 1156 ~ .then ~ response.data.data",
                    //   typeof response.data.data
                    // );
                  }
                  else{
                    SetReferralFulfillerOrderOPtions([]);
                    // console.log("nkkdsk",response.data)
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }}
          color="primary"
          title="View OrderTypes"
        >
            <FontAwesomeIcon
            icon={faListAlt}
            fixedWidth
            className="align-middle"
            title="order types"
          />
        </Button>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setReferralID(roww.id);
            setName(roww.GroupName);
            setEditAppointmentSMSNote(roww.appointment_sms_note);
            setEditDataExchangeEnabled(roww.appt_data_exchange_enabled);
            setEditSubscription(roww.subscription_type);
            setEditRecapGenerationEnabled(roww.appt_recap_generation_enabled);
            setEditClinical(roww.is_clinical);
            setEditTransport(roww.is_transport);
            setEditCategoryType(roww.category_type != null ? JSON.parse(roww.category_type):"");
            setReferralFulFillerEditModal(true);
          }}
          color="primary"
          title="Edit Referral Fulfiller"
        >
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="align-middle"
            title="Edit Referral Fulfiller"
          />
        </Button>
      </>
    );
  };

  const tableColumnsForSenderData = [
    {
      dataField: "GroupName",
      text: "Group",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "GroupNPI",
      text: "NPI",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: statusFormatterForReferralSender,
      csvFormatter: statusValidation,
    },

    {
      dataField: "enable_auto_process",
      text: "Auto Process Enabled",
      formatter: yesNoValidatation,
      sort: true,
      csvFormatter: yesNoValidatation,
    },
    {
      dataField: "subscription_type",
      text: "Subscription Type",
      formatter: subTypeFormatterForReferralSender,
      sort: true,
    },
    {
      dataField: "enable_mail_notification",
      text: "Enable Mail Notification",
      csvExport: false,
      formatter: yesNoValidatation,
      csvFormatter: yesNoValidatation,
    },
    {
      dataField: "category_type",
      text: "Category",
      csvExport: false,
      formatter: validateCategory,
      csvFormatter: validateCategory,
    },
    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
    },
  ];

  const tableColumnsForFulfillerData = [
    {
      dataField: "GroupName",
      text: "Group",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: statusFormatterForReferralFulfiller,
      csvFormatter: statusValidation,
    },

    {
      dataField: "appt_data_exchange_enabled",
      text: "Appointment SMS Note",
      formatter: yesNoValidatation,
      csvFormatter: yesNoValidatation,
    },
    {
      dataField: "appt_recap_generation_enabled",
      text: "Recap Generation Enabled",
      formatter: yesNoValidatation,
      csvFormatter: yesNoValidatation,
    },
    {
      dataField: "subscription_type",
      text: "Subscription Type",
      formatter: subTypeFormatterForReferralFullfillerr,
    },
    {
      dataField: "category_type",
      text: "Category",
      csvExport: false,
      formatter: validateCategory,
      csvFormatter: validateCategory,
    },
    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatterFulfiller,
    },
  ];

  const getDDDate = async () => {
    setSpinner(true);
    await AxiosInter.get(
      `${API_ENDPOINT}/admin-api/referral-order-sender-fulfiller/`,
      {
        headers: {
          Authorization: API_TOKEN,
        },
      }
    )
      .then((res) => {
        setDropDownDataROS(res.data.data.ros);
        setDropDownDataROF(res.data.data.rof);
        setSpinner(false);
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  };

  const createGroupOptionROS = () => {
    if (dropDownDataROS) {
      setSpinner(true);
      var array1 = dropDownDataROS;
      const options1 = array1.map((item, index) => {
        return {
          value: item.SenderID,
          label: item.senderGroupName,
        };
      });
      setGroupDropDownROS(options1);
      setSpinner(false);
    }
  };
  const createGroupOptionROF = () => {
    if (dropDownDataROF) {
      setSpinner(true);
      var array1 = dropDownDataROF;
      const options1 = array1.map((item, index) => {
        return {
          value: item.FulfillerID,
          label: item.fulfillerGroupNAme,
        };
      });
      setGroupDropDownROF(options1);
      setSpinner(false);
    }
  };

  let loader = null;

  if (loading) {
    loader = <Spinner />;
  }
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleTableChangeForReferralAssigneeTag = (type, newState) => {
    
    setPageForReferralAssigneeTag(newState.page);
    setSizePerPageForReferralAssigneeTag(newState.sizePerPage);
    setOffsetForReferralAssigneeTag((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumnForReferralAssigneeTag("");
      setSortOrderForReferralAssigneeTag("");
    } else {
      setSortColumnForReferralAssigneeTag(newState.sortField);

      setSortOrderForReferralAssigneeTag(newState.sortOrder);
      setReloadForReferralAssigneeTag(reloadForReferralAssigneeTag + 1);
    }
  };

  // DEFAULT API CALL

  useEffect(() => {
    fetchReferralImportLogData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    senderId,
    fulFillerId,
    year,
    month,
    status,
    timeZone,
    orderColumn,
    importlogReload,
  ]); // eslint-disable-line
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (senderDropDownList.length == 0 || activeTab == "1")
      fetchReferralSenderDataForDropDown();
  }, [activeTab]); // eslint-disable-line
  /* useEffect(() => { if(senderDropDownList.length !== 0  ) {

                      setLoading(true)
                      GenerateValuesForSenderDropDown();
                      setLoading(false)
                    }
  }, [senderDropDownList]);// eslint-disable-line */

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (fullFillerDropDownList.length == 0 || activeTab == "1")
      fetchReferralFulfillerDataForDropDown();
  }, [activeTab]); // eslint-disable-line
  /* useEffect(() => { if(fullFillerDropDownList.length > 0 ) GenerateValuesForFulfillerDropDown() }, [fullFillerDropDownList]);// eslint-disable-line */

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (activeTab == "2" && referrlDraftData.length == 0)
      fetchReferralDraftData();
  }, [activeTab]); // eslint-disable-line
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (activeTab == "3" && referrlSender.length == 0)
      fetchReferralSenderData();
  }, [activeTab]); // eslint-disable-line
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (activeTab == "4" && referrlfulfiller.length == 0)
      fetchReferralFulfillerData();
  }, [activeTab]); // eslint-disable-line

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (activeTab == "3") fetchReferralSenderData();
  }, [referralSenderReload]); // eslint-disable-line
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (activeTab == "4") fetchReferralFulfillerData();
  }, [referralFulfillerReload]); // eslint-disable-line
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (activeTab == "2") fetchReferralDraftData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    senderIdDraft,
    fulFillerIdDraft,
    yearDraft,
    monthDraft,
    statusDraft,
    timeZoneDraft,
    orderColumnDraft,
    referralDraftReload,
    draftReload,
  ]); // eslint-disable-line

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (searchDraft.length > 3 || searchDraft == "")
      setDraftReload(draftReload + 1);
  }, [searchDraft]); // eslint-disable-line

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (searchImport.length > 3 || searchImport == "")
      setImportlogReload(importlogReload + 1);
  }, [searchImport]); // eslint-disable-line

  // PAGINATION API CALL FOR REFERRAL SENDER
  useEffect(() => {
    let previousOfset = localStorage.getItem("senderOffset");
    if (
      (senderOffset !== previousOfset || senderSizePerPage) &&
      activeTab === "3"
    ) {
      fetchReferralSenderData();
    }
  }, [senderOffset, senderSizePerPage, activeTab]); // eslint-disable-line

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (searchKeysForSender.length > 3 || searchKeysForSender == "")
      setReferralSenderReload(referralSenderReload + 1);
  }, [searchKeysForSender]); // eslint-disable-line

  // PAGINATION API CALL FOR REFERRAL FULFILLER
  useEffect(() => {
    var previousOffset = localStorage.getItem("fulfillerOffset");
    if (
      (fulfillerOffset !== previousOffset || fulfillerSizePerPage) &&
      activeTab === "4"
    ) {
      fetchReferralFulfillerData();
    }
  }, [fulfillerOffset, fulfillerSizePerPage, activeTab]); // eslint-disable-line
  // PAGINATION API CALL FOR REFERRAL Assignee Data
  useEffect(() => {
    var previousOffset = localStorage.getItem("offsetForReferralAssigneeTag");
    if (
      (offsetForReferralAssigneeTag !== previousOffset || sizePerPageForReferralAssigneeTag) &&
      activeTab === "6"
    ) {
      fetchReferralAssigneeTag();
    }
  }, [offsetForReferralAssigneeTag, sizePerPageForReferralAssigneeTag, activeTab]); // eslint-disable-line
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (searchKeysForFulfiller.length > 3 || searchKeysForFulfiller == "")
      setReferralFulfillerReload(referralFulfillerReload + 1);
  }, [searchKeysForFulfiller]); // eslint-disable-line
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (searchOrderAgainstRule.length > 3 || searchOrderAgainstRule == "")
      setReload(reload + 1);
  }, [searchOrderAgainstRule]); // eslint-disable-line
  // API CALLS FOR ORDER AGAINST RULE
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (activeTab == "5" && data.length == 0) fetchDataForOrderAgainstRule();
  }, [activeTab]); // eslint-disable-line
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (activeTab == "5") fetchDataForOrderAgainstRule();
  }, [reload]); // eslint-disable-line

  // API CALLS FOR ORDER AGAINST RULE
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (activeTab == "6" && referralAssigneeTagList.length == 0)
      fetchReferralAssigneeTag();
  }, [activeTab]); // eslint-disable-line
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (activeTab == "6") fetchReferralAssigneeTag();
  }, [reloadForReferralAssigneeTag]); // eslint-disable-line

  useEffect(() => {
    if (
      searchKeysForReferralAssigneeTag.length > 3 ||
      // eslint-disable-next-line eqeqeq
      searchKeysForReferralAssigneeTag == ""
    )
      setReloadForReferralAssigneeTag(reloadForReferralAssigneeTag + 1);
  }, [searchKeysForReferralAssigneeTag]); // eslint-disable-line

  useEffect(() => {
    if (data) {
      localStorage.setItem("sizePerPage", sizePerPage);
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if ((offset !== previousOffset || sizePerPage) && activeTab === "5") {
      fetchDataForOrderAgainstRule();
    }
  }, [offset, sizePerPage]); // eslint-disable-line

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (orderSerialNumber.length > 3 || orderSerialNumber == "")
      setReload(reload + 1);
  }, [orderSerialNumber]); // eslint-disable-line

  useEffect(() => {
    getDDDate();
  }, [referralFulfillerReload, referralSenderReload]); // eslint-disable-line
  useEffect(() => {
    if (dropDownDataROS) createGroupOptionROS();
  }, [dropDownDataROS]); // eslint-disable-line
  useEffect(() => {
    if (dropDownDataROF) createGroupOptionROF();
  }, [dropDownDataROF]); // eslint-disable-line
  // CheckUserInactivity()
  return (
    <Hux>
      {loader}
      <Container fluid>
        <Header>
          <HeaderTitle>Referral</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Referral</BreadcrumbItem>
          </Breadcrumb>
          <div className="settings-tabs">
            <Nav tabs>
              {tabs.map((tab) => (
                <React.Fragment key={tab.id}>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === tab.id.toString() })}
                      onClick={() => toggle(tab.id.toString())}
                    >
                      <div className="payment-config-tab">{tab.name}</div>
                    </NavLink>
                  </NavItem>
                  <div className="opacity-zero">{"."}</div>
                </React.Fragment>
              ))}
            </Nav>
          </div>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <ReferralImportLogDataTable
                    senderDropDown={senderDropDown}
                    token={props.authtoken}
                    data={referrlImportLog}
                    columns={tableColumnsForImportData}
                    importlogReload={importlogReload}
                    setImportlogReload={setImportlogReload}
                    searchImport={searchImport}
                    setSearchImport={setSearchImport}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <ReferralDraftDataTable
                    token={props.authtoken}
                    data={referrlDraftData}
                    columns={tableColumnsForDraftData}
                    referrlImportLog={referrlImportLog}
                    searchDraft={searchDraft}
                    setSearchDraft={setSearchDraft}
                    draftReload={draftReload}
                    setDraftReload={setDraftReload}
                    fetchReferralDraftData={fetchReferralDraftData}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <ReferralSenderDataTable
                    token={props.authtoken}
                    data={referrlSender}
                    columns={tableColumnsForSenderData}
                    handleTableChangeForSender={(type, newState) => {
                      handleTableChangeForSender(type, newState);
                    }}
                    senderPage={senderPage}
                    senderSizePerPage={senderSizePerPage}
                    totalSize={senderTotalSize ? senderTotalSize : 0}
                    setSenderPage={setSenderPage}
                    setSenderOffset={setSenderOffset}
                    setSenderTotalSize={setSenderTotalSize}
                    setSearchKeysForSender={setSearchKeysForSender}
                    searchKeysForSender={searchKeysForSender}
                    referralSenderReload={referralSenderReload}
                    setReferralSenderReload={setReferralSenderReload}
                    exportReferralSenderTableData={
                      exportReferralSenderTableData
                    }
                    fetchReferralSenderData={fetchReferralSenderData}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col sm="12">
                  <ReferralFulfillerDataTable
                    token={props.authtoken}
                    data={referrlfulfiller}
                    columns={tableColumnsForFulfillerData}
                    handleTableChangeForFulfiller={(type, newState) => {
                      handleTableChangeForFulfiller(type, newState);
                    }}
                    page={fulfillerPage}
                    sizePerPage={fulfillerSizePerPage}
                    totalSize={fulfillerTotalSize ? fulfillerTotalSize : 0}
                    setPage={setFulfillerPage}
                    setOffset={setFulfillerOffset}
                    setTotalSize={setFulfillerTotalSize}
                    searchKeysForFulfiller={searchKeysForFulfiller}
                    setSearchKeysForFulfiller={setSearchKeysForFulfiller}
                    referralFulfillerReload={referralFulfillerReload}
                    setReferralFulfillerReload={setReferralFulfillerReload}
                    exportReferralFulfillerTableData={
                      exportReferralFulfillerTableData
                    }
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="5">
              <Row>
                <Col sm="12">
                  <OrderAgainstRuleDataTable
                    data={data}
                    columns={tableColumnsForOrderAgainstRule}
                    onTableChange={(type, newState) => {
                      handleTableChangeForOrderAgainstRule(type, newState);
                    }}
                    setOrderSerialNumber={setOrderSerialNumber}
                    orderSerialNumber={orderSerialNumber}
                    searchOrderAgainstRule = {searchOrderAgainstRule}
                    SetSearchOrderAgainstRule ={SetSearchOrderAgainstRule}
                    page={page}
                    sizePerPage={sizePerPage}
                    totalSize={totalSize ? totalSize : 0}
                    setReload={setReload}
                    reload={reload}
                    exportDataForOrderAgainstRule={exportDataForOrderAgainstRule}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="6">
              <Row>
                <Col sm="12">
                  <ReferralAssigneeTagDataTable
                    data={referralAssigneeTagList}
                    columns={tableColumnsForReferralAssigneeTag}
                    onTableChange={(type, newState) => {
                      handleTableChangeForReferralAssigneeTag(type, newState);
                    }}
                    page={pageForReferralAssigneeTag}
                    sizePerPage={sizePerPageForReferralAssigneeTag}
                    totalSize={
                      totalSizeForReferralAssigneeTag
                        ? totalSizeForReferralAssigneeTag
                        : 0
                    }
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    setPage={setPageForReferralAssigneeTag}
                    setOffset={setOffsetForReferralAssigneeTag}
                    setTotalSize={setTotalSizeForReferralAssigneeTag}
                    setSearchKeys={setSearchKeysForReferralAssigneeTag}
                    searchKeys={searchKeysForReferralAssigneeTag}
                    reloadForReferralAssigneeTag={reloadForReferralAssigneeTag}
                    setReloadForReferralAssigneeTag={
                      setReloadForReferralAssigneeTag
                    }
                    offset={offsetForReferralAssigneeTag}
                    //exportTableData={exportTableData}

                    referralAssigneeTagGroupName={referralAssigneeTagGroupName}
                    setReferralAssigneeTagGroupName={
                      setReferralAssigneeTagGroupName
                    }
                    referralAssigneeTagName={referralAssigneeTagName}
                    setReferralAssigneeTagName={setReferralAssigneeTagName}
                    referralAssigneeTagAppUser_Name={
                      referralAssigneeTagAppUser_Name
                    }
                    setReferralAssigneeTagAppUser_Name={
                      setReferralAssigneeTagAppUser_Name
                    }
                    exportReferralAssigneeTagDatatable={
                      exportReferralAssigneeTagDatatable
                    }
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="7">
              {" "}
              <ReferralPreferance />
            </TabPane>
            <TabPane tabId="8">
              {" "}
              <AllProviderSupportedInsurances activeTab={activeTab}/>
            </TabPane>
            <TabPane tabId="9">
              {" "}
              <AllFacilitySupportedInsurances activeTab={activeTab}/>
            </TabPane>
            <TabPane tabId="10">
              {" "}
              <SenderAssigneeAllocation  activeTab={activeTab}/>
            </TabPane>
            <TabPane tabId="11">
              {" "}
              <AllRefOrderTypes activeTab={activeTab}/>
            </TabPane>
            <TabPane tabId="12">
              {" "}
              <ReferralCategoryConfiguration activeTab={activeTab}/>
            </TabPane>
            <TabPane tabId="13">
              {" "}
              <AssigneeInsuranceMapping activeTab={activeTab}/>
            </TabPane>
            <TabPane tabId="14">
              {" "}
              <HIMUsers activeTab={activeTab}/>
            </TabPane>
          </TabContent>
        </Header>
        <ReferralSenderTableModal
          ReferralSenderTabelModal={ReferralSenderTabelModal}
          setReferralSenderTabelModal={setReferralSenderTabelModal}
          referralOrderSenderID={referralOrderSenderID}
          referralOrderSenderGpName={referralOrderSenderGpName}
          groupDropDownROF={groupDropDownROF}
        />
        <ReferralFulfillerTableModal
          ReferralFulfillerTabelModal={ReferralFulfillerTabelModal}
          setReferralFulfillerTabelModal={setReferralFulfillerTabelModal}
          referralOrderFulfillerID={referralOrderFulfillerID}
          referralOrderFulfillerGpName={referralOrderFulfillerGpName}
          groupDropDownROS={groupDropDownROS}
        />
        <ReferralFulfillerOrdertypes
          ReferralFulfillerOrderTypeModal={ReferralFulfillerOrderTypeModal}
          setReferralFulfillerOrderTypeModal={
            setReferralFulfillerOrderTypeModal
          }
          referralOrderFulfillerID={referralOrderFulfillerID}
          SetReferralFulfillerOrderOPtions={SetReferralFulfillerOrderOPtions}
          referralFulfillerOrderOptions={referralFulfillerOrderOptions}
        />
        <EditReferralSenderModal
          ReferralSenderEditModal={ReferralSenderEditModal}
          setReferralSenderEditModal={setReferralSenderEditModal}
          rowData={rowData}
          setEditNPI={setEditNPI}
          editNPI={editNPI}
          editAutoProcess={editAutoProcess}
          setEditAutoProcess={setEditAutoProcess}
          editSubscriptionType={editSubscriptionType}
          setEditSubscriptionType={setEditSubscriptionType}
          editEnableEmail={editEnableEmail}
          setEditEnableEmail={setEditEnableEmail}
          editIsClinical={editIsClinical}
          setEditIsClinical={setEditIsClinical}
          editIsTransport={editIsTransport}
          setEditIsTransport={setEditIsTransport}
          referralSenderReload={referralSenderReload}
          setReferralSenderReload={setReferralSenderReload}
          editCategoryType={editCategoryType}
        />
        <EditReferralFulfillerModal
          ReferralFulfillerEditModal={ReferralFulfillerEditModal}
          setReferralFulFillerEditModal={setReferralFulFillerEditModal}
          editAppointmentSMSNote={editAppointmentSMSNote}
          editReferralID={editReferralID}
          editDataExchangeEnabled={editDataExchangeEnabled}
          editSubscription={editSubscription}
          editRecapGenerationEnabled={editRecapGenerationEnabled}
          editClinical={editClinical}
          editTransport={editTransport}
          editName={editName}
          referralFulfillerReload={referralFulfillerReload}
          setReferralFulfillerReload={setReferralFulfillerReload}
          editCategoryType={editCategoryType}
        />

        <ReferralSenderStatusChangeModal
          ReferralSenderStatusModal={ReferralSenderStatusModal}
          setReferralSenderStatusModal={setReferralSenderStatusModal}
          referralReferralSenderID={referralReferralSenderID}
          referralSenderStatus={referralSenderStatus}
          setReferralSenderStatus={setReferralSenderStatus}
          referralSenderReload={referralSenderReload}
          setReferralSenderReload={setReferralSenderReload}
        />

        <ReferralFulfillerStatusChangeModal
          ReferralFulfillerStatusModal={ReferralFulfillerStatusModal}
          setReferralFulfillerStatusModal={setReferralFulfillerStatusModal}
          referralReferralFulfillerID={referralReferralFulfillerID}
          referralFulfillerStatus={referralFulfillerStatus}
          referralFulfillerReload={referralFulfillerReload}
          setReferralFulfillerReload={setReferralFulfillerReload}
        />
        <ReferralAssigneStatusChangeModal
          ReferralAssigneeStatusModal={ReferralAssigneeStatusModal}
          setReferralAssigneeStatusModal={setReferralAssigneeStatusModal}
          referralAssigneTagID={referralAssigneTagID}
          referralAssigneTagStatus={referralAssigneTagStatus}
          reloadForReferralAssigneeTag={reloadForReferralAssigneeTag}
          setReloadForReferralAssigneeTag={setReloadForReferralAssigneeTag}
        />
      </Container>
    </Hux>
  );
};


export default Tables;
