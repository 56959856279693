import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Container,
  Button,
  Row,
  Col,
  CardBody,
  Spinner
} from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Link } from "react-router-dom";
import { Hux } from "../../hoc/Hux";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import { toastr } from 'react-redux-toastr';
import Cookie from 'js-cookie';
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // Import icons
import AxiosInter from './../../AxiosInter.js';
import moment from "moment"; // Import moment for date formatting

const HealthCheck = () => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastCheckTime, setLastCheckTime] = useState(null); // Add a state for storing the last check time

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");

  // Fetch API URLs
  const fetchApiUrls = async () => {
    setLoading(true); 
    try {
      const response = await AxiosInter.get(`${API_ENDPOINT}/admin-api/get-service-heath-url/`, {
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
        },
      });
      // Map the response data to the desired format
      const apiResponseData = response.data.data[0]; // Get the first object from 'data' array
      let apiUrls = Object.keys(apiResponseData).map((key, index) => ({
        id: index + 1,
        api: key,  // Service name from the config table
        status: "Loading...",
        url: apiResponseData[key] // API URL is the value
      }));
      
      // Sort API URLs alphabetically based on the 'api' field
      apiUrls = apiUrls.sort((a, b) => a.api.localeCompare(b.api));
      
      // Set the data with the sorted API URLs
      setData(apiUrls);
      
      // Call all APIs on the first load
      callAllApis(apiUrls);
    } catch (error) {
      toastr.error('Error fetching API URLs', error.message);
    } finally {
      setLoading(false); 
    }
  };
  
  // Fetch API status for a specific API
  const fetchApiStatus = async (url, rowId, apiName, showToastr = true) => {
    // Set the status to "Loading..." for the clicked row
    setData(prevData =>
      prevData.map(row =>
        row.id === rowId ? { ...row, status: "Loading..." } : row
      )
    );

    try {
      const response = await axios.get(url);
      console.log('response', response)
      const status = response.status === 200 ? `Success (Status: ${response.status})` : `Failed (Status: ${response.status})`;

      setData(prevData =>
        prevData.map(row =>
          row.id === rowId ? { ...row, status } : row
        )
      );

      // Only show toastr if it's not part of 'Check All'
      if (response.status === 200 && !isFirstLoad && showToastr) {
        toastr.success(`${apiName} is Successful`);
      }

    } catch (error) {
      console.log('error', error)
      const statusCode = error?.response?.status;
      const status = `Health Check Failed${statusCode ? ` (Status: ${statusCode})` : ""}`;
      setData(prevData =>
        prevData.map(row =>
          row.id === rowId ? { ...row, status } : row
        )
      );

      // Only show toastr if it's not part of 'Check All'
      if (!isFirstLoad && showToastr) {
        toastr.error(`${apiName} has failed`);
      }
    }
  };

  // Fetch all API statuses on the first load
  useEffect(() => {
    fetchApiUrls();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

 // Call all APIs on "Check All" and update the time
  const callAllApis = (apiUrls = data) => {
    // Call all APIs
    apiUrls.forEach((item) => {
      fetchApiStatus(item.url, item.id, item.api, false); // Pass 'false' to disable toastr for 'Check All'
    });
    setIsFirstLoad(false);

    // Update the last check time
    setLastCheckTime(moment().format("DD MMM YYYY hh:mm A"));
  };

  // Modify the actionFormatter to pass api name as a parameter
  const actionFormatter = (cell, row) => (
    <Button
      className="money-button"
      outline
      color="primary"
      title={row.url && row.url.trim() ? "Check status" : "URL not found"}
      onClick={() => fetchApiStatus(row.url, row.id, row.api)}
      disabled={!row.url || !row.url.trim()} // Disable button if row.url is empty, null, or undefined
    >
      Check
    </Button>
  ); 

  // Formatter for the status column with icons
  const statusFormatter = (cell, row) => {
    const isLoading = cell === "Loading...";
    const isSuccess = cell.includes("Success");
    
    return (
      <span>
        {cell}{" "}
        {!isLoading && ( // Don't show icons while loading
          isSuccess ? (
            <FaCheckCircle style={{ color: "green", marginLeft: "10px" }} />
          ) : (
            <FaTimesCircle style={{ color: "red", marginLeft: "10px" }} />
          )
        )}
      </span>
    );
  };

  // Table columns
  const tableColumns = [
    {
      dataField: "api",
      text: "API"
    },
    {
      dataField: "status",
      text: "Status",
      formatter: statusFormatter
    },
    {
      dataField: "action",
      text: (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        Action
          {lastCheckTime && (
            <span style={{ fontSize: "0.75rem", marginBottom: "0px", fontWeight:'normal' }}>
              Last Check: {lastCheckTime} {/* Show the last check time */}
            </span>
          )}
          <Button
            outline
            color="primary"
            size="md"
            style={{ marginTop: "2px" }} // Add margin to the button for spacing
            onClick={() => callAllApis()}
          >
            Check All
          </Button>
      </div>
      
      ),
      formatter: actionFormatter
    }
  ];

  return (
    <div>
      <Hux>
        <Container fluid>
          <Header>
            <HeaderTitle>Health Check</HeaderTitle>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/dashboard">Dashboard</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Health Check</BreadcrumbItem>
            </Breadcrumb>
          </Header>
        </Container>
      </Hux>
      <Row>
        <Col xs={12}>
          <Card className="roles-list-card">
            <CardBody>
            {loading ? ( // Conditionally render the spinner
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : (
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={data}
                  className="table"
                  columns={tableColumns}
                  bordered={false}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HealthCheck;
