import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { Container, Breadcrumb, BreadcrumbItem, Spinner, Button } from "reactstrap"; // eslint-disable-line
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "../../hoc/Hux";
import ProviderSpecialityRankingDataTable from "./ProviderSpecialityRankingDataTable";
import { GlobalState } from '../../contextAPI/Context'
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewProviderSpecialityRankingModal from '../../newComponents/utilities/NewProviderSpecialityRankingModal';
import EditProviderSpecialityRankingModal from '../../newComponents/utilities/EditProviderSpecialityRankingModal';
import StatusChangeModalProviderSpecialityRank from '../../newComponents/utilities/StatusChangeModalProviderSpecialityRank';
import RemovePhyByFacilityModalProviderSpecialityRank from '../../newComponents/utilities/RemovePhyByFacilityModalProviderSpecialityRank';
import DeleteModalProviderSpecialityRank from '../../newComponents/utilities/DeleteModalProviderSpecialityRank';
import moment from "moment";
import { toastr } from 'react-redux-toastr';
import MomentConfig from "../../common/MomentConfig";


const AllProviderSpecialityRanking = () => {
  // CONTEXT VARIABLES 
  const {
    setBlankStateMessage,
    senderGroup,
    fulfillerGroup,
    fulfillerFacility,
    orderType,
    provider,
    psrStatus,
    insuranceType,
    setInsuranceType,
    selectedInsuranceType,
  } = useContext(GlobalState);

  // LOCAL VARIABLES
  const [data, setData] = useState([]);
  const fileExportName = 'Provider-Speciality-Ranking-Table'
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
  const [offset, setOffset] = useState("0");
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [rowData, setRowData] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [senderForEdit, setSenderForEdit] = useState("");
  const [fulfillerForEdit, setFulfillerForEdit] = useState("");
  const [facilityForEdit, setFacilityForEdit] = useState("");
  const [orderTypeForEdit, setOrderTypeForEdit] = useState("");
  const [providerForEdit, setProviderForEdit] = useState("");
  // const [insuranceType, setInsuranceType] = useState();
  const [rankingForEdit, setRankingForEdit] = useState("");
  const [id, setID] = useState("");
  const [idForStatus, setIDForStatus] = useState("");
  const [status, setStatus] = useState("");
  const [statusChangeModal, setStatusChangeModal] = useState("");
  const [removePhysicianByFacilityModal, setRemovePhysicianByFacilityModal] = useState(false);

  // removing facility for given provider variables
  const [providerID, setProviderID] = useState("");
  const [providerNameID, setProviderNameID] = useState("");
  const [facilityID, setFacilityID] = useState("");
  const [facilityNameID, setFacilityNameID] = useState("");
  const [fulfillerID, setFulfillerID] = useState("");
  const [fulfillerNameID, setFulfillerNameID] = useState("");
  const [idForDelete, setIdForDelete] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editStatus, setEditStatus] = useState(""); // eslint-disable-line
  const [editNotificationMail, setEditNotificationMail] = useState(""); // eslint-disable-line
  const [editNotificationFax, setEditNotificationFax] = useState(""); // eslint-disable-line
  const [editContactNumber, setEditContactNumber] = useState(""); // eslint-disable-line
  const [editVersion, setEditVersion] = useState(""); // eslint-disable-line
  const [editInsuranceType,setEditInsuranceType] = useState("");
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");
  const [reload, setReload] = useState(0);  // eslint-disable-line 
  const [search, setSearch] = useState("");  // eslint-disable-line 
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");


  const RankingOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
  ]
  const fetchDataForProviderSpecialityRanking = async () => {
    setSpinner(true)
    const params =
    {
      "sender_grp_id": senderGroup ? senderGroup.value.toString() : "",
      "fulfiller_grp_id": fulfillerGroup ? fulfillerGroup.value.toString() : "",
      "fulfiller_facility_id": fulfillerFacility ? fulfillerFacility.value.toString() : "",
      "order_type_id": orderType ? orderType.value.toString() : "",
      "fulfiller_physician_id": provider ? provider.value.toString() : "",
      "record_limit": sizePerPage ? sizePerPage.toString() : "25",
      "offset": offset ? offset.toString() : "0",
      "insurance_type": selectedInsuranceType ? selectedInsuranceType.value: "",
      "search_keyword": search ? search.toString() : "",
      "sortOrder": sortOrder ? sortOrder : "ASC",
      "sortColumn": sortColumn ? sortColumn : "id",
      "status": psrStatus ? psrStatus.value : ''
    }

    await AxiosInter.post(`${API_ENDPOINT}/admin-api/provider-speciality-ranking/`, params, {
      headers: {
        Authorization: API_TOKEN,
      }
    })
      .then((response) => {
        setSpinner(true)
        setBlankStateMessage(response.data.data.length > 0 ? false : true);
        if (response.data.data) {
          setTotalSize(response.data.record_count)
          setData(response.data.data);
          localStorage.setItem('offset', offset);
          localStorage.removeItem("subscriptionFilter");
          localStorage.removeItem("groupNameFilter");
          localStorage.removeItem("statusFilter");
          localStorage.removeItem("adminNameFilter");
          localStorage.removeItem("zipCodeFilter");
          localStorage.removeItem("timeZoneFilter");
        } else {
          setData([]);
          setBlankStateMessage(true);
        }
        setSpinner(false)
      })
    // .catch((error) => {
    //   setBlankStateMessage(true);
    //   setSpinner(false)
    //   toastr.warning('Error Occurred', 'Please try again');
    // });

  }

  const exportTableData = () => {

    const params =
    {
      "sender_grp_id": senderGroup ? senderGroup.value.toString() : "",
      "fulfiller_grp_id": fulfillerGroup ? fulfillerGroup.value.toString() : "",
      "fulfiller_facility_id": fulfillerFacility ? fulfillerFacility.value.toString() : "",
      "order_type_id": orderType ? orderType.value.toString() : "",
      "fulfiller_physician_id": provider ? provider.value.toString() : "",
      "record_limit": "",
      "offset": "",
      "search_keyword": search ? search.toString() : "",
      "sortOrder": sortOrder ? sortOrder : "ASC",
      "sortColumn": sortColumn ? sortColumn : "id",
      "status": psrStatus ? psrStatus.value : '',
      "file_name": fileName ? fileName : "provider-speciality-ranking-export",
      "insurance_type": selectedInsuranceType ? selectedInsuranceType.value: "",
    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/export-provider-speciality-ranking-table-data/`, params, {
      headers: {

        Authorization: API_TOKEN,

      },
      responseType: "blob"
    })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data, `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success(
            "Success",
            "Export successful"
          );

        }

      })
      .catch((error) => {

        toastr.error(
          "Failed",
          "Something went wrong"
        );
      });
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }


  const editProviderSpeciality = (row) => {

    if (row.network_rank) {
      var array = RankingOptions;
      const opt = array.map((item) => { // eslint-disable-line

        if (row.network_rank.toString() === item.value) {
          setRankingForEdit({
            value: (row ? row.network_rank : 'N/A').toString(),
            label: (row ? row.network_rank : 'N/A').toString()
          })
        }
      });
    }

    setRowData(row)
    setID(row.id)
    setEditModal(true)

    if (row.senderGroupID && row.senderID) {
      setSenderForEdit({
        value: (row ? row.senderID + "/" + row.senderGroupID : 'N/A').toString(),
        label: (row ? row.senderGroupName : 'N/A').toString()
      })
    }
    if (row.fulfillerGroupID) {
      setFulfillerForEdit({
        value: (row ? row.fulfillerID + "/" + row.fulfillerGroupID : 'N/A').toString(),
        label: (row ? row.fulfillerGroupName : 'N/A').toString()
      })
    }
    if (row.facilityID) {
      setFacilityForEdit({
        value: (row ? row.facilityID : 'N/A').toString(),
        label: (row ? row.facilityName : 'N/A').toString()
      })
    }
    setOrderTypeForEdit({
      value: (row ? row.orderTypeID : 'N/A').toString(),
      label: (row ? row.orderTypeName : 'N/A').toString()
    })
    setEditStatus(row.status ? row.status : 0)
    if (row.providerID) {
      setProviderForEdit({
        value: (row ? row.providerID : 'N/A').toString(),
        label: (row ? row.providerName : 'N/A').toString()
      })
    }

    setEditNotificationMail(row.notificationEmail ? row.notificationEmail : "")
    setEditNotificationFax(row.notificationFax ? row.notificationFax : "")

    setEditContactNumber(row.contact_number ? row.contact_number : "")
    setEditVersion(row.versionvalue ? { value: row.versionvalue, label: row.version } : { value: "O", label: "Hierarchy" })
    setEditInsuranceType(row.insuranceType ? { value: row.insuranceType, label: row.insuranceType } : { value: "O", label: "select an insurance type" })

  }

  const actionFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            editProviderSpeciality(row)

          }}
          color="primary"
          title="Edit Record"
        >
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="align-middle"
            title="Edit Record"
          />
        </Button>{" "}

        <Button
          className="delete-btn"
          outline
          onClick={() => {

            setDeleteModal(true)
            setIdForDelete(row.id)
          }}
          color="primary"
          title="Delete Record"
        >
          <FontAwesomeIcon
            icon={faTrash}
            fixedWidth
            className="align-middle"
            title="Delete Record"
          />
        </Button>

      </>
    )
  }

  const statusFormatter = (cell, row) => {

    let status = "";
    let badgeclass = " ";
    let name = ''
    let removeFacilitybtn = "";
    if (row.facilityName !== null) {
      name = 'Remove Provider By ' + row.facilityName;
    }
    else {
      name = 'Remove Provider By --';
    }
    if (row.status === 0) {
      removeFacilitybtn = "btn btn-secondary mt-1 btn-sm rounded statustoggle status-button-width";
    } else if ([1, 97, 98, 99].includes(row.status)) {
      removeFacilitybtn = "status-none";
    }

    if (cell === 1) {
      status = "Active";
      badgeclass = "btn btn-success btn-sm rounded statustoggle status-button-width";
    }
    else if (cell === 0) {
      status = "Inactive";
      badgeclass = "btn btn-danger btn-sm rounded statustoggle status-button-width";
    }
    else if (cell === 97) {
      status = "Out of Network";
      badgeclass = "btn btn-info btn-sm rounded statustoggle status-button-width";
    }
    else if (cell === 98) {
      status = "Max Threshold";
      badgeclass = "btn btn-warning btn-sm rounded statustoggle status-button-width";
    }
    else if (cell === 99) {
      status = "Out of Payor Network";
      badgeclass = "btn btn-primary btn-sm rounded statustoggle status-button-width";
    }


    return (
      <div>

        <span
          className={badgeclass}
          onClick={() => {
            setIDForStatus(row.id);
            setStatus(cell);
            setStatusChangeModal(true);
          }}
        >
          {status}
        </span>
        <span
          className={removeFacilitybtn}
          onClick={() => {
            setProviderID(row.providerID)
            setProviderNameID(row.providerName)
            setFacilityID(row.facilityID)
            setFacilityNameID(row.facilityName)
            setFulfillerID(row.fulfillerGroupID)
            setFulfillerNameID(row.fulfillerGroupName)
            setRemovePhysicianByFacilityModal(true)
          }}
        >
          {name}
        </span>

      </div>
    );
  };

  const statusFormatterCSV = (cell) => {
    return cell ? "Active" : "Inactive"
  }

  const dobFormatter = (cell) => {
    return cell
      ? moment(cell).format("DD-MMM-YYYY")
      : "N/A";
  };

  const tableColumns = [
    {
      dataField: "senderGroupName",
      text: "Sender Group",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
    },
    {
      dataField: "senderSubscriptionPlan",
      text: "Sender Subscription",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
    },
    {
      dataField: "fulfillerGroupName",
      text: "Fulfiller Group",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
    },
    {
      dataField: "fulfillerSubscriptionPlan",
      text: "Fulfiller Subscription",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
    },

    {
      dataField: "facilityName",
      text: "Facility",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '150px' },
      style: { width: '150px' },
    },
    {
      dataField: "orderTypeName",
      text: "Order Type",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
    },
    {
      dataField: "providerName",
      text: "Provider",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
    },
    {
      dataField: "npi",
      text: "NPI",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
    },
    {
      dataField: "insuranceType",
      text: "Insurance Type",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
     },
    {
      dataField: "network_rank",
      text: "Network Ranking",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
    },
    {
      dataField: "last_modified_on",
      text: "Last Modified",
      sort: true,
      formatter: dobFormatter,
      csvFormatter: basicValidation,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: statusFormatter,
      csvFormatter: statusFormatterCSV,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
    },
    {
      dataField: "notificationEmail",
      text: "Notification Email",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
    }, 
    {
      dataField: "notificationFax",
      text: "Notification Fax",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
    }, 
    {
      dataField: "contact_number",
      text: "Contact Number",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
    },
    {
      dataField: "version",
      text: "Version",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
    },
    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
      headerStyle: { width: '125px' },
      style: { width: '125px' },
    },
  ];

  const handleTableChange = (type, newState) => {
    setPage(newState.page)
    setSizePerPage(newState.sizePerPage)
    setOffset((newState.page - 1) * newState.sizePerPage)

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("senderGroupName")
      setSortOrder("ASC")
    }
    else {
      setSortColumn(newState.sortField)
      setSortOrder(newState.sortOrder)
      setReload(reload + 1)
    }
  }



  // DEFAULT API CALL
  useEffect(() => {
    if (reload > 0) {

      fetchDataForProviderSpecialityRanking();
      localStorage.setItem('offset', "0");
    }
  }, [reload]); // eslint-disable-line

  useEffect(() => {
    if (data) { localStorage.setItem('sizePerPage', sizePerPage) }
  }, [data]) // eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem('offset')

    if (offset !== previousOffset) {

      fetchDataForProviderSpecialityRanking();
    }
  }, [offset]) // eslint-disable-line


  useEffect(() => { if (search.length > 3 || search == "") setReload(reload + 1) }, [search]); // eslint-disable-line
  // CheckUserInactivity()

  return (
    <Hux>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Provider Speciality Ranking
            <Button
              className="Add-btn pull-right"
              onClick={() => {
                setAddModal(true);
              }}
            >
              Add Provider Specialty Ranking
            </Button>
          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Speciality Ranking</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        {spinner ? <Spinner /> : ""}
          <ProviderSpecialityRankingDataTable
            className="table"
            data={data}
            columns={tableColumns}
            onTableChange={(type, newState) => {
              handleTableChange(type, newState);
            }}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalSize ? totalSize : 0}
            setReload={setReload}
            reload={reload}
            search={search}
            setSearch={setSearch}
            offset={offset}
            setOffset={setOffset}
            exportTableData={exportTableData}
            spinner={spinner}
          />
        <NewProviderSpecialityRankingModal
          reload={reload}
          setReload={setReload}
          addModal={addModal}
          setAddModal={setAddModal}
          insuranceType={insuranceType}
          setInsuranceType={setInsuranceType}
          // handleChangeForInsuranceType={handleChangeForInsuranceType}
        />
        <EditProviderSpecialityRankingModal
          reload={reload}
          setReload={setReload}
          editModal={editModal}
          setEditModal={setEditModal}
          rowData={rowData}
          senderForEdit={senderForEdit}
          setSenderForEdit={setSenderForEdit}
          fulfillerForEdit={fulfillerForEdit}
          setFulfillerForEdit={setFulfillerForEdit}
          facilityForEdit={facilityForEdit}
          setFacilityForEdit={setFacilityForEdit}
          orderTypeForEdit={orderTypeForEdit}
          setOrderTypeForEdit={setOrderTypeForEdit}
          providerForEdit={providerForEdit}
          setProviderForEdit={setProviderForEdit}
          rankingForEdit={rankingForEdit}
          setRankingForEdit={setRankingForEdit}
          id={id}
          editStatus={editStatus}
          setEditStatus={setEditStatus}
          setEditNotificationMail={setEditNotificationMail}
          editNotificationMail={editNotificationMail}
          editNotificationFax={editNotificationFax}
          setEditNotificationFax={setEditNotificationFax}
          editContactNumber={editContactNumber}
          setEditContactNumber={setEditContactNumber}
          setEditVersion={setEditVersion}
          editVersion={editVersion}
          setEditInsuranceType={setEditInsuranceType}
          editInsuranceType={editInsuranceType}
          insuranceType={insuranceType}
          setInsuranceType={setInsuranceType}
        />
        <StatusChangeModalProviderSpecialityRank
          status={status}
          setStatus={setStatus}
          id={idForStatus}
          statusChangeModal={statusChangeModal}
          setStatusChangeModal={setStatusChangeModal}
          reload={reload}
          setReload={setReload}
        />
        <RemovePhyByFacilityModalProviderSpecialityRank
          providerID={providerID}
          providerNameID={providerNameID}
          facilityID={facilityID}
          facilityNameID={facilityNameID}
          fulfillerID={fulfillerID}
          fulfillerNameID={fulfillerNameID}
          removePhysicianByFacilityModal={removePhysicianByFacilityModal}
          setRemovePhysicianByFacilityModal={setRemovePhysicianByFacilityModal}
          reload={reload}
          setReload={setReload}
        />
        <DeleteModalProviderSpecialityRank
          id={idForDelete}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          reload={reload}
          setReload={setReload}
        />
      </Container>
    </Hux>
  );
};


export default AllProviderSpecialityRanking;
