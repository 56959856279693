import FacilityAssignationDataTable from "./FacilityAssingationDataTable";
import React, { useState, useEffect, useContext } from "react";
import { GlobalState } from "../../contextAPI/Context";
import {Card,Row,Col } from "reactstrap";
import Cookie from 'js-cookie';
import AxiosInter from './../../AxiosInter.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import FacilityAssignationEditModal from "../../newComponents/utilities/FacilityAssignationEditModal";


const SenderAssigneeAllocation = (props) => {

  const activeTab = props.activeTab

    const {
    
        setBlankStateMessage,
        setSpinner,
    } = useContext(GlobalState);

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    const [facilityAssignationTagList, setFacilityAssignationTagList] = useState([]);
    const [facilityAssignationTagGroupName, setFacilityAssignationTagGroupName] =useState("");
    const [facilityAssignationTagFacilityName, setFacilityAssignationTagFacilityName]= useState("");
    const [facilityAssignationTagAppUser_Name, setFacilityAssignationTagAppUser_Name]=useState("");
    const [facilityAssignationTagTableOrderCount, setFacilityAssignationTagTableOrderCount]=useState("")  
    const [offsetForFacilityAssignationTag, setOffsetForFacilityAssignationTag]=useState(0);
    const [pageForFacilityAssignationTag, setPageForFacilityAssignationTag]=useState(1);
    const [totalSizeForFacilityAssignationTag, setTotalSizeForFacilityAssignationTag]=useState(0);
    const [sizePerPageForFacilityAssignationTag,setSizePerPageForFacilityAssignationTag]=useState(50);
    const [searchKeyword,setSearchKeyword] = useState("");
    const [sortColumnForFacilityAssignationTag,setSortColumnForFacilityAssignationTag]= useState("");
    const [sortOrderForFacilityAssignationTag, setSortOrderForFacilityAssignationTag]=useState("");
    const [reloadForFacilityAssignationTag, setReloadForFacilityAssignationTag]=useState(0);
    const [editFacilityAssignationEditModal,setEditFacilityAssignationEditModal] =useState(false);
    const [editFacilityAssignationTagGroupName, setEditFacilityAssignationTagGroupName]=useState({})
    const [editFacilityAssignationTagFacilityName, setEditFacilityAssignationTagFacilityName]=useState("")
    const [editFacilityAssignationTagAppUser_Name, setEditFacilityAssignationTagAppUser_Name]=useState("")
    const [editFacilityAssignationTagTableOrderCount, setEditFacilityAssignationTagTableOrderCount]=useState("")
    const [row, setRow]=useState("")

    const fetchFacilityAssignationTag = async () => {

      const params = {
        groupName: facilityAssignationTagGroupName? facilityAssignationTagGroupName.label.toString(): "",
        FacilityName: facilityAssignationTagFacilityName? facilityAssignationTagFacilityName.label.toString(): "",
        UserName: facilityAssignationTagAppUser_Name? facilityAssignationTagAppUser_Name.label.toString(): "",
        OrderNumber:"",
        record_limit: sizePerPageForFacilityAssignationTag? sizePerPageForFacilityAssignationTag.toString(): "50",
        OFFSET: offsetForFacilityAssignationTag? offsetForFacilityAssignationTag.toString(): "0",
        searchKeyword: searchKeyword? searchKeyword: "",
        sortOrder: sortOrderForFacilityAssignationTag? sortOrderForFacilityAssignationTag: "desc",
        sortColumn:  sortColumnForFacilityAssignationTag? sortColumnForFacilityAssignationTag: "",
        "is_pagination":"1"
      };
    await AxiosInter.post(`${API_ENDPOINT}/admin-api/sender-assignee-allocation-list/`, params,
      {
        headers: {
          Authorization: API_TOKEN,
        },
      }
    )
      .then(function (response) {
        setBlankStateMessage(response.data.data.length > 0 ? false : true);
        if (response.data.data) {
          setTotalSizeForFacilityAssignationTag(response.data.record_count);
          setFacilityAssignationTagList(response.data.data);
          localStorage.setItem("offset", offsetForFacilityAssignationTag);
          setSpinner(false);
        } else {
          setFacilityAssignationTagList([]);
          setBlankStateMessage(true);
          setSpinner(false);
        }
      })
      .catch(function (error) {
        setBlankStateMessage(true);
        setSpinner(false);
      });
   };

  
  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };

  const tableColumnsForFacilityAssignationTag = [
    {
      dataField: "group_name",
      text: "Group Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "subscriptionPlanName",
      text: "Subscriptions",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "facilityName",
      text: "Facility Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "app_username",
      text: "Username",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "total_count_per_day",
      text: "Total Order Count",
      formatter: basicValidation,
      csvFormatter: basicValidation,
      sort: true,
    },
    {
      text: "Actions",
      csvExport: false,
      formatter: (cell, row) => {
        return (
          <>
            <Row>
              <Col sm={2}>
                <FontAwesomeIcon
                  icon={faEdit}
                  fixedWidth
                  size={"1x"}
                  color={"#3f86fa"}
                  style={{ cursor: "pointer" }}
                  className="align-middle"
                  title="Edit"
                  onClick={() => {
                    setEditFacilityAssignationEditModal(true);
                    setEditFacilityAssignationTagGroupName({
                      value: row.group_id,
                      label: row.group_name,
                    });
                    setEditFacilityAssignationTagFacilityName({
                      value: row.facility_id,
                      label: row.facilityName,
                    });
                    setEditFacilityAssignationTagAppUser_Name({
                      value: row.sender_assignee_id,
                      label: row.app_username,
                    });
                    setEditFacilityAssignationTagTableOrderCount(
                      row.total_count_per_day
                    );
                    setRow(row.id);
                  }}
                />
              </Col>
            </Row>
          </>
        );
      },
    },
  ];
  
  
  useEffect(()=>{
    if(reloadForFacilityAssignationTag){
      fetchFacilityAssignationTag();
    }
  },[reloadForFacilityAssignationTag]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    // eslint-disable-next-line eqeqeq
    if(activeTab == 10){
      fetchFacilityAssignationTag();
    }
  },[activeTab]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableChangeForFacilityAssignationTag = (type, newState) => {
    setPageForFacilityAssignationTag(newState.page);
    setSizePerPageForFacilityAssignationTag(newState.sizePerPage);
    setOffsetForFacilityAssignationTag((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumnForFacilityAssignationTag("");
      setSortOrderForFacilityAssignationTag("");
    } 
    if (newState.sortField === "group_name") {
      setSortColumnForFacilityAssignationTag("g.name");
      setSortOrderForFacilityAssignationTag(newState.sortOrder);
      setReloadForFacilityAssignationTag(reloadForFacilityAssignationTag + 1);
    } else if (newState.sortField ==="facilityName") {
      setSortColumnForFacilityAssignationTag("f.name");
      setSortOrderForFacilityAssignationTag(newState.sortOrder);
      setReloadForFacilityAssignationTag(reloadForFacilityAssignationTag + 1);
    } else if (newState.sortField === "app_username") {
      setSortColumnForFacilityAssignationTag("au.name");
      setSortOrderForFacilityAssignationTag(newState.sortOrder);
      setReloadForFacilityAssignationTag(reloadForFacilityAssignationTag + 1);

    } else if (newState.sortField === "total_count_per_day") {
      setSortColumnForFacilityAssignationTag("rsar.total_count_per_day");
      setSortOrderForFacilityAssignationTag(newState.sortOrder);
      setReloadForFacilityAssignationTag(reloadForFacilityAssignationTag + 1);

    } else {
      setSortColumnForFacilityAssignationTag(newState.sortField);
      setSortOrderForFacilityAssignationTag(newState.sortOrder);
      setReloadForFacilityAssignationTag(reloadForFacilityAssignationTag + 1);
    }
  };



  return(
    <div>
      <Card>  
      <div className="separator" />  
        <Row>
          <Col sm="12">
            <FacilityAssignationDataTable
             
              data={facilityAssignationTagList}
              columns={tableColumnsForFacilityAssignationTag}
              onTableChange={(type, newState) => {
                // debugger
                handleTableChangeForFacilityAssignationTag(type, newState);
              }}
              page={pageForFacilityAssignationTag}
              sizePerPage={sizePerPageForFacilityAssignationTag}
              totalSize={totalSizeForFacilityAssignationTag
                ? totalSizeForFacilityAssignationTag
                : 0
              }
              setPage={setPageForFacilityAssignationTag}
              setOffset={setOffsetForFacilityAssignationTag}
              setTotalSize={setTotalSizeForFacilityAssignationTag}
              setSearchKeyword={setSearchKeyword}
              searchKeyword={searchKeyword}
              reloadForFacilityAssignationTag={reloadForFacilityAssignationTag}
              setReloadForFacilityAssignationTag={setReloadForFacilityAssignationTag}
              offset={offsetForFacilityAssignationTag}
              facilityAssignationTagGroupName={facilityAssignationTagGroupName}
              setFacilityAssignationTagGroupName={setFacilityAssignationTagGroupName}
              facilityAssignationTagFacilityName={facilityAssignationTagFacilityName}
              setFacilityAssignationTagFacilityName={setFacilityAssignationTagFacilityName}
              facilityAssignationTagAppUser_Name={facilityAssignationTagAppUser_Name}
              setFacilityAssignationTagAppUser_Name={setFacilityAssignationTagAppUser_Name}
              facilityAssignationTagTableOrderCount={facilityAssignationTagTableOrderCount}
              setFacilityAssignationTagTableOrderCount={setFacilityAssignationTagTableOrderCount}
            />
            
            <FacilityAssignationEditModal
              row={row}
              setRow={setRow}
              setEditFacilityAssignationEditModal={setEditFacilityAssignationEditModal}
              editFacilityAssignationEditModal={editFacilityAssignationEditModal}
              reloadForFacilityAssignationTag={reloadForFacilityAssignationTag}
              setReloadForFacilityAssignationTag={setReloadForFacilityAssignationTag}
              editFacilityAssignationTagGroupName={editFacilityAssignationTagGroupName}
              setEditFacilityAssignationTagGroupName={setEditFacilityAssignationTagGroupName}
              editFacilityAssignationTagFacilityName={editFacilityAssignationTagFacilityName}
              setEditFacilityAssignationTagFacilityName={setEditFacilityAssignationTagFacilityName}
              editFacilityAssignationTagAppUser_Name={editFacilityAssignationTagAppUser_Name}
              setEditFacilityAssignationTagAppUser_Name={setEditFacilityAssignationTagAppUser_Name}
              editFacilityAssignationTagTableOrderCount={editFacilityAssignationTagTableOrderCount}
              setEditFacilityAssignationTagTableOrderCount={setEditFacilityAssignationTagTableOrderCount}
            />

          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default SenderAssigneeAllocation;